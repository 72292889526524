import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './user/login/login.component';
import {MailingPageComponent} from './mailing-page/mailing-page.component';
import {ErrorPageComponent} from './error-page/error-page.component';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MapComponent} from './map/map.component';
import {LogpageComponent} from './logpage/logpage.component';
import {TicketformComponent} from './ticketform/ticketform.component';
import {AdminLayoutComponent} from './admin/admin-layout/admin-layout.component';
import {AllticketsComponent} from "./alltickets/alltickets.component";
import {AgentPageComponent} from "./admin/admin-layout/agent-edit/agent-page/agent-page.component";
import {TicketEditComponent} from "./alltickets/ticket-edit/ticket-edit.component";
import {UserPageComponent} from "./admin/admin-layout/admin-directory/users/user-page/user-page.component";
import {MangoComponent} from "./admin/admin-layout/mango/mango.component";
import {LogsAllComponent} from "./admin/admin-layout/logs-all/logs-all.component";
import {SupportComponent} from "./support/support.component";



const routes: Routes = [
  { path: 'admin', component: AdminLayoutComponent},
  { path: 'mango', component: MangoComponent},
  { path: 'support', component: SupportComponent},
  { path: 'user/:id', component: UserPageComponent },
  { path: 'agent/:id', component: AgentPageComponent },
  { path: 'ticket/:id', component: TicketEditComponent },
  { path: 'profile', component: LogpageComponent},
  { path: 'loggs', component: LogsAllComponent },
  { path: '', component: MapComponent },
  { path: 'all-tickets', component: AllticketsComponent },
  { path: 'login', component: LoginComponent },
  { path: 'mailing-page', component: MailingPageComponent},
  { path: 'ticket', component: TicketformComponent},
  { path: '404', component: ErrorPageComponent},
  { path: '**', component: ErrorPageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [
    RouterModule,
    MatIconModule,
    MatListModule
  ]
})
export class AppRoutingModule { }
