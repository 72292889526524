<div *ngIf="!loaded" style="width: 100%; height: calc(100vh - 114px); justify-content: center; align-items: center; display: flex;">
  <mat-spinner></mat-spinner>
</div>
<div *ngIf="loaded" class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="card card-stats">
          <div class="card-header card-header-warning card-header-icon">
            <div class="card-icon">
              <i class="material-icons">content_copy</i>
            </div>
            <p class="card-category">Рабочие трэкеры</p>
            <h3 class="card-title"> {{statisticData.countWorkDevices}} / {{statisticData.countDevices}}
              <small>ед.</small>
            </h3>
          </div>
          <div class="card-footer">
            <div class="stats" *ngIf="(statisticData.countWorkDevices / statisticData.countDevices) < 4">
              <i class="material-icons text-danger">warning</i>
              Проверьте устройства
            </div>
            <div class="stats" *ngIf="(statisticData.countWorkDevices / statisticData.countDevices) > 4">
              <i class="material-icons text-info">warning</i>
              Некоторые устройства отключены
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="card card-stats">
          <div class="card-header card-header-success card-header-icon">
            <div class="card-icon">
              <i class="material-icons">check_circle</i>
            </div>
            <p class="card-category">Выполнено</p>
            <h3 class="card-title">{{statisticData.countTicketsFinish}}</h3>
          </div>
          <div class="card-footer">
            <div class="stats">
              <p (click)="countFinished()" class="m-0" style="cursor: pointer; border-bottom: 1px dashed #a699b3;">Изменить</p>
              <span style="font-size: 10px;"> &nbsp; {{countFinishedDates !== null ? (countFinishedDates.start | date: 'd MMM yyyy г.') + ' - ' : ''}}</span>
              <span style="font-size: 10px;"> &nbsp; {{countFinishedDates !== null ? (countFinishedDates.finish | date: 'd MMM yyyy г.') : ''}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="card card-stats">
          <div class="card-header card-header-danger card-header-icon">
            <div class="card-icon">
              <i class="material-icons">info_outline</i>
            </div>
            <p class="card-category">Не выполнено</p>
            <h3 class="card-title">{{statisticData.countNotTickets}}</h3>
          </div>
          <div class="card-footer">
            <div class="stats w-100 d-flex flex-direction-column">
              <p (click)="countNotFinished()" class="m-0" style="cursor: pointer; border-bottom: 1px dashed #a699b3;">Изменить</p>
              <span style="font-size: 10px;"> &nbsp; {{countNotFinishedDates !== null ? (countNotFinishedDates.start | date: 'd MMM yyyy г.') + ' - ' : ''}}</span>
              <span style="font-size: 10px;"> &nbsp; {{countNotFinishedDates !== null ? (countNotFinishedDates.finish | date: 'd MMM yyyy г.') : ''}}</span>

            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="card card-stats">
          <div class="card-header card-header-info card-header-icon">
            <div class="card-icon">
              <i class="material-icons">account_circle</i>
            </div>
            <p class="card-category">Работают всего</p>
            <h3 class="card-title">{{statisticData === null ? 'Загрузка...' : statisticData.countAgents}}</h3>
          </div>
          <div class="card-footer">
            <div class="stats">
              <i class="material-icons">update</i> Обновлено
              <ng-container *ngIf="math.trunc(counter/60) > 0"> {{math.trunc(counter/60)}} минут назад </ng-container>
              <ng-container *ngIf="math.trunc(counter/60) === 0"> {{counter}} секунд назад </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="card card-chart">
          <div class="card-header card-header-success">
          </div>
          <div class="card-body">
            <h4 class="card-title">Свободные агенты</h4>
            <p *ngIf="statisticData.countFreeAgents > 0" class="card-category">
              <span class="text-success"><i class="fa fa-long-arrow-up"></i> {{statisticData.countFreeAgents}} </span> свободно</p>
            <p *ngIf="statisticData.countFreeAgents === 0" class="card-category">
              <span class="text-success"><i class="fa fa-long-arrow-up"></i> </span> ни один агент не свободен</p>
          </div>
          <div class="card-footer">
            <div class="stats">
              <i class="material-icons">access_time</i> <ng-container *ngIf="math.trunc(counter/60) > 0"> {{math.trunc(counter/60)}} минут назад </ng-container>
              <ng-container *ngIf="math.trunc(counter/60) === 0"> {{counter}} секунд назад </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card card-chart">
          <div class="card-header card-header-warning">
          </div>
          <div class="card-body">
            <h4 class="card-title">Соотношение занятости</h4>
            <p class="card-category"><span class="text-danger">{{statisticData.countPercentWork}}% занятости</span></p>
          </div>
          <div class="card-footer">
            <div class="stats">
              <i class="material-icons">access_time</i> <ng-container *ngIf="math.trunc(counter/60) > 0"> {{math.trunc(counter/60)}} минут назад </ng-container>
              <ng-container *ngIf="math.trunc(counter/60) === 0"> {{counter}} секунд назад </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card card-chart">
          <div class="card-header card-header-danger">
          </div>
          <div class="card-body">
            <h4 class="card-title">Занятые агенты</h4>
            <p *ngIf="statisticData.countWorkAgents === 0" class="card-category">
              <span class="text-success"><i class="fa fa-long-arrow-up"></i> </span> ни один агент не занят</p>
            <p *ngIf="statisticData.countWorkAgents > 0" class="card-category">
              <span class="text-success"><i class="fa fa-long-arrow-up"></i> {{statisticData.countWorkAgents}} </span> заняты </p>
          </div>
          <div class="card-footer">
            <div class="stats">
              <i class="material-icons">access_time</i> <ng-container *ngIf="math.trunc(counter/60) > 0"> {{math.trunc(counter/60)}} минут назад </ng-container>
              <ng-container *ngIf="math.trunc(counter/60) === 0"> {{counter}} секунд назад </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="chartsLoaded">
        <div class="col-md-4 chartist-style">
          <div class="d-flex align-items-center wrapper-chart" style="padding: 12px">
            <span style="color: #3C4858">Кол-во успешных заявок</span>
            <button (click)="exportDate(1)" matTooltip="Экспорт" mat-icon-button aria-label="Example icon-button with a heart icon">
              <mat-icon class="icon-download">cloud_download</mat-icon>
            </button>
          </div>
        </div>
      <div class="col-md-4 chartist-style">
        <div class="d-flex align-items-center wrapper-chart" style="padding: 12px">
          <span style="color: #3C4858">История изменений статусов агента</span>
        </div>
      </div>
      <div class="col-md-4 chartist-style">
        <div class="d-flex align-items-center wrapper-chart" style="padding: 12px">
          <span style="color: #3C4858">Кол-во завершенных-неуспешных заявок</span>
          <button (click)="exportDate(2)" matTooltip="Экспорт" mat-icon-button aria-label="Example icon-button with a heart icon">
            <mat-icon class="icon-download">cloud_download</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="!chartsLoaded">
      <div class="col-12 my-3 d-flex flex-column justify-content-center align-items-center">
        <mat-progress-bar mode="buffer"></mat-progress-bar>
        <p class="mt-2">Загрузка...</p>
      </div>
    </div>
    <div class="row" *ngIf="chartsLoaded">
      <div class="col-md-4 p-3 chartist-style">
        <div class="wrapper-chart">
          <x-chartist class="p-3 ct-chart"
                      [type]="type"
                      [data]="data1"
                      [options]="options"
                      [events]="events"
          ></x-chartist>
        </div>
      </div>
      <div class="col-md-4 p-3 chartist-style">
        <div class="wrapper-chart p-3">
          <app-status-history></app-status-history>
        </div>
      </div>
      <div class="col-md-4 p-3 chartist-style">
        <div class="wrapper-chart">
          <x-chartist class="p-3 ct-chart"
                      [type]="type"
                      [data]="data2"
                      [options]="options"
                      [events]="events"
          ></x-chartist>
        </div>
      </div>
      <div class="col-md-4 p-3 chartist-style">
        <div class="wrapper-chart">
        </div>
      </div>
    </div>
    <div class="row">
      <app-statistis-module [type]="3" [datePickerTitleStart]="'Начало'" [datePickerTitleEnd]="'Конец'" [title]="'Информация по заявкам'" class="col-md-12"></app-statistis-module>
    </div>
  </div>
</div>
