<div class="container">
    <div class="col-md-12" style="overflow-y: auto;max-height: 440px;">
        <mat-accordion>
            <mat-expansion-panel *ngFor="let item of sortedData; let i = index">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{item.description}}
                    </mat-panel-title>
                    <mat-panel-description>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="d-flex align-items-center" style="gap: 5px">
                    <mat-icon>access_time</mat-icon>
                    <span>{{item.dateAdd | date: 'dd.MM.yyyy, H:mm:ss'}}</span>
                </div>
                <div class="d-flex align-items-center" style="gap: 5px">
                    <mat-icon>perm_identity</mat-icon>
                    <span>Агент: {{item.state.agent.user}}</span>
                </div>
                <div class="d-flex align-items-center" style="gap: 5px">
                    <mat-icon>person</mat-icon>
                    <span>{{item.emitter}} изменил заявку</span>
                </div>
                <div *ngIf="changesData[i].hasOwnProperty('state') && changesData[i].state.hasOwnProperty('firstName')" class="d-flex align-items-center" style="gap: 5px">
                    <mat-icon>person</mat-icon>
                    <span>Имя клиента изменено на: {{changesData[i]?.state?.firstName}}</span>
                </div>
                <div *ngIf="changesData[i].hasOwnProperty('state') && changesData[i].state.hasOwnProperty('lastName')" class="d-flex align-items-center" style="gap: 5px">
                    <mat-icon>person</mat-icon>
                    <span>Фамилия клиента изменена на: {{changesData[i]?.state?.lastName}}</span>
                </div>
                <div *ngIf="changesData[i].hasOwnProperty('state') && changesData[i].state.hasOwnProperty('patronymic')" class="d-flex align-items-center" style="gap: 5px">
                    <mat-icon>person</mat-icon>
                    <span>Отчество клиента изменено на: {{changesData[i]?.state?.patronymic}}</span>
                </div>
                <div *ngIf="changesData[i].hasOwnProperty('state') && changesData[i].state.hasOwnProperty('disp_firstName')" class="d-flex align-items-center" style="gap: 5px">
                    <mat-icon>record_voice_over</mat-icon>
                    <span>Имя диспетчера изменено на: {{changesData[i]?.state?.disp_firstName}}</span>
                </div>
                <div *ngIf="changesData[i].hasOwnProperty('state') && changesData[i].state.hasOwnProperty('disp_lastName')" class="d-flex align-items-center" style="gap: 5px">
                    <mat-icon>record_voice_over</mat-icon>
                    <span>Фамилия диспетчера изменена на: {{changesData[i]?.state?.disp_lastName}}</span>
                </div>
                <div *ngIf="changesData[i].hasOwnProperty('state') && changesData[i].state.hasOwnProperty('disp_patronymic')" class="d-flex align-items-center" style="gap: 5px">
                    <mat-icon>record_voice_over</mat-icon>
                    <span>Отчество диспетчера изменено на: {{changesData[i]?.state?.disp_patronymic}}</span>
                </div>
                <div *ngIf="changesData[i].hasOwnProperty('state') && changesData[i].state.hasOwnProperty('phone')" class="d-flex align-items-center" style="gap: 5px">
                    <mat-icon>settings_phone</mat-icon>
                    <span>Телефон изменен на: {{changesData[i]?.state?.phone}}</span>
                </div>
                <div class="d-flex align-items-center" style="gap: 5px">
                    <mat-icon>work</mat-icon>
                    <span>Предыдущий статус -
                        {{
                        {arrived: 'Прибыл',
                            ticket: 'Заявка открыта',
                            finished_ok: 'Завершена',
                            canceled_ok: 'Отменена',
                            submitted: 'Принята'}[item.state.status]
                        }}
                    </span>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
<!--    <ul>-->
<!--        <li *ngFor="let item of sortedData">-->
<!--            <span></span>-->
<!--            <div>-->
<!--                <div class="title">{{item.description}}</div>-->
<!--                <div class="info">{{item.state.applicant_address}}</div>-->
<!--                <div class="info">Агент: {{item.state.agent.user}}</div>-->
<!--                <div class="type">Prensetation</div>-->
<!--            </div>-->
<!--            <span class="number">-->
<!--        <span>{{item.dateAdd | date: 'dd/MM, H:mm'}}</span>-->
<!--        <span>{{data.length > 1 ? ''  : ''}}</span>-->
<!--      </span>-->
<!--        </li>-->
<!--    </ul>-->
</div>
