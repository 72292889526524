import { Component, OnInit } from '@angular/core';
import {UsersServices} from "../../../../shared/services/users.service";
import {ExcelFileExporterService} from "../../../../shared/services/excel-file-exporter.service";
export interface User {
  nummer: number;
  dateBirt: Date;
  secondName: string;
  firstName: string;
  patronymic: string;
  sex: boolean;
  dateBirth: Date;
  description?: any;
  dateAdd: Date;
  dateSendCode: Date;
  dateUpdate: Date;
  isDeleted: boolean;
  lastLogin: Date;
  isOnline: boolean;
  roleId: string;
  role?: any;
  id: string;
  normalizedEmail?: any;
  normalizedUserName?: any;
  emailConfirmed: boolean;
  passwordHash: string;
  securityStamp: string;
  concurrencyStamp: string;
  phoneNumber?: any;
  phoneNumberConfirmed: boolean;
  twoFactorEnabled: boolean;
  lockoutEnabled: boolean;
  accessFailedCount: number;
  lockoutEnd?: any;
  userName?: any;
  email: string;
}

export interface Device {
  id: number;
  groupId: number;
  name: string;
  uniqueId: string;
  status: string;
  lastUpdate: Date;
  positionId: number;
  phone: string;
  model: string;
  contact: string;
  disabled: boolean;
}

export interface Attributes {
  ignition: boolean;
  status: any;
  io1: string;
  io2: string;
  io3: string;
  io4: string;
  io5: string;
  distance: number;
  totalDistance: number;
  motion: boolean;
}

export interface Position {
  id: number;
  attributes: Attributes;
  deviceId: number;
  protocol: string;
  serverTime: Date;
  deviceTime: Date;
  fixTime: Date;
  outdated: boolean;
  valid: boolean;
  latitude: number;
  longitude: number;
  altitude: number;
  speed: number;
  course: number;
  accuracy: number;
}

export interface ActiveTicket {
  id: number;
  userId: string;
  user?: any;
  phone: string;
  additional_phone: string;
  patronymic: string;
  firstName: string;
  lastName: string;
  info_textarea: string;
  state: string;
  structural_state?: any;
  disp_firstName: string;
  disp_lastName: string;
  disp_patronymic: string;
  ticket_state: boolean;
  worker?: any;
  address: string;
  isDelete: boolean;
  stateId: number;
  ticketState?: any;
  stationId?: any;
  station?: any;
  agentId: number;
  dateAdd: Date;
  dateUpdate: Date;
}

export interface RootObject {
  id: number;
  userId: string;
  user: User;
  uniqueId: string;
  user1cUniqueId: string;
  isDelete: boolean;
  status: boolean;
  substatus: string | null;
  dateAdd: Date;
  dateUpdate: Date;
  device: Device;
  position: Position;
  activeTicket: ActiveTicket[];
}

@Component({
  selector: 'app-agent-geo-directory',
  templateUrl: './agent-geo-directory.component.html',
  styleUrls: ['./agent-geo-directory.component.css']
})
export class AgentGeoDirectoryComponent implements OnInit {
  settingsTable = {
    actions: false,
    noDataMessage: 'Список пуст',
    columns: {
      uniqueId: {
        title: 'ID Устройства',
      },
      deviceName: {
        title: 'Название устройства',
      },
      userName: {
        title: 'Инициалы',
        sortDirection: 'asc'
      },
      substatus: {
        title: 'Подстатус'
      },
      email: {
        title: 'Почта агента'
      },
      tickets: {
        title: 'Всего заказов'
      },
      status: {
        title: 'На смене',
        valuePrepareFunction: (value) => {
          if(value) {
            return 'Да'
          } else {
            return 'Нет'
          }
        }
      }
    }
  };
  geoData: any[] = [];
  constructor(private user: UsersServices, private excel: ExcelFileExporterService) { }

  exportToExcel() {
    this.excel.exportAsExcelFile(this.geoData, 'file')
  }
  ngOnInit() {
    this.user.getAgentsAPI().subscribe(data => {
      const resp = data as RootObject[];
      console.log(resp)
      this.geoData = resp.map(e => {
        return {
          uniqueId: e.uniqueId,
          deviceName: e.device.name,
          userName: e.user.secondName + " " + e.user.firstName.slice(0,1) + ". " + e.user.patronymic.slice(0,1) + ".",
          email: e.user.email,
          tickets: e.activeTicket.length,
          status: e.status,
          substatus: e.substatus === null ? "Свободен (null)" : {on_document: 'На оформлении док-ов', on_escort: 'На сопровождении', on_issue: 'На выдаче'}[e.substatus]
        }
      })
    })
  }
}
