<div *ngIf="!loading && !error">
    <mat-card class="example-card" style="margin: 10px">
        <mat-card-header>
            <mat-card-title>Редактирование заявки</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div *ngIf="reason !== null && reason.length" class="container-fluid">
                <div class="col-md-6 p-0">
                    <div class="reason-wrapper">
                        <span>Заявка была закрыта по причине: {{reason.toString().split('|')[0]}} </span>
                    </div>
                </div>
            </div>
            <div class="container-fluid my-3" *ngIf="duration !== 0">
                <div class="row">
                    <div class="col-md-6">
                        <span>Время расчетного прибытия: {{duration * 1000 | date: 'mm мин. ss сек.'}}</span>
                    </div>
                </div>
            </div>
            <form [formGroup]="ticketForm" style="display: -webkit-inline-box; width: 100%">
                <div class="container" style="margin-left: 0;">
                    <div class="row">
                        <div class="col-md-6">
                            <div style="padding: 10px; border: 1px solid gray; border-radius: 20px; margin-bottom: 10px">
                                <span>Диспетчер</span>
                                <div class="row">
                                    <div class="col-md-4">
                                        <mat-form-field class="example-full-width">
                                            <mat-label>Фамилия</mat-label>
                                            <input matInput formControlName="disp_lastName">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-4">
                                        <mat-form-field class="example-full-width">
                                            <mat-label>Имя</mat-label>
                                            <input matInput formControlName="disp_firstName" >
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-4">
                                        <mat-form-field class="example-full-width">
                                            <mat-label>Отчество</mat-label>
                                            <input matInput formControlName="disp_patronymic">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div style="padding: 10px; border: 1px solid gray; border-radius: 20px">
                                <span>Клиент</span>
                                <div class="row">
                                    <div class="col-md-4">
                                        <mat-form-field class="example-full-width">
                                            <mat-label>Фамилия</mat-label>
                                            <input matInput formControlName="lastName">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-4">
                                        <mat-form-field class="example-full-width">
                                            <mat-label>Имя</mat-label>
                                            <input matInput formControlName="firstName">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-4">
                                        <mat-form-field class="example-full-width">
                                            <mat-label>Отчество</mat-label>
                                            <input matInput formControlName="patronymic">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <mat-form-field class="example-full-width">
                                            <mat-label>Номер телефона</mat-label>
                                            <input matInput formControlName="phone">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-6">
                                        <mat-form-field class="example-full-width">
                                            <mat-label>Дополнительный номер тел.</mat-label>
                                            <input matInput formControlName="additionalPhone">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div style="padding: 10px; border: 1px solid gray; border-radius: 20px; margin-top: 10px">
                                <span>Заявка</span>
                                <div class="row">
                                    <div class="col-md-6">
                                        <mat-form-field class="example-full-width">
                                            <mat-label>Тип обращения</mat-label>
                                            <mat-select formControlName="questionType">
                                                <mat-option value="110">110</mat-option>
                                                <mat-option value="610">610</mat-option>
                                                <mat-option value="110+610">110+610</mat-option>
                                                <mat-option value="112">112</mat-option>
                                                <mat-option value="112">112+610</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-6">
                                        <mat-form-field class="example-full-width">
                                            <mat-label>Место оказания услуги</mat-label>
                                            <mat-select formControlName="placeOfService">
                                                <mat-option value="Центр ритуального обслуживания">Центр ритуального обслуживания</mat-option>
                                                <mat-option value="МОРГ">МОРГ</mat-option>
                                                <mat-option value="Выезд">Выезд</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <mat-form-field class="example-full-width">
                                            <mat-label>Дополнительная информация</mat-label>
                                            <input matInput formControlName="comment">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <mat-form-field appearance="fill" style="margin-top: 10px">
                                        <mat-label>Выбор агента</mat-label>
                                        <mat-select formControlName="agentId">
                                            <mat-select-trigger>
                                                <mat-icon>account_circle</mat-icon>&nbsp;{{agentLabel}}
                                            </mat-select-trigger>
                                            <mat-option *ngFor="let agent of loadedAgents" [value]="agent.id">
                                                <mat-icon matTooltipPosition="before" [matTooltip]="agent.status && agent.device.status === 'online' ? 'Олнайн' : 'Оффлайн'"
                                                          [style]="agent.status && agent.device.status === 'online' ? 'color: green' : 'color: #aeaeae'" aria-hidden="false" aria-label="Example home icon">account_circle</mat-icon>
                                                {{agent.user.secondName}} / ID: {{agent.id}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6">
                                    <mat-form-field appearance="fill" style="margin-top: 10px">
                                        <mat-label>Статус</mat-label>
                                        <mat-select formControlName="status">
                                            <mat-option value="ticket">Заявка открыта</mat-option>
                                            <mat-option value="submitted">Заявка принята</mat-option>
                                            <mat-option value="arrived">Прибыл</mat-option>
                                            <mat-option value="finished_ok">Заявка завершена</mat-option>
                                            <mat-option value="canceled_ok">Заявка отменена</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <mat-form-field appearance="fill">
                                <mat-label>Морг</mat-label>
                                <mat-select formControlName="morgueId">
                                    <mat-option *ngFor="let morgue of loadedMorgues" [value]="morgue.id">
                                        {{morgue.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <app-timeline-vertical [original]="ticketData" [data]="history" [agents]="loadedAgents"></app-timeline-vertical>
                        </div>
                    </div>
                </div>
            </form>
        </mat-card-content>
        <mat-card-actions>
            <button mat-raised-button (click)="onCancel()">Отменить</button>
            <button mat-raised-button color="warn" *ngIf="user?.currentUser[0]?.role?.name === 'admin'" (click)="deleteTicket()">Удалить</button>
            <button mat-raised-button color="primary" [disabled]="!ticketForm.valid" (click)="sendTicket()">Применить</button>
        </mat-card-actions>
    </mat-card>
</div>
<mat-spinner *ngIf="loading && !error"></mat-spinner>
<div class="error-container" *ngIf="error && !loading">
    <span>Заявка не найдена!</span>
</div>
