<h2 mat-dialog-title>Создать заявку на тему {{data === 1 ? '"Карты"' : data === 2 ? '"Заявка"' : data === 3 ? '"Сайт"' : '"Мобильное приложение"'}}</h2>
<mat-dialog-content style="width: 50vw" class="mat-typography">
    <mat-form-field class="example-full-width">
        <mat-label>Опишите решение</mat-label>
        <textarea [(ngModel)]="text" matInput></textarea>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Отменить</button>
    <button mat-button [disabled]="!text.length"  [mat-dialog-close]="text" cdkFocusInitial>Отправить</button>
</mat-dialog-actions>
