import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {Injectable} from '@angular/core';
import {catchError} from "rxjs/operators";
import {NotifierMsgService} from "../services/notifier-msg.service";
import {Router} from "@angular/router";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private notify: NotifierMsgService, private router: Router) {
  }
  intercept(req: HttpRequest<any>,
            next: HttpHandler): Observable<HttpEvent<any>> {
    const idToken = localStorage.getItem('id_token');
    // исключение для рассылки пушей
    const exceptionPush = req.url.split('/')[req.url.split('/').length  - 1];
    //&& exceptionPush !== 'push' && exceptionPush !== 'mail'
    if (idToken && req.url !== 'http://194.67.108.80:8082/api/session'
        && req.url !== "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address"
        && req.url !== "https://cleaner.dadata.ru/api/v1/clean/address") {
      const cloned = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + idToken)
      });
      return next.handle(cloned).pipe(
          catchError(e => {
              console.log(e);
            this.notify.notify('error', `Серверная ошибка ${e.status}: ${!!e?.error ? e.error : ''}`)
            return throwError(e)
          })
      );
    } else {
      return next.handle(req).pipe(
          catchError(e => {
              console.log(e);
              this.notify.notify('error', `Серверная ошибка ${e.status}: ${!!e?.error ? e.error : ''}`)
            return throwError(e)
          })
      );
    }
  }
}
