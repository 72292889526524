import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {AuthService} from './shared/services/auth.service';
import {UsersServices} from "./shared/services/users.service";
import {MapService} from "./shared/services/map.service";
import {NotifierMsgService} from "./shared/services/notifier-msg.service";
import {WebSocketService} from "./web-socket.service";
//import {setInterval} from "timers";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'web-app';
  isLogin: boolean;
  isLoaded: boolean;
  isProfileLoaded: boolean;
  isRolesLoaded: boolean;
  trackInterval: any;
  trackTriggered = false;
  listenCallActive = false;

  activeRouteApp: any;
  constructor(private router: Router,
              private auth: AuthService,
              private route: ActivatedRoute,
              private user: UsersServices,
              private map: MapService,
              private notifier: NotifierMsgService,
              private webSocket: WebSocketService) {
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.ngOnInit();
      }
      this.isLogin = this.auth.isAuth();
      this.activeRouteApp = this.router.routerState.snapshot.url;
    });
  }
  ngOnInit() {
    if(this.listenCallActive === false) {
      this.listenCallActive = true

      this.webSocket.listen('CallRequest').subscribe(e => {
        // @ts-ignore
        this.notifier.show('warning', `Обратный звонок ${e.agent.user?.secondName} ${e.agent.user?.firstName.slice(0, 1)}. ${e.agent.user?.patronymic.slice(0, 1)}.`, 'notify_call');
      })
    }
    let theme = localStorage.getItem('theme');
    if(theme === null) {
      localStorage.setItem('theme', 'dark');
      this.map.themeToggle.next('dark');
    } else {
      switch (theme) {
        case 'dark': {
          this.map.themeToggle.next('dark');
          break;
        }
        case 'light': {
          this.map.themeToggle.next('light');
          break;
        }
      }
    }
    this.isLogin = this.auth.isAuth();
    if (!this.isLogin) {
      this.router.navigate(['/login']);
    } else {
      this.user.currentUserLoaded.subscribe(data => {
        this.isLoaded = data;
      });
      this.user.currentRolesLoaded.subscribe(data => {
        this.isRolesLoaded = data;
      });
      this.map.devicesLoaded.subscribe(data => {
        this.isProfileLoaded = data;
      });
      this.user.getCurrentUser();
      this.map.getDevices();
      this.user.getRoles();
    }
  }
}
