import { Injectable } from '@angular/core';
import {NotifierService} from "angular-notifier";

@Injectable({
  providedIn: 'root'
})
export class NotifierMsgService {

  constructor(public notifier: NotifierService) {
  }
  notify(str, msg){
    this.notifier.notify(str,msg);
  }
  show(str, msg, id){
    this.notifier.show({
      type: str,
      message: msg,
      id: id
    });
  }
  hide(id) {
    this.notifier.hide(id);
  }
}
