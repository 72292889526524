import {Component, Inject, OnInit} from '@angular/core';
import {TicketSender} from "../shared/services/tickets.service";
import {SupportTicket} from "../shared/interfaces/support";
import {MatDialog} from "@angular/material/dialog";
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {UsersServices} from "../shared/services/users.service";
@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {

  constructor(private support: TicketSender, public user: UsersServices, private dialog: MatDialog) { }
  data: SupportTicket[] = [];
  page = 1;
  countItems = 0;
  ngOnInit(): void {
    this.parseData();
  }
  onPageChange(e: number): any {
    this.support.getSupportTicketsV2(e - 1).subscribe((res) => {
      this.page = e;
      this.data = res.supports;
    })
  }
  toggleTicket(id, state) {
    this.support.setSupportTicket({...id, state}).subscribe((e) => {
      this.parseData();
    })
  }
  parseData() {
    this.support.getSupportTicketsV2().subscribe((res) => {
      this.page = 1;
      this.data = res.supports;
      this.countItems = res.count;
    })
  }
  openDialog(type) {
    const dialogRef = this.dialog.open(SupportDialog, {
      data: type
    });

    dialogRef.afterClosed().subscribe(result => {
      this.support.submitTicket({state: 0, ...result}).subscribe((e) => {
        this.parseData();
      })
    });
  }
  openFinishDialog(id) {
    const dialogRef = this.dialog.open(SupportDialogFinish);

    dialogRef.afterClosed().subscribe(result => {
      // this.support.submitTicket({state: 0, ...result}).subscribe((e) => {
      //   this.parseData();
      // })
    });
  }
}
@Component({
  selector: 'app-support-dialog',
  templateUrl: 'support-dialog.html',
})
export class SupportDialog implements OnInit {
  constructor(
      @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
  text = "";
  name = "";
  ngOnInit() {

  }
}
@Component({
  selector: 'app-support-dialog-finish',
  templateUrl: 'support-finish-dialog.html',
})
export class SupportDialogFinish implements OnInit {
  constructor(
      @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
  text = "";
  ngOnInit() {

  }
}
