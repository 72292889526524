import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {MapItem} from "../../../shared/interfaces/mapMarkers";
import {ViewCell} from "ng2-smart-table";
import {Router, RouterModule} from "@angular/router";
import {ExcelFileExporterService} from "../../../shared/services/excel-file-exporter.service";
import {UsersServices} from "../../../shared/services/users.service";

@Component({
  selector: 'basic-example-button-view',
  template: `
    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="onClick()">
        <mat-icon>edit</mat-icon>
        <span>Редактировать</span>
      </button>
    </mat-menu>
  `,
})

export class ButtonViewComponent implements ViewCell, OnInit {

  constructor(private router: Router) {
  }
  @Input() value: string | number;
  @Input() rowData: any;

  @Output() save: EventEmitter<any> = new EventEmitter();

  ngOnInit() {

  }

  onClick() {
    this.router.navigate(['/agent/' + this.rowData.userId])
  }
}


@Component({
  selector: 'app-agent-edit',
  templateUrl: './agent-edit.component.html',
  styleUrls: ['./agent-edit.component.css']
})

export class AgentEditComponent implements OnInit {
  @Input() agentData: MapItem[];
  agentFiltered: any[];
  constructor(private excel: ExcelFileExporterService, private user: UsersServices) { }
  settingsTable = {
    actions: false,
    noDataMessage: 'Список пуст',
    columns: {
      uniqueId: {
        title: 'ID Устройства',
      },
      deviceName: {
        title: 'Название устройства',
      },
      userName: {
        title: 'Фамилия агента',
        sortDirection: 'asc'
      },
      email: {
        title: 'Почта агента'
      },
      tickets: {
        title: 'Всего заказов'
      },
      // status: {
      //   type: 'custom',
      //   filter: false,
      //   renderComponent: ButtonViewComponent
      // }
    }
  };
  onExport() {
    this.user.getDevicesAll().subscribe(e => {
      let filtered: any[] = e.filter(item => item.lastUpdate !== null);
      filtered = filtered.map(item2 => {
        return {
          name: item2.name,
          ID: item2.uniqueId
        }
      })
      this.excel.exportAsExcelFile(filtered, 'devices')
    })
  }
  ngOnInit() {
    this.agentFiltered = this.agentData.map(e => {
      return {
        userId: e.userId,
        uniqueId: e.uniqueId,
        deviceName: e.device.name,
        userName: e.user.secondName,
        email: e.user.email,
        tickets: e.activeTicket.length,
        status: e.status
      }
    })
  }

}
