<mat-spinner *ngIf="!loaded"></mat-spinner>
<ng-container *ngIf="loaded">
    <mat-card style="margin: 10px" class="example-card">
        <mat-card-header>
            <div mat-card-avatar
                 style="
                 background-image: url(assets/img/businessman-icon-png-263229-free-icons-library-businessman-icon-png-512_512.jpg);
                 background-size: cover;
                  " class="example-header-image">
            </div>
            <mat-card-title>Редактировать агента</mat-card-title>
            <mat-card-subtitle>Карточка</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <form [formGroup]="agentForm">
                <div class="row">
                    <div class="col-md-3">
                        <mat-form-field class="full-width">
                            <input matInput placeholder="Фамилия" formControlName="secondName">
                            <mat-error *ngIf="agentForm.controls['secondName'].hasError('required')">
                                Фамилия <strong>обязательна</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-3">
                        <mat-form-field class="full-width">
                            <input matInput placeholder="Имя" formControlName="firstName">
                            <mat-error *ngIf="agentForm.controls['firstName'].hasError('required')">
                                Имя <strong>обязательно</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-3">
                        <mat-form-field class="full-width">
                            <input matInput placeholder="Отчество" formControlName="patronymic">
                            <mat-error *ngIf="agentForm.controls['patronymic'].hasError('required')">
                                Отчество <strong>обязательно</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <mat-form-field class="full-width">
                            <input matInput placeholder="Почта" formControlName="email">
                            <mat-error *ngIf="agentForm.controls['email'].hasError('required')">
                                Почта <strong>обязательна</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </form>
        </mat-card-content>
        <mat-card-actions>
            <button mat-raised-button (click)="onCancel()">Назад</button>
            <button mat-raised-button color="primary" (click)="onSubmit()">Сохранить</button>
            <button mat-raised-button color="warn" (click)="onDelete()">Удалить</button>
        </mat-card-actions>
    </mat-card>
</ng-container>
