import {Component, OnDestroy, OnInit} from '@angular/core';
import {MapService} from '../shared/services/map.service';
import {UsersServices} from "../shared/services/users.service";
import {MapItem} from "../shared/interfaces/mapMarkers";
import * as _ from 'lodash';
import {Router} from "@angular/router";
import {MatCheckboxChange} from "@angular/material/checkbox";
import {Marker} from "mapbox-gl";


@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  styles: []
})

export class MapComponent implements OnInit, OnDestroy {
  mapInstance = null;
  markersData: MapItem[];
  devicesData: any;
  loaded = false;
  interval: any;
  math = Math;
  zoom = 0;
  filteredItems: MapItem[] = [];
  favoriteSeason: string = "Все";
  seasons: string[] = ['Все', 'Свободны', 'Не на смене', 'Заняты', 'Выключен трэкер'];
  filterActive = false;

  draggableAgents = ['a.ryazancev@mosritual.ru', 'latin@3dcafe.ru']

  get draggable() {
    return this.draggableAgents.includes(this.user.currentUser[0]?.email) && (this.user.currentUser[0]?.role?.name === 'admin' || this.user.currentUser[0]?.role?.name === 'supermanager')
  }

  currentFilter = null;

  renderFilter(index) {
    let filtObj = {};
    let resp: MapItem[] = [];
    if(this.markersData !== undefined) {
      switch (index) {
        case "Все": {
          resp = this.markersData
          break;
        }
        case "Заняты": {
          resp = this.markersData.filter(e => {
            {
              if(e.activeTicket.length > 0) {
                if(e.activeTicket.some(item => item.status !== "finished_ok" || item.status !== "canceled_ok")) {
                  return true;
                } else {
                  return e.device.status === "online" && e.status && e.substatus !== "" && e.substatus !== null;
                }
              } else {
                return e.device.status === "online" && e.status && e.substatus !== "" && e.substatus !== null;
              }
            }
          });
          break;
        }
        case "Свободны": {
          resp = this.markersData.filter(e => {
            if(e.activeTicket.length > 0) {
              if(e.activeTicket.some(item => item.status === "finished_ok" || item.status === "canceled_ok")) {
                return e.device.status === "online" && e.status && (e.substatus === "" || e.substatus === null)
              } else {
                return false;
              }
            } else {
              return e.device.status === "online" && e.status && (e.substatus === "" || e.substatus === null)
            }
          })
          break;
        }
        case "Выключен трэкер": {
          resp = this.markersData.filter(e => e.device.status === "offline" || e.device.status === "unknown")
          break;
        }
        case "Не на смене": {
          resp = this.markersData.filter(e => e.device.status === "online" && e.status === false);
          break;
        }
      }
    }
    if(index === "Заняты") {
    }
    return resp.sort((a, b) => a.user.secondName.localeCompare(b.user.secondName));
  }
  onSelected(e) {
    this.map.mapTriggerSearch.next(e.value)
  }
  toggleFilter(e) {
    this.currentFilter = e.value;
    const req = e.value;
    switch (req) {
      case "Все": {
        this.filteredItems = this.markersData
        break;
      }
      case "Заняты": {
        this.filteredItems = this.markersData.filter(e => {
          {
            if(e.activeTicket.length > 0) {
              if(e.activeTicket.some(e => e.status !== "finished_ok" || e.status !== "canceled_ok")) {
                return true;
              } else {
                return e.device.status === "online" && e.status && e.substatus !== "" && e.substatus !== null;
              }
            } else {
              return e.device.status === "online" && e.status && e.substatus !== "" && e.substatus !== null;
            }
          }
        });
        console.log(this.filteredItems);
        break;
      }
      case "Свободны": {
        this.filteredItems = this.markersData.filter(e => {
          {
            if(e.activeTicket.length > 0) {
              if(e.activeTicket.some(item => item.status === "finished_ok" || item.status === "canceled_ok")) {
                return e.device.status === "online" && e.status && (e.substatus === "" || e.substatus === null)
              } else {
                return false;
              }
            } else {
              return e.device.status === "online" && e.status && (e.substatus === "" || e.substatus === null)
            }
          }
        });
        break;
      }
      case "Не на смене": {
        this.filteredItems = this.markersData.filter(e => e.device.status === "online" && e.status === false)
        break;
      }
      case "Выключен трэкер": {
        this.filteredItems = this.markersData.filter(e => e.device.status === "offline" || e.device.status === "unknown")
        break;
      }
    }
    this.filterActive = true;

  }

  constructor(public map: MapService, private router: Router, private user: UsersServices) {}
  zoomController(e) {
    this.zoom = e.target.getZoom();
  }
  colorController(data: MapItem) {
    let resp = ''
    if(data.activeTicket.length > 0) {
      if(data.activeTicket.some(e => e.status === "finished_ok" && e.status === "canceled_ok")) {
        if(data.status) {
          if(data.substatus === "" || data.substatus === null) {
            if(data.device.status === "online") {
              resp = 'green'
            } else {
              resp = 'gray'
            }
          } else {
            if(data.device.status === "online") {
              resp = 'red'
            } else {
              resp = 'gray'
            }
          }
        } else {
          resp = 'gray'
        }
        // ТОТ МОМЕНТ КОГДА ЧЕЛ С ТИКЕТАМИ НО ЗАВЕРШЕННЫМИ
      } else {
        resp = {ticket: 'yellow', submitted: 'orange', arrived: 'red'}[data.activeTicket.filter(e => e.status !== "finished_ok" && e.status !== "canceled_ok")[0].status]
      }
    } else {
      if(data.status) {
        if(data.substatus === "" || data.substatus === null) {
          if(data.device.status === "online") {
            resp = 'green'
          } else {
            resp = 'gray'
          }
        } else {
          if(data.device.status === "online") {
            resp = 'red'
          } else {
            resp = 'gray'
          }
        }
      } else {
        resp = 'gray'
      }
    }
    return resp
  }
  checkColor(type: string, source: MapItem[]) {
    return source.filter(e => e.inits === type).length;
  }
  async onManualChange(value: MatCheckboxChange, item: MapItem, index: number) {
    await this.map.setControl(item.id, value.checked).toPromise();
    (this.filteredItems.length || this.filterActive ? this.filteredItems : this.markersData)[index].manual_control = value.checked;
    let _date = new Date();
    _date.setDate(new Date().getDate() + 1);
    (this.filteredItems.length || this.filterActive ? this.filteredItems : this.markersData)[index].start_manual_control = _date.toISOString();
  }
  async onDrag(agent: MapItem, marker: Marker, ind: number) {
    if (this.user.currentUser[0]?.role?.name !== 'admin' && this.user.currentUser[0]?.role?.name !== 'supermanager')
      return;

    let _date = new Date();
    _date.setDate(new Date().getDate() + 1);
    await this.map.setPosition(agent.id, marker.getLngLat()).toPromise();
    if(!agent.manual_control) {
      (this.filteredItems.length || this.filterActive ? this.filteredItems : this.markersData)[ind].start_manual_control = _date.toISOString();
    }
    (this.filteredItems.length || this.filterActive ? this.filteredItems : this.markersData)[ind].manual_control = true;
  }
  async fetchData() {
    await new Promise((res, rej) => {
      this.map.getMapMarkers()
          .subscribe((data) => {
            this.markersData = data as MapItem[]
            this.markersData = this.markersData.map(e => {
              if(e.activeTicket.length) {
                return {
                  ...e,
                  activeTicket: e.activeTicket.filter(item => item.status !== 'finished_ok' && item.status !== 'canceled_ok'),
                  deviceStatus: e.device.status === "unknown" || e.device.status === "offline" ? "offline" : "online",
                  hasTickets: e.activeTicket.filter(item => item.status !== 'canceled_ok' && item.status !== 'finished_ok').length > 0
                }
              } else {
                return {
                  ...e,
                  activeTicket: [],
                  deviceStatus: e.device.status === "unknown" || e.device.status === "offline" ? "offline" : "online",
                  hasTickets: e.activeTicket.filter(item => item.status !== 'canceled_ok' && item.status !== 'finished_ok').length > 0
                }
              }
            })
            this.markersData = this.markersData.map(e => {
              return {
                ...e,
                inits: this.colorController(e)
              }
            })
            this.loaded = true;
            this.toggleFilter({value: this.currentFilter === null ? 'Все' : this.currentFilter})
            res(true);
          });
    });
    await new Promise((res, rej) => {
      setTimeout(() => this.fetchData(), 5000)
    })
  }
  onMapLoad(map) {
    map.resize();
    this.mapInstance = map;
  }
  ngOnInit() {
    try {
      if(this.user.currentUser[0].role.name === 'manager') {
        this.router.navigate(['/mango'])
      }
    } catch (e) {

    }
    this.map.mapTriggerSearch.subscribe((e) => {
      if(e.position && (e.position.latitude && e.position.longitude)) {
        this.mapInstance.flyTo({center: [e.position.longitude, e.position.latitude], zoom: 14})
      }
    })

    this.fetchData();
    // this.interval = setInterval(() => { this.fetchData(); }, 5000);
  }
  ngOnDestroy() {
    clearInterval(this.interval);
  }

}
