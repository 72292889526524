import { Component, OnInit } from '@angular/core';
import {Route, Router, ActivatedRoute} from "@angular/router";
import {UsersServices} from "../../../../shared/services/users.service";
import {MapItem} from "../../../../shared/interfaces/mapMarkers";
import {FormBuilder, Validators} from "@angular/forms";
import {NotifierMsgService} from "../../../../shared/services/notifier-msg.service";

@Component({
  selector: 'app-agent-page',
  templateUrl: './agent-page.component.html',
  styleUrls: ['./agent-page.component.css']
})
export class AgentPageComponent implements OnInit {
  userId: string = this.route.snapshot.paramMap.get('id');
  currentAgent: MapItem = null;
  loaded = false;

  agentForm = this.fb.group({
    email: [null, Validators.required],
    firstName: [null, [Validators.required, Validators.minLength(2)]],
    secondName: [null, Validators.required],
    patronymic: [null, Validators.required],
    roleId: [null, Validators.required],
  });
  onSubmit() {
    this.agent.updateUser({...this.agentForm.value, id: this.currentAgent.user.id, nummer: this.currentAgent.user.nummer}).subscribe(() => {
      this.notify.notify('success', 'Данные изменены')
    })
  }
  onDelete() {
    this.agent.deleteUser(this.currentAgent.user.nummer, this.currentAgent.id).subscribe((e) => {
      this.notify.notify('success', 'Аккаунт удален')
      setTimeout( () => {
        this.router.navigate(['/admin'], {queryParams: { 'ref': 'agents' }})
      }, 2000)
    }, () => {
      this.notify.notify('error', 'Ошибка удаления!')
    })
  }
  onCancel() {
    this.router.navigate(['/admin'], {queryParams: { 'ref': 'agents' }})
  }

  constructor(private router: Router,
              private route: ActivatedRoute,
              private agent: UsersServices,
              private fb: FormBuilder,
              private notify: NotifierMsgService) { }

  ngOnInit() {
   this.agent.getAgentsAPI().subscribe(data => {
     const resp = data as MapItem[];
     const filObj = resp.filter(e => e.userId === this.userId);
     if(!filObj.length) {
       this.router.navigate(['/'])
     } else {
       this.currentAgent = resp.filter(e => e.userId === this.userId)[0];
       console.log(this.currentAgent)
       this.agentForm.setValue({
         email: this.currentAgent.user.email,
         firstName: this.currentAgent.user.firstName,
         secondName: this.currentAgent.user.secondName,
         patronymic: this.currentAgent.user.patronymic,
         roleId: this.currentAgent.user.roleId
       })
       this.loaded = true;
     }
   })
  }
}
