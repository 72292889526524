<mat-tab-group *ngIf="role !== null" [selectedIndex]="currentTab" #tabs (selectedTabChange)="directory($event)" style="z-index: 0">
  <mat-tab *ngIf="role !== 'manager'" label="Инфо-панель"><app-admintrackers></app-admintrackers></mat-tab>
<!--  <mat-tab label="Формы"> <app-admin-form></app-admin-form> </mat-tab>-->
  <mat-tab *ngIf="role !== 'manager'" label="Безопасность"><app-admin-logs></app-admin-logs></mat-tab>
  <mat-tab *ngIf="role !== 'manager'" label="Добавить трэкер"><app-admin-device></app-admin-device></mat-tab>
  <mat-tab *ngIf="role !== 'manager'" label="Создание агента">
    <div *ngIf="!allUsersLoaded">
      <mat-spinner>
      </mat-spinner>
    </div>
    <app-agent-add *ngIf="allUsersLoaded" [devicesData]="allDevicesData" [agentsData]="allAgentsData">

    </app-agent-add>
  </mat-tab>
  <mat-tab *ngIf="role !== 'manager'" label="Агенты"><app-agent-edit *ngIf="agentsLoadedAPI.length" [agentData]="agentsLoadedAPI"></app-agent-edit></mat-tab>
  <mat-tab *ngIf="role !== 'manager'" label="Создать пользователя"><app-user-add></app-user-add></mat-tab>
  <mat-tab *ngIf="role !== 'manager'" label="Справочники">
    <div *ngIf="!dirServ.morguesToggle" style="margin-top: 50px;display: flex;justify-content: center;overflow: hidden;">
    <mat-spinner></mat-spinner>
    </div>
    <app-admin-directory *ngIf="dirServ.morguesToggle"></app-admin-directory>
  </mat-tab>
  <mat-tab label="Манго телефония">
  </mat-tab>

</mat-tab-group>
