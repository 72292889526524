import {Component, NgModule, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../shared/services/auth.service';
import {Router} from '@angular/router';
import {NotifierMsgService} from '../../shared/services/notifier-msg.service';
import {UsersServices} from "../../shared/services/users.service";
import {MapService} from "../../shared/services/map.service";


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {

  isLogin = true;
  hide = true;

  constructor(public auth: AuthService,
              private router: Router, private notifier: NotifierMsgService, private user: UsersServices,
              private map: MapService) { }

  loginForm: FormGroup;
  recoverForm: FormGroup;

  ngOnInit(): void {
    if(localStorage.getItem('id_token') !== undefined && localStorage.getItem('id_token') !== null) {
      this.router.navigate(['/'])
    }
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required])
    });
    this.recoverForm = new FormGroup({
      phone: new FormControl('', [Validators.required])
    });
  }

  recover() {
    this.auth.sendRecover(this.recoverForm.value).subscribe(data => {
      this.notifier.notify('success', 'Восстановление успешно!');
      this.recoverForm.reset();
    }, error => {
      this.notifier.notify('error', 'Аккаунта с таким номером не существует!');
    });
  }
  login() {
    // console.log('loginForm', this.loginForm.value);

    this.auth.sendLogin(this.loginForm.value).subscribe( data => {
      // if (data?.role !== 'agent') {
        // @ts-ignore
        localStorage.setItem('id_token', data.text);
        this.user.getCurrentUser();
        this.map.getDevices();
        this.user.getRoles();
        this.router.navigate(['/']);
      // } else {
      //   this.notifier.notify('error', 'Ошибка доступа!');
      // }
    }, error => {
      this.notifier.notify('error', 'Неверный логин либо пароль!');
      console.log(error);
    });

  }
}
