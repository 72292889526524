import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-form',
  templateUrl: './admin-form.component.html',
  styleUrls: ['./admin-form.component.css']
})
export class AdminFormComponent implements OnInit {

  typeOfFields = [
    {type: 'input', label: 'Текстовое поле'},
    {type: 'checkbox', label: 'Маркер'},
    {type: 'radiobutton', label: 'Radio-маркер'}
  ];
  fields = [
    { type: 'input', text: 'Текстовое поле', id: '324' },
    { type: 'input', text: 'Текстовое поле', id: '125' },
    { type: 'input', text: 'Текстовое поле', id: '1254' },
    { type: 'input', text: 'Текстовое поле', id: '148' },
  ];
  creatorNameField = null;
  creatorTypeField = null;

  selectedItem = String;

  constructor() { }

  deleteValue(item) {
    this.fields = this.fields.filter(e => e.id !== item.id);
  }

  getFieldCreator() {
    this.fields = [...this.fields, {
      type: this.creatorTypeField,
      text: this.creatorNameField,
      id: `${Math.floor(Math.random() * 100)}`
    }];
  }
  selectItem(item) {
    if (this.selectedItem === item.id) {
      this.selectedItem = null;
    } else {
      this.selectedItem = item.id;
    }
  }

  ngOnInit(): void {
  }

}
