<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
    <mat-icon>more_vert</mat-icon>
</button>
<mat-menu #menu="matMenu">
    <button (click)="onSubmitChange()" mat-menu-item>
        <mat-icon>edit</mat-icon>
        <span>Изменить</span>
    </button>
<!--    <button disabled mat-menu-item>-->
<!--        <mat-icon>block</mat-icon>-->
<!--        <span>Удалить</span>-->
<!--    </button>-->
</mat-menu>
