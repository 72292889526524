<h2 mat-dialog-title>Изменить период</h2>
<mat-dialog-content class="mat-typography">
<!--    <h3>Develop across all platforms</h3>-->
    <form [formGroup]="formg">
        <mat-form-field appearance="fill">
            <mat-label>Начальная дата</mat-label>
            <input formControlName="start" matInput [matDatepicker]="picker">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Конечная дата</mat-label>
            <input formControlName="finish" matInput [matDatepicker]="picker2">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="null">Отмена</button>
    <button mat-button [disabled]="!formg.valid" [mat-dialog-close]="formg.value" cdkFocusInitial>Показать</button>
</mat-dialog-actions>