import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl} from "@angular/forms";
import {UsersServices} from "../../../../shared/services/users.service";
import {DeviceObject} from "../../../../shared/interfaces/device";
import {Observable} from "rxjs";
import {map, startWith} from "rxjs/operators";
import {NotifierMsgService} from "../../../../shared/services/notifier-msg.service";

@Component({
  selector: 'app-edit-device',
  templateUrl: './edit-device.component.html',
  styleUrls: ['./edit-device.component.css']
})
export class EditDeviceComponent implements OnInit {
  deviceForm = this.fb.group({
    name: null,
    phone: null
  })
  myControl = new FormControl();
  devices: DeviceObject[] = null;
  selectedDevice: DeviceObject = null;
  filteredOptions: Observable<DeviceObject[]>;
  loaded = false;
  constructor(private users: UsersServices, private fb: FormBuilder, private notify: NotifierMsgService) { }

  ngOnInit(): void {
    this.users.getDevicesAll().subscribe((e) => {
      this.devices = e;
      this.loaded = true;
      this.filteredOptions = this.myControl.valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value))
      );
    })
  }
  displayFn(device: DeviceObject): string {
    return device === null ? '' : device.uniqueId === null ? device.name : device.uniqueId
  }
  onSelectValue(event: DeviceObject) {
    this.selectedDevice = event;
    this.deviceForm.setValue({
      name: event.name,
      phone: event.phone
    })
  }
  onSave() {
    this.users.editDevice({...this.selectedDevice, name: this.deviceForm.controls['name'].value, phone: this.deviceForm.controls['phone'].value}).subscribe(e => {
      this.notify.notify('success', 'Трэкер изменен!')
    })
  }
  private _filter(value: string | DeviceObject): DeviceObject[] {
    let filterValue;
   if(typeof value === "string") {
     filterValue = value.toLowerCase();
   } else {
     filterValue = value.uniqueId.toLowerCase();
   }

    return this.devices.filter(option => option.uniqueId.indexOf(filterValue) === 0);
  }

}
