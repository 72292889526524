import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import {Router} from '@angular/router';
import {UsersServices} from '../shared/services/users.service';
import {UserType} from '../shared/interfaces/user';
import {OnInit} from '@angular/core';
import {TicketSender} from "../shared/services/tickets.service";
import {MapService} from "../shared/services/map.service";

@Component({
  selector: 'app-main-dash',
  templateUrl: './main-dash.component.html',
  styleUrls: ['./main-dash.component.css']
})

export class MainDashComponent implements OnInit {
  userData: any;
  sidenavWidth: any;
  ticketLoaded = false;
  activeTickets: boolean = false;
  searchActive: boolean = false;
  theme: string = null;
  themeToggle: boolean = null;
  loaded = false;
  isAdmin: UserType = {
    role: null
  };
  constructor(private breakpointObserver: BreakpointObserver,
              public router: Router,
              private user: UsersServices,
              private ticket: TicketSender,
              public mapService: MapService) {
  }

  toggleActive(type: string) {
    if(type === 'messages') {
      this.activeTickets = !this.activeTickets
      this.searchActive === true ? this.searchActive = false : null;
    }
    if(type === 'search') {
      this.searchActive = !this.searchActive;
      this.activeTickets === true ? this.activeTickets = false : null;
    }
  }
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
      .pipe(
          map(result => result.matches),
          shareReplay()
      );
  exit() {
    localStorage.clear();
    this.router.navigate(['/login']);
  }
  toggleTheme(e) {
    switch (e.checked) {
      case true: {
        this.mapService.themeToggle.next('light')
        break;
      }
      case false: {
        this.mapService.themeToggle.next('dark')
        break;
      }
    }
  }
  ngOnInit() {
    this.themeToggle = {light: true, dark: false}[this.mapService.themeToggleData as string]
    this.user.getCurrentUser();
    this.user.currentUserLoaded.subscribe(() => {
      let currentRoleName = 'none';
      if (this.user.currentUser[0].roleId !== null) {
        currentRoleName = this.user.currentRoles.filter(e => e.id === this.user.currentUser[0].roleId)[0].name;
      } else {
        currentRoleName = 'none';
      }
      this.isAdmin = {
        role: currentRoleName
      };
      this.userData = this.user.currentUser[0];
      this.loaded = true;
    })
  }

}
