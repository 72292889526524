<div class="card card-chart">
        <div class="card-body">
            <h4 class="card-title">{{title}}</h4>
            <form [formGroup]="dateGroup">
            <div class="row">
                    <div class="col-md-6">
                        <mat-form-field color="accent" appearance="fill">
                            <mat-label>{{datePickerTitleStart}}</mat-label>
                            <input formControlName="startDate" matInput [matDatepicker]="picker1">
                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                            <mat-datepicker #picker1></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6">
                        <mat-form-field color="accent" appearance="fill">
                            <mat-label>{{datePickerTitleEnd}}</mat-label>
                            <input formControlName="endDate" matInput [matDatepicker]="picker2">
                            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                            <mat-datepicker #picker2></mat-datepicker>
                        </mat-form-field>
                    </div>
            </div>
            </form>
            <div class="row">
                <div class="col-md-12 d-flex" style="gap: 10px">
                    <button (click)="exportData()" [disabled]="!dateGroup.valid" mat-raised-button color="primary">Экспорт</button>
                </div>
            </div>
        </div>
</div>
