<section [class]="map.themeToggleData === 'dark' ? 'example-section' : 'example-section-light'">
  <mat-radio-group
          aria-labelledby="example-radio-group-label"
          class="example-radio-group"
          [(ngModel)]="favoriteSeason">
    <div style="display: flex; flex-direction: column">
      <mat-radio-button class="example-radio-button" (change)="toggleFilter($event)" *ngFor="let season of seasons" [value]="season">
        <div style="display: flex;flex-direction: row-reverse;justify-content: center;align-items: center;" class="filtered_items">
          <mat-icon *ngIf="checkColor('gray', renderFilter(season)) > 0" style="color: gray; font-size: 16px; width: 15px;" aria-hidden="false" aria-label="Example home icon" class="marker">location_on</mat-icon>
          <mat-icon *ngIf="checkColor('red', renderFilter(season)) > 0" style="color: red; font-size: 16px; width: 15px;" aria-hidden="false" aria-label="Example home icon" class="marker">location_on</mat-icon>
          <mat-icon *ngIf="checkColor('green', renderFilter(season)) > 0" style="color: green; font-size: 16px; width: 15px;" aria-hidden="false" aria-label="Example home icon" class="marker">location_on</mat-icon>
          <mat-icon *ngIf="checkColor('blue', renderFilter(season)) > 0" style="color: blue; font-size: 16px; width: 15px;" aria-hidden="false" aria-label="Example home icon" class="marker">location_on</mat-icon>
          <mat-icon *ngIf="checkColor('yellow', renderFilter(season)) > 0" style="color: yellow; font-size: 16px; width: 15px;" aria-hidden="false" aria-label="Example home icon" class="marker">location_on</mat-icon>
          <mat-icon *ngIf="checkColor('orange', renderFilter(season)) > 0" style="color: orange; font-size: 16px; width: 15px;" aria-hidden="false" aria-label="Example home icon" class="marker">location_on</mat-icon>
          <span>{{'- ' + renderFilter(season).length}}</span>
          <mat-form-field style="width: 100px;">
            <mat-label>{{season}}</mat-label>
            <mat-select (selectionChange)="onSelected($event)">
              <mat-option *ngFor="let food of renderFilter(season)" [value]="food">
                {{food?.user?.secondName}} {{food?.user?.firstName?.slice(0, 1)}}. {{food?.user?.patronymic?.slice(0, 1)}}.
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </mat-radio-button>
    </div>
  </mat-radio-group>
</section>
<mgl-map (zoomEnd)="zoomController($event)" (load)="onMapLoad($event)" *ngIf="loaded" [style]="map.themeToggleData === 'dark' ? 'mapbox://styles/mafahes/ck7ytniby1cm81jlktlfxfuuh' : 'mapbox://styles/mafahes/ckh4uzf9g05wr1as4cdw4oof2'" [zoom]="[9]" [center]="[37.620950, 55.753094]">
  <ng-container *ngFor="let item of (filteredItems.length || filterActive ? filteredItems : markersData); let ind = index">
    <mgl-marker
      #myMarker
      [offset]="[0, zoom > 11.7 ? -31 : 0]"
      [lngLat]="[item.position.longitude, item.position.latitude]"
      [draggable]="draggable"
      [className]="item.manual_control ? 'manual' : ''"
      (dragEnd)="onDrag(item, $event, ind)"
    >
      <div style="display: flex; justify-content: center; flex-direction: column; align-items: center">
        <div *ngIf="zoom > 11.7" style="width: inherit;position: fixed;margin-bottom: 80px;" class="popup-wrapper">
          <mat-icon aria-hidden="false" aria-label="Example home icon" class="marker marker_icon">contact_mail</mat-icon>
          <span style="width: max-content" class="popup-text">{{item?.user?.secondName}} {{item?.user?.firstName?.slice(0, 1)}}.{{item?.user?.patronymic?.slice(0,1)}}.</span>
        </div>
<!--        <span style="color: white">{{item.status}} / {{item.substatus}}</span>-->
        <mat-icon [style]="'cursor: pointer; transform: rotate(-' + item.position.course + 'deg); color: ' + item.inits" aria-hidden="false" aria-label="Example home icon" class="marker">location_on</mat-icon>
      </div>
    </mgl-marker>
    <mgl-popup [marker]="myMarker"><mat-card style="margin-top: 5px" class="example-card">
      <mat-card-header>
        <mat-card-title>{{item.user.secondName}} {{item.user.firstName?.slice(0, 1)}}.{{item.user.patronymic?.slice(0,1)}}.</mat-card-title>
        <mat-card-subtitle>{{item.device.status === 'online' ? 'Онлайн' : 'Оффлайн'}}
          <ng-container *ngIf="item.inits !== 'gray' && item.device.status !== 'offline' && item.status === true">
            ({{(item.substatus === '' || item.substatus === null) ? item.hasTickets ? 'Занят' : 'Свободен' : item.substatus === 'on_escort' ? 'На сопровождении' : item.substatus === 'on_document' ? 'На оформлении документов' : 'На выдаче'}})
          </ng-container></mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <div class="container-marker">
          <div class="popup-wrapper">
            <mat-icon aria-hidden="false" aria-label="Example home icon" class="marker marker_icon">verified_user</mat-icon>
            <span class="popup-text">ID: {{item.uniqueId}}</span>
          </div>
          <div class="popup-wrapper">
            <mat-icon aria-hidden="false" aria-label="Example home icon" class="marker marker_icon">speed</mat-icon>
            <span class="popup-text">{{item.position.speed}} км/ч</span>
          </div>
<!--          <div class="popup-wrapper">-->
<!--            <mat-icon aria-hidden="false" aria-label="Example home icon" class="marker marker_icon">directions_walk</mat-icon>-->
<!--            <span class="popup-text">{{math.round((item.position.attributes.totalDistance/1000)*100)/100}} км</span>-->
<!--          </div>-->
          <div *ngIf="!!item.position && !!item.position.attributes && item.position.attributes.hasOwnProperty('io5') && item.position.attributes.io5 !== null" class="popup-wrapper">
            <mat-icon aria-hidden="false" aria-label="Example home icon" class="marker marker_icon">battery_charging_full</mat-icon>
            <span class="popup-text">{{item.position.attributes.io5}}%</span>
          </div>
          <div *ngIf="item.device.phone !== null" class="popup-wrapper">
            <mat-icon aria-hidden="false" aria-label="Example home icon" class="marker marker_icon">phone</mat-icon>
            <span class="popup-text">{{item.device.phone}}</span>
          </div>
          <div *ngIf="draggable" class="popup-wrapper" style="width: 100%; flex-direction: column; align-items: flex-start;">
            <mat-checkbox color="primary" [(ngModel)]="item.manual_control" (change)="onManualChange($event, item, ind)" class="example-margin">Ручной режим</mat-checkbox>
            <span class="popup-text" *ngIf="item.start_manual_control !== '0001-01-01T00:00:00' && item.manual_control">{{item.start_manual_control | date: 'dd.MM.yyyy HH:mm'}}</span>
          </div>
          <div *ngIf="item.activeTicket.length" class="popup-wrapper" style="width: 100%; flex-direction: column; align-items: flex-start;">
            <span class="popup-text">Активный заказ</span>
            <hr style="width: 100%;">
            <span class="popup-text">Добавлен: {{item.activeTicket[0].dateAdd | date:'dd MMM yyy'}}</span>
            <span class="popup-text">Описание: {{item.activeTicket[0].comment}}</span>
            <hr style="width: 100%;">
            <span class="popup-text">Адрес: {{item.activeTicket[0].applicant_address}}</span>

          </div>
        </div>
      </mat-card-content>
    </mat-card></mgl-popup>
  </ng-container>
</mgl-map>
