import { Component, OnInit } from '@angular/core';
import {MangoService} from "../../../shared/services/mango.service";
import {CallObj, CallObjV2} from "../../../shared/interfaces/mango";
import {DatePipe} from "@angular/common";
import {Router} from "@angular/router";
import {FormBuilder, Validators} from "@angular/forms";
import * as _ from 'lodash';
import {MangoArray} from "../../../shared/interfaces/mangoArray";

@Component({
  selector: 'app-mango',
  templateUrl: './mango.component.html',
  styleUrls: ['./mango.component.scss']
})
export class MangoComponent implements OnInit {

  constructor(private mango: MangoService, private fb: FormBuilder, private datePipe: DatePipe, private router: Router) { }
  dateGroup = this.fb.group({
    start: [null, Validators.required],
    finish: [null, Validators.required]
  })
  callData: CallObjV2[];
  data: MangoArray[];
  page = 0;
  onCancel() {
    this.router.navigate(['/admin'])
  }
  ngOnInit(): void {
    this.dateGroup.valueChanges.subscribe((e) => {
      if(e.start !== null && e.finish !== null) {
        this.mango.getCalls(this.dateGroup.value).subscribe((e) => {
          this.data = _.chain(e)
              .groupBy("from_number")
              .map((value, key) => ({ number: key, data: value }))
              .value();
          console.log(this.data);
          this.callData = e;
        })
      }
    })
    // this.mango.getCalls().subscribe((e) => {
    //   console.log(e);
    //   this.callData = e;
    // })
  }

}
