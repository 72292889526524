<mat-card *ngIf="loaded" style="margin: 10px;">
    <mat-card-header>
        <mat-card-title>Редактировать девайс</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div class="row">
            <div class="col-md-12">
                <mat-form-field class="example-full-width">
                    <input type="text"
                           placeholder="Поиск трэкера"
                           matInput
                           [formControl]="myControl"
                           [matAutocomplete]="auto">
                    <mat-autocomplete [displayWith]="displayFn" (optionSelected)="onSelectValue($event.option.value)" autoActiveFirstOption #auto="matAutocomplete">
                        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">{{option.uniqueId}}</mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>
        <form [formGroup]="deviceForm">
            <div class="row">
                <div class="col-md-6">
                    <mat-form-field class="example-full-width">
                        <mat-label>Название</mat-label>
                        <input formControlName="name" [disabled]="selectedDevice === null" matInput>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="example-full-width">
                        <mat-label>Телефон</mat-label>
                        <input formControlName="phone" [disabled]="selectedDevice === null" matInput>
                    </mat-form-field>
                </div>
            </div>
        </form>
        <button (click)="onSave()" [disabled]="selectedDevice === null" mat-raised-button color="primary">Сохранить</button>
    </mat-card-content>
</mat-card>
