<mat-spinner *ngIf="!loaded"></mat-spinner>
<form *ngIf="loaded" #aForm="ngForm" [formGroup]="agentForm" novalidate (ngSubmit)="onSubmit()">
  <mat-card style="margin: 10px" class="shipping-card">
    <mat-card-header>
      <mat-card-title>Создать агента</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <input matInput placeholder="e-mail" formControlName="email">
            <mat-error *ngIf="agentForm.controls['email'].hasError('required')">
              E-mail <strong>обязателен</strong>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <mat-select placeholder="Привязка к пользователю" formControlName="userId">
              <mat-option *ngFor="let user of userData" [value]="user.id">
                Имя: {{ user.firstName }} / E-mail: {{user.email}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="agentForm.controls['userId'].hasError('required')">
              Пользователь <strong>обязателен</strong>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <mat-select placeholder="Привязка к агенту 1C" formControlName="user1cUniqueId">
              <mat-option *ngFor="let user of agentsData" [value]="user.agentID">
                {{user.fio}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="agentForm.controls['user1cUniqueId'].hasError('required')">
              Пользователь 1С <strong>обязателен</strong>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <mat-select placeholder="Привязка к геотрэкеру" formControlName="uniqueId">
              <mat-option [disabled]="device !== null && device.hasOwnProperty('lastUpdate') && device.lastUpdate === null" *ngFor="let device of deviceDataFiltered" [value]="device.uniqueId">
                Название: {{device.name}} / ID: {{device.uniqueId}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="agentForm.controls['uniqueId'].hasError('required')">
              Геотрэкер <strong>обязателен</strong>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field appearance="fill">
            <mat-label>Дата рождения</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="dateBirth">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-radio-group formControlName="sex">
            <mat-radio-button [value]="true">Мужчина</mat-radio-button>
            <mat-radio-button [value]="false">Женщина</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <button [disabled]="!agentForm.valid" mat-raised-button color="primary" type="submit">Создать</button>
    </mat-card-actions>
  </mat-card>
</form>
