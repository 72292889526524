import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Conf} from '../conf';

@Injectable({
  providedIn: 'root'
})
export class AgentService {

  constructor(private http: HttpClient) {}

  createAgent(data) {
    return this.http.post(`${Conf.APIBASEURL}api/agentUser`, data);
  }
  editAgentConfig(data) {
    return this.http.put(`${Conf.APIBASEURL}api/agentUser/update`, data);
  }
}
