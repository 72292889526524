<div *ngIf="currentUser === null" style="width: 100%; height: 100%; display: flex; justify-content: center; align-items: center">
    <mat-spinner></mat-spinner>
</div>
<div class="container my-3" *ngIf="currentUser !== null && error === false">
    <div class="row">
        <div class="col-md-12">
            <mat-card>
                <div class="row">
                    <div class="col-md-9 p-3 d-flex flex-column">
                        <span class="header-title">Редактирование профиля</span>
                        <span class="header-subtitle">Обновите информацию о данном пользователе</span>
                        <form [formGroup]="userForm">
                            <div class="row mt-4">
                                <div class="col-md-4">
                                    <mat-form-field class="example-full-width">
                                        <mat-label>Фамилия</mat-label>
                                        <input formControlName="secondName" matInput placeholder="Иванов">
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="example-full-width">
                                        <mat-label>Имя</mat-label>
                                        <input formControlName="firstName" matInput placeholder="Иван">
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="example-full-width">
                                        <mat-label>Отчество</mat-label>
                                        <input formControlName="patronymic" matInput placeholder="Иванович">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <mat-form-field class="example-full-width">
                                        <mat-label>E-mail</mat-label>
                                        <input formControlName="email" matInput placeholder="email@mosritual.ru">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row" *ngIf="currentAgent !== null">
                                <div class="col-md-12">
                                    <mat-form-field appearance="fill">
                                        <mat-label>Привязка</mat-label>
                                        <mat-select (selectionChange)="onSelect($event)" [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedDevice" name="devices">
<!--                                            <mat-option value="0">-->
<!--                                                Без привязки-->
<!--                                            </mat-option>-->
                                            <mat-option [disabled]="item.category" *ngFor="let item of allDevices" [value]="item.uniqueId">
                                                ID: {{item.uniqueId}} / {{item.name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <ng-container *ngIf="currentAgent !== null">
                                <app-user-status [agentData]="currentAgent"></app-user-status>
                            </ng-container>
                        </form>
                        <button (click)="onSave()" [disabled]="!userForm.valid" mat-stroked-button color="primary">Сохранить</button>
                        <button (click)="passDialogRef()" mat-stroked-button color="warn" class="my-2">Сменить пароль</button>
                        <button (click)="onDelete()" mat-stroked-button color="warn">Удалить пользователя</button>
                    </div>
                    <div class="d-flex flex-column justify-content-start align-items-center col-md-3 p-3">
                        <img style="width: 100px; height: 100px;" src="assets/img/businessman-icon-png-263229-free-icons-library-businessman-icon-png-512_512.jpg"/>
                        <span style="color: #bdbdbd;" class="mt-2">{{currentUser.email}}</span>
                    </div>
                </div>
            </mat-card>
        </div>
    </div>
</div>
