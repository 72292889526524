import {Component, OnInit, ViewChild} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {TicketSender} from '../../../shared/services/tickets.service';
import {NotifierMsgService} from "../../../shared/services/notifier-msg.service";
import {UsersServices} from "../../../shared/services/users.service";
import {DeviceObject} from "../../../shared/interfaces/device";

@Component({
  selector: 'app-admin-device',
  templateUrl: './admin-device.component.html',
  styleUrls: ['./admin-device.component.css']
})
export class AdminDeviceComponent implements OnInit{
  deviceForm = this.fb.group({
    name: [null, Validators.required],
    uniqueId: [null, Validators.required],
    phone: null,
    // lastName: [null, Validators.required],
    // state: [null, Validators.required],
    disabled: false
  });
  deviceData: DeviceObject[] = [];
  loading = true;
  hasUnitNumber = false;
  @ViewChild('deviceWatch') deviceWatch;


  states = [
    {name: 'Категории', abbreviation: 'type'},
  ];

  constructor(private fb: FormBuilder,
              private deviceTicket: TicketSender,
              private notifier: NotifierMsgService,
              private user: UsersServices) {}
  onSubmit() {
    let deviceExist = this.deviceData.filter(e => e.uniqueId === this.deviceForm.controls['uniqueId'].value).length > 0
    if(deviceExist) {
      this.notifier.notify('error', 'Трекер уже существует!');
    } else {
      this.deviceTicket.addDevice(this.deviceForm.value).subscribe(e => {
        this.notifier.notify('success', 'Новый трэкер добавлен!');
        this.deviceWatch.resetForm();
      }, error => {
        this.notifier.notify('error', 'Произошла ' + error.status + ' ошибка!');
      });
    }
  }
  ngOnInit() {
    this.user.getDevicesAll().subscribe((e) => {
      this.deviceData = e;
      this.loading = false;
    })
  }
}
