<mat-card style="margin: 10px; z-index: 0">
  <span class="mat-display-1" style="font-size: 16px  ">Текущие доп. поля формы ТИП2</span>
  <mat-divider style="margin-bottom: 15px"></mat-divider>
  <mat-card style="margin-top: 15px">
    <div style="display: flex; align-items: center; gap: 10px;">
      <mat-form-field appearance="fill">
        <mat-label>Название</mat-label>
        <input (input)="creatorNameField = $event.target.value" matInput>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Тип поля</mat-label>
        <mat-select (selectionChange)="creatorTypeField = $event.value">
          <mat-option *ngFor="let field of typeOfFields" [value]="field.type">{{field.label}}</mat-option>
        </mat-select>
      </mat-form-field>
      <div class="example-button-container">
        <button (click)="getFieldCreator()" mat-mini-fab color="primary" aria-label="Example icon button with a home icon">
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </div>
  </mat-card>
  <mat-card *ngFor="let item of fields" style="margin-top: 15px">
    <div style="display: flex; align-items: center; gap: 10px;">
      <span>{{item.text}}</span>
      <button (click)="selectItem(item)" mat-flat-button color="primary">Редактировать</button>
      <div class="example-button-container">
        <button (click)="deleteValue(item)" mat-mini-fab color="warn" aria-label="Example icon button with a home icon">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
    <div class="row" *ngIf="item.id === selectedItem">
      <div class="col">
        <mat-card style="margin-top: 15px">
          <mat-form-field appearance="fill">
            <mat-label>Название</mat-label>
            <input matInput [value]="item.text">
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Тип поля</mat-label>
            <mat-select>
              <mat-option *ngFor="let field of typeOfFields" [value]="field.type">{{field.label}}</mat-option>
            </mat-select>
          </mat-form-field>
        </mat-card>
      </div>
      <div class="col"></div>
      <div class="col"></div>
    </div>
  </mat-card>


</mat-card>
