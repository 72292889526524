import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import {UsersServices} from '../../../shared/services/users.service';
import {NotifierMsgService} from '../../../shared/services/notifier-msg.service';
import {AgentService} from '../../../shared/services/agent.service';
import {forkJoin} from "rxjs";
import {UserList} from "../../../shared/interfaces/userList";
import {DeviceObject} from "../../../shared/interfaces/device";

@Component({
  selector: 'app-agent-add',
  templateUrl: './agent-add.component.html',
  styleUrls: ['./agent-add.component.css']
})
export class AgentAddComponent implements OnInit {
  userData: UserList[]
  deviceDataFiltered: DeviceObject[];
  @Input() agentsData: any;
  @Input() devicesData: DeviceObject[];
  @ViewChild('aForm') aForm;
  loaded = false;

  agentForm = this.fb.group({
    email: [null, [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
    userId: [null, Validators.required],
    user1cUniqueId: [null, Validators.required],
    uniqueId: [null, Validators.required],
    dateBirth: [null, Validators.required],
    sex: [true, Validators.required]
  });

  constructor(private fb: FormBuilder,
              private agentService: AgentService,
              private notifier: NotifierMsgService,
              private user: UsersServices) {}
  ngOnInit(): void {
    forkJoin([
        this.user.getAllUsers(),
        this.user.getAgentsAPI()
    ]).subscribe((data) => {
      try {
        const currentRole = this.user.currentRoles.find(e => e.name === "agent");
        let dataUser: UserList[] = [];
        let deviceObj = [];
        let usrs = data[0].filter(e => e.roleId === currentRole.id).forEach((e) => {
          if(!data[1].some((item) => item.user.email === e.email)) {
            dataUser = [...dataUser, e]
          }
        })
        //test
        this.devicesData.forEach((e) => {
          if(!data[1].some((item) => item.device.uniqueId === e.uniqueId)) {
            deviceObj = [...deviceObj, e]
          }
        })
        this.userData = dataUser;
        this.deviceDataFiltered = deviceObj;
        this.loaded = true;
      } catch (e) {
        console.log(e)
        this.deviceDataFiltered = this.devicesData;
        this.userData = data[0];
        this.loaded = false;
      }
    })
  }

  onSubmit() {
    const {lastName, email, sex, dateBirth, userId, user1cUniqueId, uniqueId, fip} = this.agentForm.value;
    this.agentService.createAgent({
      userId,
      user1cUniqueId,
      uniqueId,
      isDelete: false
    }).subscribe(data => {
      this.notifier.notify('success', 'Агент создан!');
      this.aForm.resetForm();
    });
  }
}
