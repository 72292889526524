<div class="row">
    <div class="col-md-5">
        <mat-form-field *ngIf="status !== null" appearance="fill">
            <mat-label>Статус</mat-label>
            <mat-select [disabled]="substatus !== ''" (selectionChange)="onStatusSelect($event)" [(ngModel)]="status">
                <mat-option [value]="true">
                    На смене
                </mat-option>
                <mat-option [value]="false">
                    Не на смене
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-md-5">
        <mat-form-field *ngIf="substatus !== null" appearance="fill">
            <mat-label>Подстатус</mat-label>
            <mat-select [disabled]="status === false" (selectionChange)="onSubstatusSelect($event)" [(ngModel)]="substatus">
                <mat-option value="">
                    Свободен
                </mat-option>
                <mat-option value="on_escort">
                    На сопровождении
                </mat-option>
                <mat-option value="on_document">
                    На оформлении документов
                </mat-option>
                <mat-option value="on_issue">
                    На выдаче
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-md-2">
        <button (click)="clearAll()" matTooltip="Сбросить все статусы" mat-raised-button color="warn">
            <mat-icon aria-hidden="false" aria-label="Example home icon">clear</mat-icon>
        </button>
    </div>
</div>
