import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LogsService} from "../../../shared/services/logs.service";
import {LogObject} from "../../../shared/interfaces/logs";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {DatePipe} from "@angular/common";
import {ExcelFileExporterService} from "../../../shared/services/excel-file-exporter.service";

@Component({
  selector: 'app-admin-logs',
  templateUrl: './admin-logs.component.html',
  styleUrls: ['./admin-logs.component.css']
})
export class AdminLogsComponent implements OnInit {
  logArray: LogObject[] = [];
  constructor(private log: LogsService,
              public dialog: MatDialog,
              private datePipe: DatePipe,
              private excelExp: ExcelFileExporterService) { }
  settingsTable = {
    actions: false,
    noDataMessage: 'Список пуст',
    columns: {
      id: {
        title: 'ID'
      },
      info: {
        title: 'Пользователь'
      },
      dateAdd: {
        title: 'Время',
      },
      work: {
        title: 'Действие'
      },
      ip: {
        title: 'IP'
      },
      userAgent: {
        title: 'Точка входа'
      }
    }
  };
  openDialog() {
    let dialogConf = new MatDialogConfig();
    dialogConf.disableClose = true;
    const dialogRef = this.dialog.open(AdminLogsAlert, dialogConf);

    dialogRef.afterClosed().subscribe(result => {
      this.log.getLogs().subscribe((e) => {
        this.logArray = e.map(item => {
          return {
            ...item,
            dateAdd: this.datePipe.transform(item.dateAdd, 'dd MMMM HH:mm'),
            info: item.user.email
          }
        });
      })
    })
  }
  exportToFile() {
    this.excelExp.exportAsExcelFile(this.logArray, 'LogFile')
  }
  ngOnInit(): void {

  }

}
@Component({
  selector: 'admin-logs-alert',
  templateUrl: 'admin-logs-alert.html',
})
export class AdminLogsAlert {}
@Component({
  selector: 'log-cell',
  templateUrl: 'admin-logs-table-ip.html'
})
export class AdminLogsIpCell {
  renderValue: string;

  @Input() value: string | number;
  @Input() rowData: any;
}
