import { Injectable } from '@angular/core';

import {HttpClient} from '@angular/common/http';
import {Conf} from '../conf';
import {forkJoin, Observable, Subject} from 'rxjs';
import {UserForm} from "../interfaces/userForm";
import {MapItem} from "../interfaces/mapMarkers";
import {CallObject} from "../interfaces/callRequest";
import {DeviceObject} from "../interfaces/device";
import {UserList} from "../interfaces/userList";
import {map} from "rxjs/operators";
import {AgentState} from "../interfaces/agentStates";
import {HeaderComponent} from "../../header/header.component";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class UsersServices {

  currentUser: any = false;
  currentUserLoaded: Subject<boolean> = new Subject<boolean>();
  currentRoles: any = false;
  currentRolesLoaded: Subject<boolean> = new Subject<boolean>();
  constructor(private http: HttpClient, private router: Router) { }

  getCurrentUser() {
    this.http.get(`${Conf.APIBASEURL}api/users/0`).subscribe((data) => {
      if((data[0]?.role?.name ?? 'admin') === 'agent') {
        localStorage.clear();
        this.router.navigate(['/login']);
      }
      this.currentUser = data;
      this.currentUserLoaded.next(true);
    });
  }
  getAgentStates(data): Observable<AgentState[]> {
    return this.http.post<AgentState[]>(`${Conf.APIBASEURL}api/stats/v2/agentstates-id`, data).pipe(
        map((e2) => {
          var source: AgentState[] = e2;
          console.log(source);
          source = source.filter((e, i) => {
            // if(!e.status && (e.substatus === "" || e.substatus === null)) {
            //   return false;
            // }
            if(i !== 0) {
              if(source[i - 1].status === source[i].status && source[i].substatus === source[i - 1].substatus) {
                return false;
              } else {
                return e;
              }
            } else {
              return e;
            }
          })
          return source
        })
    )
  }
  getAllAgentStates(data): Observable<AgentState[]> {
    return this.http.post<AgentState[]>(`${Conf.APIBASEURL}api/stats/agentstates`, data)
  }
  getUserById(id: string): Observable<UserList> {
    return this.http.get<UserList>(`${Conf.APIBASEURL}api/user/${id}`)
  }
  getCallReq(): Observable<CallObject[]> {
    return this.http.get<CallObject[]>(`${Conf.APIBASEURL}api/CallRequest`);
  }
  createUser(data) {
    return this.http.post(`${Conf.APIBASEURL}api/users/add`, data);
  }
  getDevicesAll(): Observable<DeviceObject[]> {
    return this.http.get<DeviceObject[]>(`${Conf.APIBASEURL}api/devices`);
  }
  editDevice(data) {
    return this.http.put(`${Conf.APIBASEURL}api/devices`, data);
  }
  getAllUsers(): Observable<UserList[]> {
    return this.http.get<UserList[]>(`${Conf.APIBASEURL}api/users/all`);
  }
  getAgents() {
    return this.http.get(`${Conf.APIBASEURL}api/agents`);
  }
  setAgentConfig(data) {
    return this.http.put(`${Conf.APIBASEURL}api/agentUser/update`, data);
  }
  getAgentsAPI(): Observable<MapItem[]> {
    return this.http.get<MapItem[]>(`${Conf.APIBASEURL}api/agentUser`).pipe(map((item) => {
      return item.filter(e => e.device !== null && e.position !== null && e.user.firstName !== null && e.user.secondName !== null && e.user.patronymic !== null)
    }));;
  }
  updateUser(data) {
    return this.http.put(`${Conf.APIBASEURL}api/users`, data);
  }
  updateOtherUser(data) {
    return this.http.put(`${Conf.APIBASEURL}api/users/edit`, data);
  }
  deleteUser(agentId, userId) {
    return forkJoin([
      this.http.delete(`${Conf.APIBASEURL}api/users?id=${agentId}`),
      this.http.delete(`${Conf.APIBASEURL}api/agentUser?id=${userId}`)
    ])
  }
  deleteOnlyUser(id) {
    return this.http.delete(`${Conf.APIBASEURL}api/users?id=${id}`)
  }
  getRoles() {
    this.http.get(`${Conf.APIBASEURL}auth/roles`).subscribe(data => {
      this.currentRoles = data;
      this.currentRolesLoaded.next(true);
    });
  }
}
