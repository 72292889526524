import { Component, OnInit } from '@angular/core';
import {UsersServices} from "../../../../shared/services/users.service";
import {UserList} from "../../../../shared/interfaces/userList";
import {UserButtonTableComponent} from "./user-button/user-button-table/user-button-table.component";
import {ExcelFileExporterService} from "../../../../shared/services/excel-file-exporter.service";

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  constructor(private user: UsersServices, private excel: ExcelFileExporterService) { }

  UserData: UserList[] = []
  settingsTable = {
    actions: false,
    noDataMessage: 'Список пуст',
    columns: {
      nummer: {
        title: 'Номер пользователя',
      },
      email: {
        title: 'Почта'
      },
      firstName: {
        title: 'Ф.И.О.'
      },
      roleType: {
        title: 'Роль',
        filter: {
          type: 'list',
          config: {
            selectText: 'Показать всех',
            list: [
              { value: 'Агент', title: 'Агент' },
              { value: 'Диспетчер', title: 'Диспетчер' },
              { value: 'Админ', title: 'Администратор' },
              { value: 'Суперменеджер', title: 'Суперменеджер' },
            ]
          }
        }
      },
      phoneNumber: {
        title: 'Номер телефона'
      },
      editbtn: {
        type: 'custom',
        filter: false,
        title: '',
        renderComponent: UserButtonTableComponent
      }
    }
  };
  exportData(type) {
    if(type === 1) {
      this.excel.exportAsExcelFile(this.UserData, 'Пользователи ')
    }
    if(type === 2) {
      this.excel.exportAsExcelFile(this.UserData.filter(e => e.role.description === "dispatcher").map(e => {
        return {
          ФИО: e.secondName + " " + e.firstName.slice(0, 1) + ". " + e.patronymic.slice(0, 1) + ". ",
          Почта: e.email
        }
      }), 'Диспетчеры ')
    }
    if(type === 3) {
      this.user.getAgentsAPI().subscribe((item) => {
        this.excel.exportAsExcelFile(this.UserData.filter(e => e.role !== undefined && e.role.description === "agent").map(e => {
          let curAgent = item.filter(user => user.userId === e.id);
          return {
            ФИО: e.secondName + " " + e.firstName.slice(0, 1) + ". " + e.patronymic.slice(0, 1) + ". ",
            Почта: e.email,
            АктивныеЗаказы: curAgent.length ? curAgent[0].activeTicket.length : 0,
            Трэкер: curAgent.length ? curAgent[0].uniqueId : 0,
            Телефон: curAgent.length ? curAgent[0].device.phone : 0
          }
        }), 'Диспетчеры ')
      })
    }
  }
  ngOnInit(): void {
    this.user.getAllUsers().subscribe((data) => {
      this.UserData = data.map((e) => {
        return {
          ...e,
          firstName: e.secondName + " " + e.firstName + " " + e.patronymic,
          roleType: {agent: "Агент", dispatcher: 'Диспетчер', admin: 'Админ', supermanager: 'Суперменеджер'}[e.role.description]
        }
      });
    })
  }
}
