import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Conf, MAP_API, MAP_API_FORMATTER, MAP_API_SECRET, MAP_API_TOKEN} from '../conf';
import {Observable, Subject} from "rxjs";
import {map} from "rxjs/operators";
import {TicketObject} from "../interfaces/ticket";
import {MapItem} from "../interfaces/mapMarkers";
import {Statistic} from "../interfaces/Statistic";
import {LngLat} from "mapbox-gl";
export interface NotFinTicket {
  value: number;
  state: boolean;
}
@Injectable({
  providedIn: 'root'
})
export class MapService {
  devicesLoaded: Subject<boolean> = new Subject<boolean>();
  mapTriggerSearch: Subject<MapItem> = new Subject<MapItem>();
  themeToggle: Subject<String> = new Subject<String>();
  themeToggleData: String = null;
  currentDevices: any = false;
  constructor(private http: HttpClient) {
    this.themeToggle.subscribe((e) => {
      localStorage.setItem('theme', e as string);
      this.themeToggleData = e;
    })
  }
  getMapMarkers(): Observable<MapItem[]> {
    return this.http.get<MapItem[]>(`${Conf.APIBASEURL}api/agentUser/v2`).pipe(map((item) => {
      return item.filter(e => e.device !== null && e.position !== null && e?.user?.firstName !== undefined && e?.user?.secondName !== undefined && e?.user?.patronymic !== undefined )
    }));
  }
  setControl(agentId: number, manual: boolean): Observable<any> {
    return this.http.patch(`${Conf.APIBASEURL}api/agentUser/update-agent-control?agentId=${agentId}&manual=${manual}`, null);
  }
  setPosition(agentId: number, pos: LngLat): Observable<any> {
    return this.http.patch(`${Conf.APIBASEURL}api/agentUser/update-position?agentId=${agentId}&latitude=${pos.lat}&longitude=${pos.lng}`, null);
  }
  getStatistic(): Observable<Statistic> {
    return this.http.get<Statistic>(`${Conf.APIBASEURL}api/dashboard`);
  }
  getNotFinishedTickets(data): Observable<NotFinTicket> {
    return this.http.post<NotFinTicket>(`${Conf.APIBASEURL}api/dashboard/count-not-tickets`, data);
  }
  getFinishedTickets(data): Observable<NotFinTicket> {
    return this.http.post<NotFinTicket>(`${Conf.APIBASEURL}api/dashboard/count-finish-tickets`, data);
  }
  getAddress(string) {
    return this.http.post(`${MAP_API}`, {
          "query": string,
          "locations": [
            {
              "region_iso_code": "RU-MOS"
            },
            {
              "region_iso_code": "RU-MOW"
            }
          ]
        },
        {
          headers: {
            "X-Secret": MAP_API_SECRET,
            "Authorization": "Token " + MAP_API_TOKEN
          }
        })
  }
  getDevices() {
    this.http.get(`${Conf.APIBASEURL}api/devices`).subscribe(data => {
      this.currentDevices = data;
      this.devicesLoaded.next(true);
    });
  }
}
