import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Params, Router} from "@angular/router";
import {switchMap} from "rxjs/operators";
import {TicketSender} from "../../shared/services/tickets.service";
import {TicketObject} from "../../shared/interfaces/ticket";
import {FormBuilder, Validators} from "@angular/forms";
import {NotifierMsgService} from "../../shared/services/notifier-msg.service";
import {UsersServices} from "../../shared/services/users.service";
import {MapItem} from "../../shared/interfaces/mapMarkers";
import {forkJoin} from "rxjs";
import {Metro} from "../../shared/interfaces/metro";
import {Morgue} from "../../shared/interfaces/morgue";
import {TicketById, History} from "../../shared/interfaces/ticketById";

@Component({
  selector: 'app-ticket-edit',
  templateUrl: './ticket-edit.component.html',
  styleUrls: ['./ticket-edit.component.scss']
})
export class TicketEditComponent implements OnInit {

  constructor(private route: ActivatedRoute, private notifier: NotifierMsgService,
              public user: UsersServices,
              private rroute: Router, private ticket: TicketSender, private fb: FormBuilder, private agent: UsersServices) { }
  loading = true;
  error = false;
  reason: string = null;
  duration = 0;
  distance = 0;
  ticketData: TicketById;
  ticketForm = this.fb.group({
    disp_firstName: 'fgfgh',
    disp_lastName: '',
    disp_patronymic: '',
    firstName: '',
    lastName: '',
    patronymic: '',
    questionType: '',
    placeOfService: '',
    comment: '',
    phone: '',
    additionalPhone: '',
    agentId: [null, Validators.required],
    status: [null, Validators.required],
    morgueId: null
  })
  loadedAgents: MapItem[] = null;
  // PARSE DATA
  loadedMetro: Metro[];
  loadedMorgues: Morgue[];
  history: History[];
  agentLabel: string = null;

  sendTicket() {
    const {text, name, ...dataReq} = this.ticketForm.value
    const { agentId, agent, ...dataReq2} = this.ticketData;
    this.ticket.editTicket({...dataReq2, ...dataReq }, this.ticketData.id).subscribe(() => {
      this.notifier.notify('success', 'Заявка изменена!')
    })
  }
  deleteTicket() {
    this.ticket.deleteTicket(this.ticketData.id).subscribe(() => {})
    this.rroute.navigate(['/admin']);
  }
  onCancel() {
    this.rroute.navigate(['/all-tickets'])
  }

  ngOnInit() {
    console.log(this.user.currentUser);
    this.ticketForm.controls['agentId'].valueChanges.subscribe(item => {
      try {
        let temp = this.loadedAgents.find(e => e.id === item);
        this.agentLabel = `${temp.user.secondName + ' ' + temp.user.firstName.slice(0, 1) + '. ' + temp.user.patronymic.slice(0, 1) + '.'}`;
      } catch (e) {
        this.agentLabel = item
      }
    })
    this.route.params.subscribe((params: Params) => {
      forkJoin([
        this.ticket.getMorgues(),
        this.ticket.getMetro(),
        this.agent.getAgentsAPI(),
        this.ticket.getTicketById(params['id'])
      ]).subscribe((data) => {
        this.loadedMetro = data[1];
        this.loadedMorgues = data[0];
        this.loadedAgents = data[2]
        this.history = data[3].history;
        const resp = data[3];
        this.duration = data[3].duration;
        this.distance = data[3].distance;
        console.log(data[3].reason)
        this.reason = data[3].reason;
        this.loadedAgents = [...this.loadedAgents, ...data[2].filter(e => e.id === resp.agentId)]
        if(resp !== undefined) {
          this.agentLabel = resp.user.secondName + ' ' + resp.user.firstName.slice(0, 1) + '. ' + resp.user.patronymic.slice(0, 1) + '.';
          this.loading = false;
          this.ticketData = resp
          this.ticketForm.setValue({
            additionalPhone: resp.additionalPhone,
            disp_firstName: resp.disp_firstName,
            disp_lastName: resp.disp_lastName,
            disp_patronymic: resp.disp_patronymic,
            firstName: resp.firstName || '',
            lastName: resp.lastName || '',
            patronymic: resp.patronymic || '',
            questionType: resp.questionType, // Тип обращения
            placeOfService: resp.placeOfService, // Место оказания услуги
            comment: resp.comment,
            phone: resp.phone,
            agentId: resp.agentId,
            status: resp.status,
            morgueId: resp.morgueId
          })
        } else {
          this.loading = false;
          this.error = true;
        }
      })
    });
  }

}
