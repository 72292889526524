<mat-sidenav-container class="sidenav-container">
  <mat-sidenav [style]="mapService.themeToggleData === 'dark' ? 'background-color: #1a2637' : 'background-color: #ffffff'" #drawer class="sidenav" fixedInViewport
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="(isHandset$ | async) === false">
    <mat-toolbar><span [style]="mapService.themeToggleData === 'dark' ? 'white-space: pre-wrap; color: white' : 'white-space: pre-wrap; color: black'">Профиль</span></mat-toolbar>
    <mat-nav-list>
      <a [style]="mapService.themeToggleData === 'dark' ? 'color: white' : 'color: black'" mat-list-item routerLink="/" *ngIf="isAdmin.role !== 'manager'"  [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active-button">Карта</a>
      <a [style]="mapService.themeToggleData === 'dark' ? 'color: white' : 'color: black'" (click)="ticketLoaded = !ticketLoaded" *ngIf="isAdmin.role === 'admin' || isAdmin.role === 'dispatcher' || isAdmin.role === 'supermanager'" mat-list-item routerLinkActive="active-button">Заявка</a>
      <a [style]="mapService.themeToggleData === 'dark' ? 'color: white' : 'color: black'" mat-list-item routerLink="/all-tickets" *ngIf="isAdmin.role === 'admin' || isAdmin.role === 'dispatcher' || isAdmin.role === 'supermanager'" routerLinkActive="active-button">Все заявки</a>
      <a [style]="mapService.themeToggleData === 'dark' ? 'color: white' : 'color: black'" mat-list-item [routerLink]="isAdmin.role === 'admin' ? '/admin' : '/mango'" *ngIf="isAdmin.role === 'manager' || isAdmin.role === 'admin' || isAdmin.role === 'supermanager'" routerLinkActive="active-button">Админ-панель</a>
      <a [style]="mapService.themeToggleData === 'dark' ? 'color: white' : 'color: black'" mat-list-item routerLink="/profile" routerLinkActive="active-button">Мой профиль</a>
      <a [style]="mapService.themeToggleData === 'dark' ? 'color: white' : 'color: black'" mat-list-item routerLink="/support" routerLinkActive="active-button">Поддержка</a>
      <a [style]="mapService.themeToggleData === 'dark' ? 'color: white' : 'color: black'" mat-list-item (click)="exit()">Выход</a>
      <a [style]="mapService.themeToggleData === 'dark' ? 'color: white' : 'color: black'" mat-list-item><mat-slide-toggle [checked]="themeToggle" (change)="toggleTheme($event)">Светлый режим</mat-slide-toggle></a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary" style="z-index: 9999">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <div style="display: flex; justify-content: space-between; width: inherit">
        <span *ngIf="loaded">{{userData?.secondName}} {{userData?.firstName}} {{userData?.patronymic}}</span>
<!--        <span>Панель администратора</span>-->
        <div style="display: flex; gap: 20px">
          <div class="example-button-container" style="display: none">
            <button style="background-color: #374380 !important;" (click)="toggleActive('messages')" mat-mini-fab>
              <mat-icon matBadgeSize="medium" [matBadge]="'0'" matBadgeColor="warn" style="color: white">local_post_office</mat-icon>
            </button>
          </div>
          <div *ngIf="router.url === '/'" class="example-button-container">
            <button (click)="toggleActive('search')" style="background-color: #374380 !important;" mat-mini-fab>
              <mat-icon matBadgeSize="medium" matBadgeColor="warn" style="color: white">search</mat-icon>
            </button>
          </div>
<!--          <div class="example-button-container">-->
<!--            <button style="background-color: #374380 !important;" (click)="toggleActive()" mat-mini-fab>-->
<!--              <mat-icon matBadgeSize="medium" [matBadge]="ticketsLength" matBadgeColor="warn" style="color: white">local_post_office</mat-icon>-->
<!--            </button>-->
<!--          </div>-->
        </div>
      </div>
    </mat-toolbar>
    <div class="wrapp-div">
      <app-active-tickets-map *ngIf="activeTickets"></app-active-tickets-map>
      <app-dash-search *ngIf="searchActive"></app-dash-search>
      <ng-content></ng-content>
    </div>
    <div *ngIf="ticketLoaded" style="right: 0; top: 24px; position: fixed; height: 100%; z-index: 1;
"><app-ticketform (submit)="ticketLoaded = !ticketLoaded"></app-ticketform></div>
  </mat-sidenav-content>
</mat-sidenav-container>
