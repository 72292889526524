import { Component, OnInit, Input } from '@angular/core';
import {History, TicketById} from "../../../shared/interfaces/ticketById";
import {MapItem} from "../../../shared/interfaces/mapMarkers";
import {UsersServices} from "../../../shared/services/users.service";
import * as _ from 'lodash';

@Component({
  selector: 'app-timeline-vertical',
  templateUrl: './timeline-vertical.component.html',
  styleUrls: ['./timeline-vertical.component.css']
})
export class TimelineVerticalComponent implements OnInit {
  @Input() data: History[];
  @Input() agents: MapItem[];
  @Input() original: TicketById;
  sortedData: History[];
  changesData: History[];
  constructor(private user: UsersServices) { }

  compareChanges(object, base) {
      function changes(object, base) {
        return _.transform(object, function(result, value, key) {
          if (!_.isEqual(value, base[key])) {
            result[key] = (value !== null && typeof value === 'object' && base[key] !== null && typeof base[key] === 'object') ? changes(value, base[key]) : value;
          }
        });
      }
      return changes(object, base);
  }

  ngOnInit() {
    this.user.getAllUsers().subscribe((item) => {
      this.sortedData = this.data.map((e) => {
        const search = this.agents.find(agent => agent.id === e.state.agentId);
        const changerId = parseInt(e.description.split(' ').pop())
        const changerUser = item.find(i => i.nummer === changerId)
        return {
          ...e,
          emitter: changerUser === undefined ? '' :
              changerUser.secondName + " " + changerUser.firstName.slice(0, 1) + ". " + changerUser.patronymic.slice(0, 1) + ".",
          state: {
            ...e.state,
            agent: {
              ...e.state.agent,
              user: search.user.secondName + ' ' + search.user.firstName.slice(0, 1) + '. ' + search.user.patronymic.slice(0, 1) + '. '
            }
          }
        }
      })
      let previousValue: History = null;
      this.changesData = this.sortedData.map((i, index) => {
        return this.compareChanges(i, index - 1 < 0 ? this.original : this.sortedData[index - 1])
      })
      console.log(this.changesData)
    })
  }

}
