<mat-card style="margin: 10px" class="shipping-card">
  <mat-tab-group style="z-index: 0">
    <mat-tab label="Морги"><div class="tabl-wrap">
      <ng2-smart-table [source]="ELEMENT_DATA" [settings]="settingsTable"></ng2-smart-table>
    </div></mat-tab>
    <mat-tab label="Метро"><app-metro-directory></app-metro-directory></mat-tab>
    <mat-tab label="Агенты 1C"><app-agent-directory></app-agent-directory></mat-tab>
    <mat-tab label="Агенты GEO"><app-agent-geo-directory></app-agent-geo-directory></mat-tab>
    <mat-tab label="Пользователи"><app-users></app-users></mat-tab>
  </mat-tab-group>
</mat-card>
