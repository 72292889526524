<h2 mat-dialog-title>Выбор агентов</h2>
<mat-dialog-content style="width: 70vw;flex-flow: column;display: flex;flex-wrap: wrap;" class="mat-typography">
    <div>
        <div>
            <mat-form-field class="example-full-width">
                <mat-label>Поиск</mat-label>
                <input matInput (ngModelChange)="searchText = $event" [(ngModel)]="searchText">
            </mat-form-field>
        </div>
        <div class="container-fluid">
            <form [formGroup]="dataForm">
                <div class="row">
                    <div class="col-md-4">
                        <mat-form-field appearance="fill">
                            <mat-label>Дата начала</mat-label>
                            <input formControlName="start" matInput [matDatepicker]="picker1">
                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                            <mat-datepicker #picker1></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field appearance="fill">
                            <mat-label>Дата конца</mat-label>
                            <input formControlName="finish" matInput [matDatepicker]="picker2">
                            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                            <mat-datepicker #picker2></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-radio-group [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedDateFormat" class="d-flex flex-column" aria-label="Выбрать диапазон">
                            <mat-radio-button color="primary" (change)="dropDate()" [value]="7">За 7 дней</mat-radio-button>
                            <mat-radio-button color="primary" (change)="dropDate()" [value]="30">За 30 дней</mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
            </form>
        </div>
        <div>
            <div class="container-fluid p-0">
                <div class="row">
                    <div class="col-md-3" *ngFor="let item of displayFilter()">
                        <mat-checkbox color="primary" [(ngModel)]="item.checked">{{item.user.firstName}}</mat-checkbox>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Назад</button>
    <button mat-raised-button color="primary" [disabled]="selectedDateFormat === null ? !dataForm.valid : false" (click)="submitExport()" cdkFocusInitial>Экспорт</button>
</mat-dialog-actions>
