<div class="container-wrapper">
    <span>Поиск агента</span>
    <mat-spinner *ngIf="allData.length === 0"></mat-spinner>
    <mat-form-field *ngIf="allData.length > 0" class="example-full-width">
        <mat-label>Агент</mat-label>
        <input type="text" matInput [formControl]="myControl" [matAutocomplete]="auto">
        <mat-autocomplete (optionSelected)="onUserSelect($event.option.value)" #auto="matAutocomplete" [displayWith]="displayFn">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                {{option.user.firstName}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</div>
