import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {UsersServices} from "../../../../../shared/services/users.service";
import {UserList} from "../../../../../shared/interfaces/userList";
import {FormBuilder, Validators} from "@angular/forms";
import {catchError} from "rxjs/operators";
import {MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA} from "@angular/material/dialog";
import {NotifierMsgService} from "../../../../../shared/services/notifier-msg.service";
import {MapItem} from "../../../../../shared/interfaces/mapMarkers";
import {forkJoin} from "rxjs";
import {DeviceObject} from "../../../../../shared/interfaces/device";

@Component({
  selector: 'app-user-page',
  templateUrl: './user-page.component.html',
  styleUrls: ['./user-page.component.css']
})
export class UserPageComponent implements OnInit {
  currentUser: UserList = null;
  currentAgent: MapItem = null;
  allDevices: DeviceObject[] = [];
  selectedDevice: string;
  error = false;
  userForm = this.fb.group({
    email: [null, Validators.email],
    firstName: [null, Validators.required],
    secondName: [null, Validators.required],
    patronymic: [null, Validators.required]
  })
  constructor(private route: ActivatedRoute,
              private router: Router,
              private user: UsersServices,
              private fb: FormBuilder,
              private dialog: MatDialog,
              private notify: NotifierMsgService) {
  }
  onSave() {
    const {email, ...data } = this.userForm.value;
    this.user.updateOtherUser({...data, Email: email, nummer: this.currentUser.nummer}).subscribe(e => {
      this.notify.notify('success', 'Данные изменены');
    }, e => {
      this.notify.notify('error', 'Ошибка в изменении данных');
    })
  }
  onSelect(event) {
    console.log(this.currentAgent)
    this.user.setAgentConfig(
        {
          id: this.currentAgent.id,
          uniqueId: event.value,
          userId: this.currentAgent.userId,
          inits: this.currentAgent.inits,
          user1cUniqueId: this.currentAgent.user1cUniqueId,
          isDelete: false,
          status: this.currentAgent.status,
          substatus: this.currentAgent.substatus,
          message: '',
          isDeleted: false
        }).subscribe(e => {
      this.notify.notify('success', 'Привязка изменена!')
    }, (error => this.notify.notify('error', 'Ошибка изменения!')))
  }
  onDelete() {
    this.user.getAgentsAPI().subscribe((e) => {
      let dialogConf = new MatDialogConfig();
      dialogConf.disableClose = true;
      dialogConf.data = e.filter(item => item.userId === this.currentUser.id)
      const dialogRef = this.dialog.open(DialogDelete, dialogConf);
      dialogRef.afterClosed().subscribe(ref => {
        if(ref === true) {
          const tempData = e.filter(item => item.userId === this.currentUser.id);
          if(tempData.length) {
            this.user.deleteUser(this.currentUser.nummer, tempData[0].id).subscribe((e) => {
              this.notify.notify('success', 'Агент и пользователь удалены!');
              this.router.navigate(['/admin'])
            })
          } else {
            this.user.deleteOnlyUser(this.currentUser.nummer).subscribe((e) => {
              this.notify.notify('success', 'Пользователь удален!')
              this.router.navigate(['/admin'])
            })
          }
        }
      })
    });
  }
  passDialogRef() {
    let dialogConf = new MatDialogConfig();
    dialogConf.disableClose = true;
    const dialogRef = this.dialog.open(DialogPassword, dialogConf);

    dialogRef.afterClosed().subscribe(ref => {
      if(ref !== false) {
        this.user.updateOtherUser({
          ...this.userForm.value,
          email: this.currentUser.email,
          nummer: this.currentUser.nummer,
          passwordHash: ref
        }).subscribe(e => {
          this.notify.notify('success', 'Пароль изменен!')
        }, (e) => {
          this.notify.notify('error', 'Произошла ошибка в изменении пароля!')
        })
      }
    })
  }
  ngOnInit(): void {
    this.route.params.subscribe((e) => {
      forkJoin([
          this.user.getUserById(e['id']),
          this.user.getAgentsAPI(),
          this.user.getDevicesAll()
      ]).subscribe((e) => {
        let tempData = e[1].filter(item => item.userId === e[0].id)
        if(tempData.length) {
          this.currentAgent = tempData[0]
          this.selectedDevice = tempData[0].uniqueId
        }
        this.allDevices = e[2].map((data) => {
          return {
            ...data,
            category: e[1].some(tempData => tempData.uniqueId === data.uniqueId) || data.lastUpdate === null
          }
        });
        this.currentUser = e[0];
        this.userForm.setValue({
          email: e[0].email,
          firstName: e[0].firstName,
          secondName: e[0].secondName,
          patronymic: e[0].patronymic
        })
      })
    }, e => {
      this.error = true;
    })

  }
}
@Component({
  selector: 'dialog-content-example-dialog',
  templateUrl: 'dialog-password.html',
})
export class DialogPassword {

}

@Component({
  selector: 'dialog-content-example-dialog',
  templateUrl: 'dialog-delete.html',
})
export class DialogDelete {
  constructor(public dialogRef: MatDialogRef<UserPageComponent>,
              @Inject(MAT_DIALOG_DATA) public data: MapItem[]) {
  }


}
