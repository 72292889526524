<mat-card style="margin: 10px">
    <mat-card-header>
        <mat-card-title>Просмотр последних диалогов</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <button (click)="onCancel()" class="my-3" mat-raised-button color="primary"><mat-icon>keyboard_arrow_left</mat-icon>Назад</button>
        <form [formGroup]="dateGroup">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-6 px-0 pr-2">
                        <mat-form-field appearance="fill">
                            <mat-label>Начальная дата</mat-label>
                            <input formControlName="start" matInput [matDatepicker]="pickerStart">
                            <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
                            <mat-datepicker #pickerStart></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6 px-0 pl-2">
                        <mat-form-field appearance="fill">
                            <mat-label>Конечная дата</mat-label>
                            <input formControlName="finish" matInput [matDatepicker]="pickerFinish">
                            <mat-datepicker-toggle matSuffix [for]="pickerFinish"></mat-datepicker-toggle>
                            <mat-datepicker #pickerFinish></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </form>
        <p *ngIf="callData !== undefined && !callData.length">Ничего не найдено!</p>
        <mat-accordion>
            <mat-expansion-panel *ngFor="let i of data | paginate: { itemsPerPage: 10, currentPage: page }">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{i.number.replace('sip:', '')}}
                    </mat-panel-title>
                    <mat-panel-description>
<!--                        <mat-icon>date_range</mat-icon>-->
                        <span>{{i.data.length}} обращений</span>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <mat-accordion>
                    <mat-expansion-panel *ngFor="let subcall of i.data">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{subcall.from_number.replace('sip:', '')}}
                            </mat-panel-title>
                            <mat-panel-description>
                                <!--                        <mat-icon>date_range</mat-icon>-->
                                <span>{{subcall.dateAdd | date: 'dd.MM.yyyy hh:mm:ss'}}</span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <div class="audio-card p-3 mb-3">
                            <audio controls *ngIf="subcall.file !== null" [src]="subcall.file.fullUrl"></audio>
                            <mat-icon *ngIf="subcall.file === null">report_problem</mat-icon>
                            <span *ngIf="subcall.file === null">Аудиофайл не записан</span>
                        </div>
                        <mat-card>
                            <mat-card-content>
                                <div class="my-2" *ngFor="let dialog of subcall.dialog" [style]="dialog.type === 'operator' ? 'align-items: start' : 'align-items: flex-end'" style="display: flex;flex-direction: column;">
                                    <div class="d-flex justify-content-center align-items-center">
                                        <mat-icon class="icon-profile" *ngIf="dialog.type === 'operator'">support_agent</mat-icon>
                                        <span class="text-dialog">{{dialog.text}}</span>
                                        <mat-icon class="icon-profile" *ngIf="dialog.type === 'client'">call</mat-icon>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </mat-expansion-panel>
                </mat-accordion>
<!--                <mat-card>-->
<!--                    <mat-card-content>-->
<!--                        <div class="my-2" *ngFor="let dialog of i.dialog" [style]="dialog.type === 'operator' ? 'align-items: start' : 'align-items: flex-end'" style="display: flex;flex-direction: column;">-->
<!--                            <div class="d-flex justify-content-center align-items-center">-->
<!--                                <mat-icon class="icon-profile" *ngIf="dialog.type === 'operator'">support_agent</mat-icon>-->
<!--                                <span class="text-dialog">{{dialog.text}}</span>-->
<!--                                <mat-icon class="icon-profile" *ngIf="dialog.type === 'client'">call</mat-icon>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </mat-card-content>-->
<!--                </mat-card>-->
            </mat-expansion-panel>
            <pagination-controls *ngIf="callData !== null && callData !== undefined" previousLabel="Назад" nextLabel="Дальше" (pageChange)="page = $event"></pagination-controls>
        </mat-accordion>
    </mat-card-content>
</mat-card>
