import {Component, OnInit, ViewChild} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import {UsersServices} from '../../../shared/services/users.service';
import {NotifierMsgService} from '../../../shared/services/notifier-msg.service';
import {UserList} from "../../../shared/interfaces/userList";

@Component({
  selector: 'app-user-add',
  templateUrl: './user-add.component.html',
  styleUrls: ['./user-add.component.css']
})
export class UserAddComponent implements OnInit {
  userForm = this.fb.group({
    email: [null, Validators.required],
    fio: [null, Validators.required],
    roleId: [null, Validators.required],
    dateBirt: null,
    sex: [true, Validators.required],
    passwordHash: [null, Validators.required]
  });
  @ViewChild('pForm') pForm;

  hasUnitNumber = false;

  states = this.user.currentRoles;
  hide = true;
  userData: UserList[] = [];
  loaded = false;
  constructor(private fb: FormBuilder, private user: UsersServices, private notifier: NotifierMsgService) {}

  ngOnInit() {
    this.user.getAllUsers().subscribe((e) => {
      this.userData = e;
      this.loaded = true;
      console.log('zdao')
    });
  }

  onSubmit() {
    const {fio, ...req} = this.userForm.value
    let data = this.userForm.controls['fio'].value;
    let secondName = data.split(" ").length >= 1 ? data.split(" ")[0] : null
    let firstName = data.split(" ").length >= 2 ? data.split(" ")[1] : null
    let patronymic = data.split(" ").length >= 3 ? data.split(" ")[2] : null
    this.user.createUser({...req, isDeleted: false, secondName, firstName, patronymic}).subscribe(data => {
      this.notifier.notify('success', 'Аккаунт создан!');
      this.pForm.resetForm();
    }, err => {
      this.notifier.notify('error', err.title || 'Ошибка создания!');
      this.pForm.resetForm();
    });
  }
}
