import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LoginComponent} from './user/login/login.component';
import {IndexComponent} from './user/index/index.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { HeaderComponent } from './header/header.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {MailingPageComponent} from './mailing-page/mailing-page.component';
import {PreviewListComponent} from './mailing-page/preview-list/preview-list.component';
import {Ng2SmartTableModule} from 'ng2-smart-table';
import {NotifierModule} from 'angular-notifier';
import {TokenInterceptor} from './shared/interceptors/token';
import { ErrorPageComponent } from './error-page/error-page.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import {MatCardModule} from "@angular/material/card";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatButtonModule} from "@angular/material/button";
import { MainDashComponent } from './main-dash/main-dash.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MapComponent } from './map/map.component';
import { LogpageComponent } from './logpage/logpage.component';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import {MatBadgeModule} from '@angular/material/badge';
import { TicketformComponent } from './ticketform/ticketform.component';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import { AdminFormComponent } from './admin/admin-layout/admin-form/admin-form.component';
import { AdminLayoutComponent } from './admin/admin-layout/admin-layout.component';
import {MatTabsModule} from "@angular/material/tabs";
import {MatTableModule} from "@angular/material/table";
import {AdmintrackersComponent, PickDateModal} from './admin/admin-layout/admintrackers/admintrackers.component';
import {MatTooltipModule} from "@angular/material/tooltip";
import {ChartistModule} from "ng-chartist";
import { AdminDeviceComponent } from './admin/admin-layout/admin-device/admin-device.component';
import {MatCheckboxModule} from "@angular/material/checkbox";
import { UserAddComponent } from './admin/admin-layout/user-add/user-add.component';
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MAT_DATE_LOCALE, MatNativeDateModule} from "@angular/material/core";
import { AdminDirectoryComponent } from './admin/admin-layout/admin-directory/admin-directory.component';
import { AgentAddComponent } from './admin/admin-layout/agent-add/agent-add.component';
import {
    AllticketsComponent,
    CustomRendererComponent,
    StatusHandler
} from './alltickets/alltickets.component';
import { MetroDirectoryComponent } from './admin/admin-layout/admin-directory/metro-directory/metro-directory.component';
import { ActiveTicketsMapComponent } from './active-tickets-map/active-tickets-map.component';
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import { AgentDirectoryComponent } from './admin/admin-layout/admin-directory/agent-directory/agent-directory.component';
import { AgentGeoDirectoryComponent } from './admin/admin-layout/admin-directory/agent-geo-directory/agent-geo-directory.component';
import {AgentEditComponent, ButtonViewComponent} from './admin/admin-layout/agent-edit/agent-edit.component';
import { AgentPageComponent } from './admin/admin-layout/agent-edit/agent-page/agent-page.component';
import { TicketEditComponent } from './alltickets/ticket-edit/ticket-edit.component';
import { DatePipe } from '@angular/common';
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import { TimelineVerticalComponent } from './alltickets/ticket-edit/timeline-vertical/timeline-vertical.component';
import { UsersComponent } from './admin/admin-layout/admin-directory/users/users.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import {AdminLogsAlert, AdminLogsComponent, AdminLogsIpCell} from './admin/admin-layout/admin-logs/admin-logs.component'
import {MatDialogModule} from "@angular/material/dialog";
import {MatChipsModule} from "@angular/material/chips";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {MatMenuModule} from "@angular/material/menu";
import { UserButtonTableComponent } from './admin/admin-layout/admin-directory/users/user-button/user-button-table/user-button-table.component';
import {
    DialogDelete,
    DialogPassword,
    UserPageComponent
} from "./admin/admin-layout/admin-directory/users/user-page/user-page.component";
import { UserStatusComponent } from './admin/admin-layout/admin-directory/users/user-page/user-status/user-status.component';
import { DashSearchComponent } from './main-dash/dash-search/dash-search.component';
import { StatistisModuleComponent } from './admin/admin-layout/admintrackers/statistis-module/statistis-module.component';
import { EditDeviceComponent } from './admin/admin-layout/admin-device/edit-device/edit-device.component';
import { MangoComponent } from './admin/admin-layout/mango/mango.component';
import {MatExpansionModule} from "@angular/material/expansion";
import {NgxPaginationModule} from "ngx-pagination";
import {
    GroupSelectionDialog,
    StatusHistoryComponent
} from './admin/admin-layout/admintrackers/status-history/status-history.component';
import { LogsAllComponent } from './admin/admin-layout/logs-all/logs-all.component';
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {VimeModule} from "@vime/angular";
import {SupportComponent, SupportDialog, SupportDialogFinish} from './support/support.component';

registerLocaleData(localeRu, 'ru');
export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    IndexComponent,
    HeaderComponent,
    MailingPageComponent,
    PreviewListComponent,
    ErrorPageComponent,
    MainDashComponent,
    MapComponent,
    LogpageComponent,
    TicketformComponent,
    AdminFormComponent,
    AdminLayoutComponent,
    AdmintrackersComponent,
    AdminDeviceComponent,
    UserAddComponent,
    AdminDirectoryComponent,
    AgentAddComponent,
    AllticketsComponent,
    MetroDirectoryComponent,
    ActiveTicketsMapComponent,
    AgentDirectoryComponent,
    AgentGeoDirectoryComponent,
    AgentEditComponent,
      ButtonViewComponent,
      AgentPageComponent,
      TicketEditComponent,
      CustomRendererComponent,
      StatusHandler,
      TimelineVerticalComponent,
      UsersComponent,
      AdminLogsComponent,
      AdminLogsAlert,
      AdminLogsIpCell,
      UserButtonTableComponent,
      UserPageComponent,
      DialogPassword,
      DialogDelete,
      UserStatusComponent,
      DashSearchComponent,
      StatistisModuleComponent,
      EditDeviceComponent,
      MangoComponent,
      StatusHistoryComponent,
      LogsAllComponent,
      SupportComponent,
      SupportDialog,
      SupportDialogFinish,
      PickDateModal,
      GroupSelectionDialog
  ],
    imports: [
        NgxMaskModule.forRoot(),
        NgxMapboxGLModule.withConfig({
            accessToken: 'pk.eyJ1IjoibWFmYWhlcyIsImEiOiJjazV6cW5xdDUwMDRrM21ueHF2Z3EzY3VyIn0.RRuRqnVCy3VWno0v3Xk__w',
            geocoderAccessToken: 'pk.eyJ1IjoibWFmYWhlcyIsImEiOiJjazV6cW5xdDUwMDRrM21ueHF2Z3EzY3VyIn0.RRuRqnVCy3VWno0v3Xk__w'
        }),
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        HttpClientModule,
        Ng2SmartTableModule,
        NotifierModule,
        MatCardModule,
        MatSidenavModule,
        MatButtonModule,
        LayoutModule,
        MatToolbarModule,
        MatIconModule,
        MatListModule,
        MatSelectModule,
        MatRadioModule,
        MatBadgeModule,
        MatProgressSpinnerModule,
        MatTabsModule,
        MatTableModule,
        MatTooltipModule,
        ChartistModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatAutocompleteModule,
        MatSlideToggleModule,
        MatDialogModule,
        MatChipsModule,
        DragDropModule,
        MatMenuModule,
        MatExpansionModule,
        NgxPaginationModule,
        MatProgressBarModule,
        VimeModule],
  providers: [
      DatePipe,
      { provide: LOCALE_ID, useValue: 'ru' },
    MatDatepickerModule,
    {provide: MAT_DATE_LOCALE, useValue: 'ru-RU'},
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: TokenInterceptor
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
