import { Component, OnInit } from '@angular/core';
import {LogsService} from "../../../shared/services/logs.service";

@Component({
  selector: 'app-logs-all',
  templateUrl: './logs-all.component.html',
  styleUrls: ['./logs-all.component.css']
})
export class LogsAllComponent implements OnInit {
  logsData: any = null;
  constructor(private logs: LogsService) { }
  settingsTable = {
    actions: false,
    noDataMessage: 'Список пуст',
    columns: {
      id: {
        title: 'ID',
        filter: false
      },
      name: {
        title: 'Станция',
      },
      nameLine: {
        title: 'Линия',
      }
    }
  };
  ngOnInit(): void {
    this.logs.getAllLogs().subscribe(e => {
      this.logsData = e;
    })
  }

}
