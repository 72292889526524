<h2 mat-dialog-title>Сменить пароль</h2>
<mat-dialog-content class="mat-typography">
    <mat-form-field class="example-full-width">
        <mat-label>Новый пароль</mat-label>
        <input #input matInput>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="false">Отмена</button>
    <button mat-button [mat-dialog-close]="input.value" color="primary">Изменить</button>
</mat-dialog-actions>
