import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Conf} from "../conf";
import {Observable} from "rxjs";
import {LogObject, StatsObject} from "../interfaces/logs";

@Injectable({
  providedIn: 'root'
})
export class LogsService {

  constructor(private http: HttpClient) { }

  // Вернуть все логи
  getLogs(): Observable<LogObject[]>{
    return this.http.get<LogObject[]>(`${Conf.APIBASEURL}api/userLogs`);
  }
  getAllLogs() {
    return this.http.get(`${Conf.APIBASEURL}api/logs`);
  }
  getStats(dates): Observable<StatsObject[]> {
    return this.http.post<StatsObject[]>(`${Conf.APIBASEURL}api/stats`, dates);
  }
  getStatsCanceled(dates): Observable<StatsObject[]> {
    return this.http.post<StatsObject[]>(`${Conf.APIBASEURL}api/stats/canceled`, dates);
  }
  getStatsNotCompleted(dates): Observable<StatsObject[]> {
    return this.http.post<StatsObject[]>(`${Conf.APIBASEURL}api/stats/not-completed`, dates);
  }
}
