import { Component, OnInit } from '@angular/core';
import {UsersServices} from "../../../../shared/services/users.service";

@Component({
  selector: 'app-agent-directory',
  templateUrl: './agent-directory.component.html',
  styleUrls: ['./agent-directory.component.css']
})
export class AgentDirectoryComponent implements OnInit {
  agents: any[];

  constructor(private agent: UsersServices) { }
  settingsTable = {
    actions: false,
    noDataMessage: 'Список пуст',
    columns: {
      fio: {
        title: 'Ф.И.О. Агента',
      },
      agentID: {
        filter: false,
        title: 'ID Агента',
      }
    }
  };
  ngOnInit(): void {
    this.agent.getAgents().subscribe(data => {
      this.agents = data as any[];
    })
  }

}
