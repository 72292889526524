import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Conf} from '../conf';
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) { }
  sendRecover(data) {
    return this.http.post(`${Conf.APIBASEURL}auth/v2/recover`, data);
  }
  sendLogin(user): Observable<any> {
    return this.http.post<any>(`${Conf.APIBASEURL}auth/login`, user);
  }
  isAuth(){
    if (localStorage.id_token){
      return true;
    } else {
      return false;
    }
  }
}
