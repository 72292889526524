
<form [formGroup]="addressForm" novalidate style="height: 100%;" (ngSubmit)="onSubmit()">
  <div class="spinner-wrap" style="z-index: 9999" *ngIf="loader">
    <mat-spinner></mat-spinner>
  </div>
  <div class="spinner-wrap" style="z-index: 9999" *ngIf="success">
    <mat-icon style="width: 90px; font-size: 90px; height: 90px; color: #4da851" aria-hidden="false" aria-label="Example home icon">how_to_reg</mat-icon>
    <span class="mat-display-1">Заявка отправлена!</span>
  </div>
  <mat-card style="height: 100%" class="shipping-card">
    <mat-card-header>
      <mat-card-title>Консультация Специализированного отделения агентского обслуживания</mat-card-title>
    </mat-card-header>
    <mat-card-content style="z-index: 0; max-height: 84vh;">
      <div style="overflow-x: hidden; max-height: inherit">
        <div class="row">
          <div class="col">
            <mat-form-field>
              <mat-label>Тип запроса</mat-label>
              <mat-select disabled formControlName="requestType">
                <mat-option [value]="1">Консультация Специализированного отделения агентского обслуживания</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-12">
            <mat-form-field class="full-width">
              <input matInput placeholder="ФИО Заявителя" formControlName="applicantName">
              <mat-error *ngIf="addressForm.controls['applicantName'].hasError('required')">
                Ф.И.О. <strong>обязательно</strong>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field appearance="fill" class="example-full-width">
              <mat-label>МОРГ</mat-label>
              <input type="text" matInput [formControl]="morgControl" [matAutocomplete]="morgs">
              <mat-autocomplete (optionSelected)="morgSelected($event)" #morgs="matAutocomplete" [displayWith]="morgDisplayWith">
                <mat-option *ngFor="let option of morgField" [value]="option">
                  {{option.name}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </div>
<!--        <div class="row">-->
<!--          <div class="col">-->
<!--            <mat-form-field appearance="fill">-->
<!--              <mat-label>МОРГ</mat-label>-->
<!--              <mat-select formControlName="morgue">-->
<!--                <mat-option *ngFor="let cat of morgues" [value]="cat">{{cat.name}}</mat-option>-->
<!--              </mat-select>-->
<!--            </mat-form-field>-->
<!--          </div>-->
<!--        </div>-->
        <div class="row">
          <div class="col">
            <mat-form-field class="full-width">
              <input matInput placeholder="ФИО Умершего" formControlName="departedName">
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field class="full-width">
              <input matInput [(ngModel)]="okrugFormatter" [ngModelOptions]="{standalone: true}" disabled placeholder="Округ">
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field class="example-full-width">
              <input type="text"
                     placeholder="Адрес"
                     aria-label="Number"
                     matInput
                     formControlName="addressField"
                     [matAutocomplete]="auto">
              <mat-autocomplete [displayWith]="addressDisplayWith" autoActiveFirstOption #auto="matAutocomplete">
                <mat-option *ngFor="let address of addressesData" [value]="address">
                  {{address.place_name}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </div>
        <div class="row mb-2" *ngIf="selectedAgent !== null && !loadingYandex">
          <div class="col">
            <div class="d-flex flex-row align-items-center">
              <mat-icon style="color: green">done</mat-icon>
              <p class="m-0" style="color: green">
                {{selectedAgent?.elements[0]?.agent?.user?.secondName}}.
                {{selectedAgent?.elements[0]?.agent?.user?.firstName.slice(0, 1)}}.
                {{selectedAgent?.elements[0]?.agent?.user?.patronymic.slice(0, 1)}}.
                 время прибытия
                {{selectedAgent.elements[0].duration.value * 1000 | date: 'mm мин. ss сек.'}}</p>
            </div>
          </div>
        </div>
        <div class="row mb-2" *ngIf="loadingYandex">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <div class="row mb-2" *ngIf="agentNotFound">
          <div class="col">
            <div class="d-flex flex-row align-items-center">
              <mat-icon style="color: red">close</mat-icon>
              <p class="m-0" style="color: red">Агент не найден!</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field class="full-width">
              <input matInput prefix="+7" [showMaskTyped]="true" mask="(000) 000-0000" placeholder="Номер телефона" formControlName="phone">
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field class="full-width">
              <input matInput prefix="+7" [showMaskTyped]="true" mask="(000) 000-0000" placeholder="Дополнительный номер телефона" formControlName="additional_phone">
            </mat-form-field>
          </div>
        </div>
        <ng-container *ngIf="agent.currentUser[0].role.name === 'admin'">
            <div class="row">
              <div class="col">
                <span style="font-size: 20px">Данные о диспетчере</span>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <mat-form-field class="full-width">
                  <input matInput placeholder="Имя" formControlName="disp_firstName">
                  <mat-error *ngIf="addressForm.controls['disp_firstName'].hasError('required')">
                    Имя <strong>обязательно</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field class="full-width">
                  <input matInput placeholder="Фамилия" formControlName="disp_lastName">
                  <mat-error *ngIf="addressForm.controls['disp_lastName'].hasError('required')">
                    Фамилия <strong>обязательна</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field class="full-width">
                  <input matInput placeholder="Отчество" formControlName="disp_patronymic">
                  <mat-error *ngIf="addressForm.controls['disp_patronymic'].hasError('required')">
                    Отчество <strong>обязательно</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
        </ng-container>
        <div class="row">
          <div class="col">
            <mat-form-field class="example-full-width">
              <mat-label>Категория запроса</mat-label>
              <input type="text" matInput [formControl]="categoryControl" [matAutocomplete]="quest">
              <mat-autocomplete (optionSelected)="categorySelected($event)" #quest="matAutocomplete" [displayWith]="categoryDisplayWith">
                <mat-option *ngFor="let option of categoryField" [value]="option">
                  {{option.name}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field class="full-width">
              <mat-label>Тип обращения</mat-label>
              <mat-select formControlName="questionType">
                <mat-option value="110">110</mat-option>
                <mat-option value="610">610</mat-option>
                <mat-option value="110+610">110+610</mat-option>
                <mat-option value="112">112</mat-option>
                <mat-option value="112+610">112+610</mat-option>
              </mat-select>
              <mat-error *ngIf="addressForm.controls['questionType'].hasError('required')">
                Тип <strong>обязателен</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field class="full-width">
              <mat-label>Место оказания услуги</mat-label>
              <mat-select formControlName="placeOfService">
                <mat-option value="Центр ритуального обслуживания">Центр ритуального обслуживания</mat-option>
                <mat-option value="МОРГ">МОРГ</mat-option>
                <mat-option value="Выезд">Выезд</mat-option>
              </mat-select>
              <mat-error *ngIf="addressForm.controls['placeOfService'].hasError('required')">
                Место оказание услуги <strong>обязательно</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field class="full-width">
              <mat-label>Место смерти</mat-label>
              <mat-select formControlName="placeOfDeath">
                <mat-option value="Дом">Дом</mat-option>
                <mat-option value="Больница">Больница</mat-option>
              </mat-select>
              <mat-error *ngIf="addressForm.controls['questionType'].hasError('required')">
                Место смерти <strong>обязательно</strong>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field class="full-width">
              <input matInput placeholder="Описание" formControlName="comment">
              <mat-error *ngIf="addressForm.controls['comment'].hasError('required')">
                Текст <strong>обязателен</strong>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <button mat-raised-button [disabled]="!addressForm.valid || this.addressForm.controls['addressField']?.value?.constructor.name !== 'Object'" color="primary" type="submit">Отправить</button>
      </div>
    </mat-card-content>
  </mat-card>
</form>
