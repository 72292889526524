import {Component, Input, OnInit} from '@angular/core';
import {TicketSender} from "../shared/services/tickets.service";
import {TicketObject} from "../shared/interfaces/ticket";
import {Router} from "@angular/router";

@Component({
  selector: 'app-active-tickets-map',
  templateUrl: './active-tickets-map.component.html',
  styleUrls: ['./active-tickets-map.component.css']
})
export class ActiveTicketsMapComponent implements OnInit {
  @Input() role;
  loading = true;
  constructor(private ticket: TicketSender, private router: Router) { }
  ticketsData: TicketObject[];
  editTicket(id: number) {
    this.router.navigate(['/ticket/' + id])
  }
  ngOnInit() {
    this.ticket.getAllTickets().subscribe(data => {
      const intercept = data as TicketObject[];
      this.ticketsData = intercept.filter(e => e.status !== 'finished_ok' && e.status !== 'canceled_ok');
      this.loading = false;
    })
  }

}
