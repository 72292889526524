import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {TicketSender} from "../shared/services/tickets.service";
import {AgentService} from "../shared/services/agent.service";
import {UsersServices} from "../shared/services/users.service";
import {MapService} from "../shared/services/map.service";
import {forkJoin, Observable, Subject} from "rxjs";
import {debounceTime, distinctUntilChanged, map, startWith} from "rxjs/operators";
import {AddressMapbox, DadataObject, Feature, Suggestion} from "../shared/interfaces/addressMap";
import {getDistanceFromLatLonInKm} from "../shared/distanceMatrix";
import {MapItem} from "../shared/interfaces/mapMarkers";
import {NotifierMsgService} from "../shared/services/notifier-msg.service";
import {QuestionCategory} from "../shared/interfaces/questionCategory";
import {StateTicket} from "../shared/interfaces/StateTicket";
import {Morgue} from "../shared/interfaces/morgue";
import {Metro} from "../shared/interfaces/metro";
import * as _ from 'lodash';
import {RowYad} from "../shared/interfaces/yandexGeo";


@Component({
  selector: 'app-ticketform',
  templateUrl: './ticketform.component.html',
  styleUrls: ['./ticketform.component.css']
})
export class TicketformComponent implements OnInit {

  @Output()
  submit: EventEmitter<boolean> = new EventEmitter<boolean>();

  addressForm = this.fb.group({
    phone: '',
    additional_phone: '',
    requestType: [1, Validators.required],
    questCategory: null,
    questionType: [null, Validators.required],
    placeOfService: [null, Validators.required],
    placeOfDeath: [null, Validators.required],
    departedName: [null, Validators.required],
    applicantName: [null, Validators.required], // name
    comment: '', // info_textarea
    stateId: null,
    agentId: null,
    disp_firstName: '',
    disp_lastName: '',
    disp_patronymic: '',
    addressField: [null, Validators.required], // addressField
    serviceTime: null,
    morgue: null

  });
  loader = true;
  success = false;
  loadingYandex = false;
  errorSearch = false;

  currentAgents: any[];
  selectedAgent: RowYad = null;
  agentNotFound = false;

  addressesData?: Feature[];
  address: any;


  debouncer = new Subject<string>();
  hasUnitNumber = false;

  questionCategory: QuestionCategory[];

  stateTickets: StateTicket[];

  morgues: Morgue[];

  categoryControl = new FormControl();
  categoryField: QuestionCategory[];

  morgControl = new FormControl();
  morgField: Morgue[] = [];

  okrugFormatter: string = null;

  constructor(private fb: FormBuilder,
              private ticketApi: TicketSender,
              public agent: UsersServices,
              private map: MapService,
              private notifier: NotifierMsgService) {
    this.debouncer.pipe(
        debounceTime(1000),
        distinctUntilChanged())
        .subscribe(response => {
          if(typeof response === "string") {
            this.map.getAddress(response).subscribe((data) => {
              const resp = data as DadataObject;
              console.log(resp)
              const response = resp.suggestions
                  .filter(e => (e.data['geo_lat'] !== null && e.data['geo_lat'] !== '') && (e.data['geo_lon'] !== null && e.data['geo_lon'] !== ''))
                  .map((e) => {
                return {
                  center: [parseFloat(e.data['geo_lon']), parseFloat(e.data['geo_lat'])],
                  place_name: e.value,
                  area: e.data['city_area']
                }
              })
              // const response = data as AddressMapbox;
              this.addressesData = response as unknown as Feature[]
            })
          }
        })
  }

  categoryDisplayWith(data) {
    if(data === null) {
      return null
    }
    return data.name;
  }
  morgDisplayWith(data) {
    if(data === null) {
      return null
    }
    return data.name;
  }
  categorySelected(data) {
    this.addressForm.controls['questCategory'].setValue(data.option.value.id)
  }
  morgSelected(data) {
    this.addressForm.controls['morgue'].setValue(data.option.value)
  }



  ngOnInit() {
    this.addressForm.controls['morgue'].valueChanges.subscribe(e => {
      console.log(e)
    })
    this.categoryControl.valueChanges.subscribe(e => {
      if(typeof e !== "object") {
        this.categoryField = _.filter(this.questionCategory, (obj: QuestionCategory) => {
          return obj.name.toLowerCase().indexOf(e.toLowerCase()) !== -1
        })
      }
    })
    this.morgControl.valueChanges.subscribe(e => {
      if(typeof e !== "object") {
        this.morgField = _.filter(this.morgues, (obj: Morgue) => {
          return obj.name.toLowerCase().indexOf(e.toLowerCase()) !== -1
        })
      }
    })

    forkJoin([
      this.ticketApi.getQuestionCategory(),
      this.ticketApi.getStateTickets(),
      this.ticketApi.getMorgues(),
      this.agent.getAgentsAPI()
    ]).subscribe((result) => {
      this.questionCategory = result[0];
      this.categoryField = result[0];
      this.stateTickets = result[1];
      this.morgues = result[2];
      this.morgField = result[2];
      this.currentAgents = result[3] as [];
      this.loader = false
    })
    if(this.agent.currentUser[0].role.name === 'dispatcher') {
      this.addressForm.controls['disp_firstName'].setValue(this.agent.currentUser[0].firstName)
      this.addressForm.controls['disp_lastName'].setValue(this.agent.currentUser[0].secondName)
      this.addressForm.controls['disp_patronymic'].setValue(this.agent.currentUser[0].patronymic)
    }
    this.onValueChanges()
  }
  addressDisplayWith(e) {
    return e === null ? '' : e.place_name
  }

  onValueChanges(): void {
    this.addressForm.controls['addressField'].valueChanges.subscribe(data=>{
      if(typeof data !== 'string') {
        console.log(this.addressesData);
        this.searchAgent();
      }
      const val = {addressField: data}
      this.debouncer.next(val.addressField)
    })
  }
  searchAgent() {
    if(typeof this.addressForm.controls['addressField'].value !== 'object' || this.addressForm.controls['addressField'].value == null) {
      this.errorSearch = true;
    } else {
      var data = this.addressForm.controls['addressField'].value;
      this.loadingYandex = true;
      this.ticketApi.searchAgent({latitude: data.center[1], longitude: data.center[0]}).subscribe((e) => {
        this.loadingYandex = false;
        if(e.state) {
          var sortData: RowYad[] = e.rows.sort(function(a, b) {
            return a.elements[0].duration.value - b.elements[0].duration.value;
          });
          this.selectedAgent = sortData[0];
          console.log(this.addressesData);
          // @ts-ignore
          this.map.mapTriggerSearch.next({
            // @ts-ignore
            position: {
              latitude: this.addressesData[0].center[1],
              longitude: this.addressesData[0].center[0]
            },
          })
        } else {
          this.agentNotFound = true;
        }
      })
      this.errorSearch = false;
    }
  }
  onSubmit() {
      this.loader = true;
      const { ticket_name, ticket_text, applicantName, additional_phone, addressField, morgue,  ...ticketInfo } = this.addressForm.value
      const nameChunk = applicantName.split(" ");
      const {center} = this.addressesData[0];
      this.ticketApi.sendTicket({...ticketInfo,
        geo_lon: center[0],
        geo_lat: center[1],
            distance: this.selectedAgent === null ? 0 : this.selectedAgent.elements[0].distance.value,
            duration: this.selectedAgent === null ? 0 : this.selectedAgent.elements[0].duration.value,
        applicantName,
            applicant_address: addressField.place_name,
            isDeleted: false,
            additionalPhone: additional_phone,
            agentId: this.selectedAgent === null ? null : this.selectedAgent.elements[0].agent.id,
            firstName: nameChunk[1] || "null",
            lastName: nameChunk[0] || "null",
            patronymic: nameChunk[2] || "null",
            status: 'ticket',
            status1c: 'Передан агенту',
            morgueId: morgue === null ? undefined : morgue.id,
            substate: 'none',
            serviceTime: new Date()
          }
      ).subscribe(e => {
        this.notifier.notify('success', `Заявка отправлена!`)
        this.loader = false;
        this.success = true
      })
      setTimeout(() => {
        this.submit.emit(true);
      }, 1500)
  }
}
