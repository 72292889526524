import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {TicketSender} from "../shared/services/tickets.service";
import {TicketObject} from "../shared/interfaces/ticket";
import {DatePipe, formatDate} from "@angular/common";
import {LocalDataSource, ViewCell} from "ng2-smart-table";
import {ActivatedRoute, Router} from "@angular/router";
import {TicketPagination} from "../shared/interfaces/TicketPagination";
import {TicketP, TicketPaginatedV2} from "../shared/interfaces/ticketPaginated";

@Component({
  template: `
    <div class="justify-content-center d-flex flex-row" style="gap: 5px">
      <mat-icon *ngIf="rowData.inits === null" style="color: red">error_outline</mat-icon>
      <div *ngIf="rowData.inits !== null" class="w-100 example-button-container wrapper-status" [style]="colorHandler()">
        <span>{{textHandler()}}</span>
      </div>
    </div>
  `,
  styleUrls: ['./alltickets.component.scss']
})
export class StatusHandler implements ViewCell, OnInit {
  @Input() value: any;    // This hold the cell value
  @Input() rowData: TicketP;  // This holds the entire row object
  constructor(private router: Router) {}
  ngOnInit() {
  }

  colorHandler() {
    return 'background-color: ' + {finished_ok: '#808080', canceled_ok: '#ff5757', arrived: '#b5c946', ticket: '#aeaeae', submitted: '#b5c946'}[this.rowData.status]
  }
  textHandler() {
    return this.rowData.calcStatus;
  }

}


@Component({
  template: `
    <div class="example-button-container">
      <button (click)="onSelect()" mat-mini-fab color="primary" aria-label="Example icon button with a home icon">
        <mat-icon>edit</mat-icon>
      </button>
    </div>
  `,
})
export class CustomRendererComponent implements ViewCell {
  @Input() value: any;    // This hold the cell value
  @Input() rowData: any;  // This holds the entire row object
  constructor(private router: Router) {}


  onSelect() {
    this.router.navigate(['/ticket/' + this.rowData.id])
  }
}


@Component({
  selector: 'app-alltickets',
  templateUrl: './alltickets.component.html',
  styleUrls: ['./alltickets.component.scss']
})
export class AllticketsComponent implements OnInit {
  filterState: string = '';
  source: LocalDataSource = new LocalDataSource();
  ticketsData: TicketPaginatedV2;
  currentPage = 0;
  itemsPerPage = 50;
  search = '';
  desc = null;
  settingsTable = {
    pager: {
      display: false,
      // perPage: 100000
    },
    actions: false,
    noDataMessage: 'Список пуст',
    columns: {
      state: {
        title: 'Статус',
        sort: false,
        filterFunction(cell?: any, search?: string): boolean {
          return true;
        },
        filter: {
          type: 'list',
          config: {
            selectText: 'Все',
            list: [
              {value: 'Принят', title: 'Принят'},
              {value: 'Прибыл', title: 'Прибыл'},
              {value: 'Завершен', title: 'Завершен'},
              {value: 'Отменен', title: 'Отменен'},
              {value: 'Открыт', title: 'Открыт'},
              {value: 'Прерван клиентом', title: 'Прерван клиентом'},
              {value: 'Прерван агентом', title: 'Прерван агентом'},
            ],
          },
        },
        type: 'custom',
        renderComponent: StatusHandler
      },
      firstName: {
        filter: false,
        title: 'Агент',
        sort: false,
      },
      dateAdd: {
        filter: false,
        title: 'Дата добавления',
        sortDirection: 'desc',
        valuePrepareFunction: (item) => {
          return this.datePipe.transform(item, 'd.M.yyyy, HH:mm');
        },
        sort: false,
      },
      disp_firstName: {
        filter: false,
        title: 'Диспетчер',
        sort: false,
      },
      phone: {
        filter: false,
        title: 'Номер телефона',
        sort: false,
      },
      edit: {
        filter: false,
        title: '',
        type: 'custom',
        sort: false,
        renderComponent: CustomRendererComponent,
      }
    }
  };
  constructor(private ticket: TicketSender,
              private router: Router,
              private arouter: ActivatedRoute,
              private datePipe: DatePipe) {}
  onDescUpdate(e): void {
    this.desc = e.value;
    this.parseData(this.currentPage, this.itemsPerPage, e.value, this.search)
  }
  onSearch(): void {
    this.currentPage = 0;
    if(!!this.search) {
      this.parseData(this.currentPage, this.itemsPerPage, this.desc, this.search);
    } else {
      this.parseData(this.currentPage, this.itemsPerPage, this.desc, null)
    }
  }
  onPageSizeChange(e): void {
    this.currentPage = 0;
    this.parseData(this.currentPage, this.itemsPerPage, this.desc, this.search)
  }
  onPageChange(e): void {
    console.log('onPageChange');
    this.parseData(e, this.itemsPerPage, this.desc, this.search);
  }
  parseData(currentPage = 0, perPage = 50, desc = null, search = null) {
    this.router.navigate(['/all-tickets'], {
      queryParams: {
        p: currentPage,
        ...(!!search && {search: search}),
        ...(!!this.itemsPerPage && {t: this.itemsPerPage}),
        ...(desc !== null && {desc: desc}),
        ...(!!this.filterState && {filter: this.filterState})
      }
    })
  }
  _parseData(currentPage = 0, perPage = 50, desc = null, search = null) {
    this.ticket.getTicketPaginationV2(currentPage,
        perPage,
        desc,
        search,
        !!this.filterState ? this.filterState : null).subscribe(data => {
      this.source.load(data.data.map(e => {
        return {
          ...e,
          dateAdd: e.dateAdd,
          firstName: e.inits,
          status: e.status,
          state: e.calcStatus,
          disp_firstName: e.disp_lastName + " " + e.disp_firstName + " " + e.disp_patronymic
        }
      }))
      this.ticketsData = {
        ...data,
        data: data.data.map(e => {
          return {
            ...e,
            dateAdd: e.dateAdd,
            firstName: e.inits,
            status: e.status,
            state: e.calcStatus,
            disp_firstName: e.disp_lastName + " " + e.disp_firstName + " " + e.disp_patronymic
          }
        })
      }
      console.log(currentPage);
      this.currentPage = currentPage;
      })
  }
  ngOnInit() {
    this.source.onChanged().subscribe((e) => {
      if(e.action === 'filter' && (e.filter?.filters ?? []).length > 0) {
        if(this.filterState !== e.filter.filters[0].search) {
          this.currentPage = 0;
        }
        this.filterState = e.filter.filters[0].search;
        this.parseData(this.currentPage, this.itemsPerPage, this.desc, this.search);
      }
    })
    this.arouter.queryParams.subscribe(queryParams => {
      if(!!queryParams && queryParams.hasOwnProperty('filter')) {
        this.filterState = queryParams.filter;
        this.source.setFilter([{field: "state", search: this.filterState}]);
      } else {
        this.source.setFilter([]);
      }
      if(!!queryParams && queryParams.hasOwnProperty('search')) {
        this.search = queryParams.search;
      } else {
        this.search = '';
      }
      if(!!queryParams && queryParams.hasOwnProperty('desc')) {
        this.desc = parseInt(queryParams.desc, 10);
      } else {
        this.desc = null;
      }
      if(!!queryParams && queryParams.hasOwnProperty('p')) {
        this.currentPage = parseInt(queryParams.p);
      }
      if(!!queryParams && queryParams.hasOwnProperty('t')) {
        this.itemsPerPage = parseInt(queryParams.t, 10);
      } else {
        this.itemsPerPage = 50;
      }
      console.log(queryParams);
      this._parseData(
          (!!queryParams && queryParams.hasOwnProperty('p')) ? parseInt(queryParams.p, 10) : 0,
          this.itemsPerPage,
          this.desc,
          !!this.search ? this.search : null);
    });
    if(Object.keys(this.arouter.snapshot.queryParams).length === 0) {
      this._parseData()
    }
  }

}
