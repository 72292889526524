import { Injectable } from '@angular/core';

import {HttpClient} from '@angular/common/http';
import {Conf} from '../conf';
import {TicketObject} from "../interfaces/ticket";
import {Observable} from "rxjs";
import {QuestionCategory} from "../interfaces/questionCategory";
import {StateTicket} from "../interfaces/StateTicket";
import {Morgue} from "../interfaces/morgue";
import {Metro} from "../interfaces/metro";
import {TicketById} from "../interfaces/ticketById";
import {SupportTicket} from "../interfaces/support";
import {YandexGeo} from "../interfaces/yandexGeo";
import {TicketPagination} from "../interfaces/TicketPagination";
import {map} from "rxjs/operators";
import {TicketPaginatedV2} from "../interfaces/ticketPaginated";

@Injectable({
  providedIn: 'root'
})
export class TicketSender {
  constructor(private http: HttpClient) { }
  getSupportTickets(): Observable<SupportTicket[]> {
    return this.http.get<SupportTicket[]>(`${Conf.APIBASEURL}api/supportsRequests`)
  }
  getSupportTicketsV2(page = 0): Observable<{count: number, supports: SupportTicket[]}> {
    return this.http.get<{count: number, supports: SupportTicket[]}>(`${Conf.APIBASEURL}api/supportsRequests/with-paging?skip=${page * 10}&take=10`).pipe(
        map(e => ({...e, supports: e.supports.map((e2) => ({...e2, state: e2.state === 1 ? 2 : e2.state}))}))
    )
  }
  setSupportTicket(data): Observable<any> {
    return this.http.put(`${Conf.APIBASEURL}api/supportsRequests`, data);
  }
  searchAgent(data): Observable<YandexGeo> {
    return this.http.post<YandexGeo>(`${Conf.APIBASEURL}api/positions/find-free-agents?latitude=${data.latitude}&longitude=${data.longitude}`, null);
  }
  submitTicket(data): Observable<any> {
    return this.http.post(`${Conf.APIBASEURL}api/supportsRequests`, data);
  }
  sendTicket(data) {
    return this.http.post(`${Conf.APIBASEURL}api/simpleTicket`, data);
  }
  addDevice(data) {
    return this.http.post(`${Conf.APIBASEURL}api/devices`, data);
  }
  getAllTickets(): Observable<TicketObject[]> {
    return this.http.get<TicketObject[]>(`${Conf.APIBASEURL}api/simpleTicket`);
  }
  getTicketPagination(page = 0, pageSize = 10, desc = null, search = null, status = null): Observable<TicketPagination> {
    return this.http.get<TicketPagination>(`${Conf.APIBASEURL}api/simpleTicket/v2?PageNumber=${page}&PageSize=${pageSize}${desc !== null ? desc === 0 ? '&orderByField=status&desc=false' : desc === 2 ? '&orderByField=dateAdd&desc=true' : '&orderByField=status&desc=true' : ''}${search !== null ? '&search=' + search  : ''}${status === null ? '' : `&status=${status}`}`);
  }
  getTicketPaginationV2(page = 0, pageSize = 10, desc = null, search = null, status = null): Observable<TicketPaginatedV2> {
    return this.http.get<TicketPaginatedV2>(`${Conf.APIBASEURL}api/simpleTicket/v3?PageNumber=${page}&PageSize=${pageSize}${desc !== null ? desc === 0 ? '&orderByField=dateAdd&desc=false' : desc === 2 ? '&orderByField=dateAdd&desc=true' : '&orderByField=dateAdd&desc=true' : ''}${search !== null ? '&search=' + search  : ''}${status === null ? '' : `&status=${status}`}`);
  }
  getAllTicketsFiltered(data): Observable<TicketObject[]> {
    var date = new Date();
    return this.http.post<TicketObject[]>(`${Conf.APIBASEURL}api/simpleTicket/filter`, {
      start: new Date(new Date().setDate(new Date().getDate() - data)),
      finish: new Date
    });
  }
  getTicketById(id: string): Observable<TicketById> {
    return this.http.get<TicketById>(`${Conf.APIBASEURL}api/simpleTicket/${id}`);
  }
  editTicket(data, id) {
    return this.http.put(`${Conf.APIBASEURL}api/simpleTicket?id=${id}`, data);
  }
  getQuestionCategory(): Observable<QuestionCategory[]> {
    return this.http.get<QuestionCategory[]>(`${Conf.APIBASEURL}api/questionsCategory`);
  }
  getStateTickets(): Observable<StateTicket[]> {
    return this.http.get<StateTicket[]>(`${Conf.APIBASEURL}api/stateTicket`)
  }
  getMetro(): Observable<Metro[]> {
    return this.http.get<Metro[]>(`${Conf.APIBASEURL}api/metro`)
  }
  deleteTicket(id) {
    return this.http.delete(`${Conf.APIBASEURL}api/simpleTicket?id=${id}`)
  }
  getMorgues(): Observable<Morgue[]> {
    return this.http.get<Morgue[]>(`${Conf.APIBASEURL}api/morgues`)
  }
}

