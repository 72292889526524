import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import {DatePipe} from "@angular/common";

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExcelFileExporterService {

  constructor(private dateFormat: DatePipe) { }

  public exportAsExcelFile(json: any[], excelFileName: string): void {

    let objectMaxLength = [];
    for (let i = 0; i < json.length; i++) {
      let value = <any>Object.values(json[i]);
      for (let j = 0; j < value.length; j++) {
        if (typeof value[j] == "number") {
          objectMaxLength[j] = 10;
        } else {
          objectMaxLength[j] =
              objectMaxLength[j] >= value[j].length
                  ? objectMaxLength[j]
                  : value[j].length;
        }
      }
      let key = <any>Object.keys(json[i]);
      for (let j = 0; j < key.length; j++) {
        objectMaxLength[j] =
            objectMaxLength[j] >= key[j].length
                ? objectMaxLength[j]
                : key[j].length;
      }
    }
    console.log(objectMaxLength);

    var wscols = [
      { width: objectMaxLength[0] },  // first column
      { width: objectMaxLength[1] }, // second column
      { width: objectMaxLength[2] }, //...
      { width: objectMaxLength[3] },
      { width: objectMaxLength[4] },
      { width: objectMaxLength[5] },
      { width: objectMaxLength[6] },
      { width: objectMaxLength[7] },
      { width: objectMaxLength[8] },
      { width: objectMaxLength[9] }
    ];

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    worksheet["!cols"] = wscols;
    console.log('worksheet',worksheet);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + ' ' + this.dateFormat.transform(new Date().getTime(), 'dd.MM.yyyy') + EXCEL_EXTENSION);
  }

}
