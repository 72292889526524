import { Component, OnInit } from '@angular/core';
import {TicketSender} from "../../../../shared/services/tickets.service";
export interface MetroElement {
  name: string;
  id: number;
  nameLine: string,
  lat: number,
  lng: number,
  isDelete: boolean,
  hex_color: string
}
@Component({
  selector: 'app-metro-directory',
  templateUrl: './metro-directory.component.html',
  styleUrls: ['./metro-directory.component.css']
})
export class MetroDirectoryComponent implements OnInit {
  loaded = false;
  metroData: any;
  displayedColumns: string[] = ['id', 'name', 'nameLine']
  ELEMENT_DATA: MetroElement[]
  settingsTable = {
    actions: false,
    noDataMessage: 'Список пуст',
    columns: {
      id: {
        title: 'ID',
        filter: false
      },
      name: {
        title: 'Станция',
      },
      nameLine: {
        title: 'Линия',
      }
    }
  };

  constructor(private metro: TicketSender) { }

  ngOnInit() {
    this.metro.getMetro().subscribe(data => {
      this.ELEMENT_DATA = data as MetroElement[]
      this.loaded = true
    })
  }

}
