<form #deviceWatch="ngForm" *ngIf="!loading" [formGroup]="deviceForm" novalidate (ngSubmit)="onSubmit()">
  <mat-card style="margin: 10px" class="shipping-card">
    <mat-card-header>
      <mat-card-title>Добавить новый трэкер</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <input matInput placeholder="Название трэкера" formControlName="name">
            <mat-error *ngIf="deviceForm.controls['name'].hasError('required')">
              Название <strong>обязательно</strong>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <input matInput placeholder="Идентификатор" formControlName="uniqueId">
            <mat-error *ngIf="deviceForm.controls['uniqueId'].hasError('required')">
              Идентификатор <strong>обязателен</strong>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field class="full-width">
            <input matInput placeholder="Номер (не обязательно)" formControlName="phone">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
      </div>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button color="primary" [disabled]="!deviceForm.valid" type="submit">Отправить</button>
    </mat-card-actions>
  </mat-card>
</form>
<app-edit-device></app-edit-device>
