<mat-spinner *ngIf="!loaded">

</mat-spinner>
<form *ngIf="loaded" #pForm="ngForm" [formGroup]="userForm" novalidate (ngSubmit)="onSubmit()">
  <mat-card style="margin: 10px" class="shipping-card">
    <mat-card-header>
      <mat-card-title>Создать пользователя</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <input matInput placeholder="e-mail" formControlName="email">
            <mat-error *ngIf="userForm.controls['email'].hasError('required')">
              E-mail <strong>обязателен</strong>
            </mat-error>
          </mat-form-field>
        </div>

      </div>
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <input matInput placeholder="ФИО" formControlName="fio">
            <mat-error *ngIf="userForm.controls['fio'].hasError('required')">
              ФИО <strong>обязательно</strong>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <mat-select placeholder="Привилегия" formControlName="roleId">
              <mat-option *ngFor="let role of states" [value]="role.id">
                {{ role.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="userForm.controls['roleId'].hasError('required')">
              Роль <strong>обязательна</strong>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field appearance="fill">
            <mat-label>Дата рождения</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="dateBirt">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <input [type]="hide ? 'password' : 'text'" matInput placeholder="Пароль" formControlName="passwordHash">
            <mat-icon style="cursor: pointer" matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            <mat-error *ngIf="userForm.controls['passwordHash'].hasError('required')">
              Пароль <strong>обязателен</strong>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-radio-group formControlName="sex">
            <mat-radio-button [value]="true">Мужчина</mat-radio-button>
            <mat-radio-button [value]="false">Женщина</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <button [disabled]="!userForm.valid" mat-raised-button color="primary" type="submit">Создать</button>
    </mat-card-actions>
  </mat-card>
</form>