import {Component, Inject, Input, LOCALE_ID, OnInit} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {LogsService} from "../../../../shared/services/logs.service";
import {ExcelFileExporterService} from "../../../../shared/services/excel-file-exporter.service";
import {forkJoin} from "rxjs";
import * as _ from 'lodash';
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-statistis-module',
  templateUrl: './statistis-module.component.html',
  styleUrls: ['./statistis-module.component.css']
})
export class StatistisModuleComponent implements OnInit {
  @Input() title;
  @Input() datePickerTitleStart;
  @Input() datePickerTitleEnd;
  @Input() type;


  dateGroup = this.fb.group({
    startDate: [null, Validators.required],
    endDate: [null, Validators.required]
  })
  constructor(private fb: FormBuilder,
              private logs: LogsService,
              private excel: ExcelFileExporterService,
              private datePipe: DatePipe,
              @Inject(LOCALE_ID) private locale: string) { }
  exportData() {
    var startDate = new Date(this.dateGroup.controls['startDate'].value.setHours(this.dateGroup.controls['startDate'].value.getHours() + 3));
    var endDate = new Date(this.dateGroup.controls['endDate'].value.setHours(this.dateGroup.controls['endDate'].value.getHours() + 3));

    forkJoin([
      this.logs.getStats({start: startDate, finish: endDate}),
      this.logs.getStatsCanceled({start: startDate, finish: endDate}),
      this.logs.getStatsNotCompleted({start: startDate, finish: endDate})
    ]).subscribe((resp) => {
      let itemStats = resp[0].map(e => {
        return {
          ФИО: e.inits,
          ВзятыеЗаказы: e.count,
          id: e.agentId
        }
      })
      let itemStatsCanceled = resp[1].map(e => {
        return {
          ФИО: e.inits,
          Отменены: e.count,
          id: e.agentId
        }
      })
      let itemStatsNotCompleted = resp[2].map(e => {
        return {
          ФИО: e.inits,
          НеЗавершены: e.count,
          id: e.agentId
        }
      })
      this.excel.exportAsExcelFile(_.merge(itemStats, itemStatsCanceled, itemStatsNotCompleted), 'Отчет')
    })
  }
  ngOnInit(): void {
    this.dateGroup.valueChanges.subscribe(e => {
      console.log(e);
    })
  }

}
