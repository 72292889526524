<mat-card *ngIf="loaded" class="example-card">
  <mat-card-content>
    <div class="row">
      <div class="col-md-1">
        <div [matBadge]="currentRole === 'admin' ? 'A' : ''" matBadgeColor="warn" mat-card-avatar class="example-header-image" style="width: 70px; height: 70px; background-size: cover; background-image: url('assets/img/profile.jpg');"></div>
      </div>
      <div class="col-md-11 d-flex flex-column justify-content-center">
        <span style="font-size: 20px;">{{userData.firstName}}</span>
        <span style="color: #838383;">{{userData.email}}</span>
        <mat-chip-list
                class="example-chip"
                cdkDropList
                cdkDropListOrientation="horizontal">
          <mat-chip
                  class="example-box"
                  cdkDrag>
            {{userData?.role?.name}}
          </mat-chip>
        </mat-chip-list>
      </div>
    </div>
    <div *ngIf="currentRole === 'admin'" style="padding-top: 10px; padding-bottom: 10px">
     <div class="row">
       <div class="col-md-3">
         <mat-card>
           <mat-card-content>
             Имя: {{userData.firstName}}
           </mat-card-content>
         </mat-card>
       </div>
       <div class="col-md-3">
         <mat-card>
           <mat-card-content>
             Фамилия: {{userData.lastName}}
           </mat-card-content>
         </mat-card>
       </div>
       <div class="col-md-3">
         <mat-card>
           <mat-card-content>
             Отчество: {{userData.patronymic}}
           </mat-card-content>
         </mat-card>
       </div>
       <div class="col-md-3">
         <mat-card>
           <mat-card-content>
             Создан: {{userData.dateAdd | date:'dd MMM yyyy'}}
           </mat-card-content>
         </mat-card>
       </div>
     </div>
    </div>
    <div class="row">
      <div class="col-md-6">

      </div>
    </div>

  </mat-card-content>
  <mat-card-actions>
    <button *ngIf="currentRole === 'admin'" mat-button (click)="fetchUser()">ОБНОВИТЬ</button>
<!--    <button mat-button>СТАТИСТИКА</button>-->
  </mat-card-actions>
</mat-card>
