import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {UsersServices} from '../shared/services/users.service';
import {MapService} from '../shared/services/map.service';
import {DevicesTable} from '../shared/interfaces/table';
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-logpage',
  templateUrl: './logpage.component.html',
  styleUrls: ['./logpage.component.css']
})
export class LogpageComponent {

  constructor(private user: UsersServices, private map: MapService, private datePipe: DatePipe) {
    this.fetchUser();
  }

  loaded = false;
  loadedTable = false;
  userData: any;
  currentRole: any;
  responseMarkers: any;
  displayedColumns: string[] = ['disabled', 'status', 'contact', 'uniqueId', 'name'];
  fetchUser() {
      this.userData = this.user.currentUser[0];
      let currentRoleName = 'none';
      if (this.user.currentUser[0].roleId !== null) {
        currentRoleName = this.user.currentRoles.filter(e => e.id === this.user.currentUser[0].roleId)[0].name;
      } else {
        currentRoleName = 'none';
        }
      this.currentRole = currentRoleName;
      this.loaded = true;
      this.responseMarkers = this.map.currentDevices;
      this.loadedTable = true;
  }
}
