import { Component, OnInit } from '@angular/core';
import {DirectoryService} from "../../../shared/services/directory.service";

export interface DirectoryElement {
  morgID: string;
  name: string;
  type: string;
  address: string;
  city: string;
  code: string
}
@Component({
  selector: 'app-admin-directory',
  templateUrl: './admin-directory.component.html',
  styleUrls: ['./admin-directory.component.scss']
})
export class AdminDirectoryComponent implements OnInit {
  displayedColumns: string[] = ['name', 'type', 'address', 'city'];
  ELEMENT_DATA: DirectoryElement[] = this.directory.morgues;
  settingsTable = {
    actions: false,
    noDataMessage: 'Список пуст',
    columns: {
      name: {
        title: 'Название',
      },
      type: {
        title: 'Тип',
      },
      address: {
        title: 'Адрес'
      },
      city: {
        title: 'Город'
      }
    }
  };
    constructor(private directory: DirectoryService) { }
  ngOnInit(): void {

  }
}
