  import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  IBarChartOptions,
  IChartistAnimationOptions,
  IChartistData
} from 'chartist';
import { ChartEvent, ChartType } from 'ng-chartist';
import {MapService} from '../../../shared/services/map.service';
import {UsersServices} from "../../../shared/services/users.service";
import {TicketSender} from "../../../shared/services/tickets.service";
  import {Statistic} from "../../../shared/interfaces/Statistic";
  import * as _ from 'lodash';
  import {ExcelFileExporterService} from "../../../shared/services/excel-file-exporter.service";
  import {MatDialog} from "@angular/material/dialog";
  import {FormBuilder, Validators} from "@angular/forms";


  @Component({
  selector: 'app-admintrackers',
  templateUrl: './admintrackers.component.html',
  styleUrls: ['./admintrackers.component.scss']
})
export class AdmintrackersComponent implements OnInit, OnDestroy {
  statisticData: Statistic = null;
  updateInterval = null;
  countNotFinishedDates = null;
  countFinishedDates = null;
  counter = 0;
  loaded = false;
  math = Math;
  chartsLoaded = false;
  weekTickets: any[] = [];
  currentDay: any[] = [];
  config = [
      {day: 'numeric', weekday: "long", month: 'numeric'},
      {weekday: "long"},
      {day: 'numeric', month: 'numeric'},
      {day: 'numeric', month: 'numeric', year: 'numeric'}
    ]
  constructor(private devices: MapService,
              private ticket: TicketSender,
              private agent: UsersServices,
              private excel: ExcelFileExporterService,
              public dialog: MatDialog) { }

  type: ChartType = 'Line';
  data1: IChartistData = null;
  data2: IChartistData = null;
  data3: IChartistData = null;

  options: IBarChartOptions | any = {
    axisX: {
      showGrid: false
    },
    height: 200,
    showArea: true
  };

  events: ChartEvent = {
    draw: (data) => {
      if (data.type === 'line') {
        data.element.animate({
          y2: {
            dur: '3s',
            from: data.y1,
            to: data.y2,
            easing: 'easeOutQuad'
          } as IChartistAnimationOptions
        });
      }
    }
  };
  ngOnDestroy() {
    clearInterval(this.updateInterval);
  }
  exportDate(type) {
    if(type === 1) {
      this.excel.exportAsExcelFile((this.data1.labels as any[]).map((e, i) => {
        return {
          Количество: this.data1.series[0][i],
          День: e
        }
      }), "Кол-во успешных заявок за  ")
    }
    if(type === 2) {
      this.excel.exportAsExcelFile((this.data2.labels as any[]).map((e, i) => {
        return {
          Количество: this.data2.series[0][i],
          День: e
        }
      }), "Кол-во завершенных неуспешных заявок за ")
    }
  }
  async countNotFinished() {
    var result = await this.openDialog();
    var startDate = new Date(result.start.setHours(result.start.getHours() + 3));
    var finishDate = new Date(result.finish.setHours(result.finish.getHours() + 3));
    if(result !== null) {
      this.devices.getNotFinishedTickets({start: startDate, finish: finishDate}).subscribe((e) => {
        this.statisticData.countNotTickets = e.value;
        this.countNotFinishedDates = result;
      })
    }
  }
  async countFinished() {
    var result = await this.openDialog();
    var startDate = new Date(result.start.setHours(result.start.getHours() + 3));
    var finishDate = new Date(result.finish.setHours(result.finish.getHours() + 3));
    if(result !== null) {
      this.devices.getFinishedTickets({start: startDate, finish: finishDate}).subscribe((e) => {
        this.statisticData.countTicketsFinish = e.value;
        this.countFinishedDates = result;
      })
    }
  }
  async openDialog() {
      const dialogRef = this.dialog.open(PickDateModal);
      return await dialogRef.afterClosed().toPromise();
  }
  exportCurrentDate(type) {
    this.excel.exportAsExcelFile(this.currentDay.map(i => {
      return {
        День: i.dateOfWeek + ", " + new Date().toLocaleDateString('ru-RU', this.config[3] as Intl.DateTimeFormatOptions),
        Заявок: i.users,
        Статус: {cancel_agent: 'Отменен агентом', cancel_user: 'Отменен клиентом', canceled_ok: 'Отменен', finished_ok: 'Завершен', submitted: 'Принят', arrived: 'Прибыл'}[i.status]
      }
    }), 'Заявки на ')
    if(type === 1) {

    }
  }
  ngOnInit() {
    this.ticket.getAllTicketsFiltered(7).subscribe((data) => {
      let days = ["понедельник", "вторник", "среда",
        "четверг", "пятница", "суббота", "воскресенье"];
      let arr = data.map((e) => {
        return {
          ...e,
          status: (e.reason !== null && e.reason.split('|').length > 1) ? {cancel: 'cancel_agent', user: 'cancel_user'}[e.reason.split('|')[1].trim()] : e.status,
          currentDay: new Date(e.dateAdd).toLocaleDateString('ru-RU', this.config[2] as Intl.DateTimeFormatOptions),
          dayOfWeek: new Date(e.dateAdd).toLocaleDateString('ru-RU', this.config[1] as Intl.DateTimeFormatOptions)
        }
      });
      let filArr = [];
      days.forEach((i, index) => {
        filArr.push(arr.filter(d => d.currentDay === new Date(new Date().setDate(new Date().getDate() - index)).toLocaleDateString('ru-RU', this.config[2] as Intl.DateTimeFormatOptions)))
      })
         filArr = filArr.map(e => {
           return _.chain(e)
               .groupBy("status")
               .map((value, key) => ({ status: key, users: value.length, dateOfWeek: value[0].dayOfWeek }))
               .value();
         })
      if(!filArr[0].length) {
        filArr[0] = [{status: 'finished_ok', users: 0, dateOfWeek: new Date().toLocaleDateString('ru-RU', this.config[1] as Intl.DateTimeFormatOptions)}]
      }
      this.currentDay = filArr[0];
      filArr = filArr.map(e => {
        return {..._.chain(e)
              .groupBy("dateOfWeek")
              .map((value, key) => ({ status: key, users: value }))
              .value()[0]}
      })
      this.weekTickets = filArr;
      this.data1 = {
        labels: this.weekTickets.map(e => e.status.slice(0, 3)).reverse(),
        series: [
            this.weekTickets.map(e => e.users.filter(i => i.status === "finished_ok").length ? e.users.filter(i => i.status === "finished_ok")[0].users : 0).reverse()
        ]
      }
      this.data2 = {
        labels: this.weekTickets.map(e => e.status.slice(0, 3)).reverse(),
        series: [
          this.weekTickets.map(e => e.users.filter(i => i.status === "canceled_ok").length ? e.users.filter(i => i.status === "canceled_ok")[0].users : 0).reverse()
        ]
      }
      this.chartsLoaded = true;
    })
    this.devices.getStatistic().subscribe((data) => {
      this.statisticData = data;
      this.updateInterval = setInterval(() => {
        ++this.counter
      }, 1000)
      this.loaded = true;
    })
  }
}
  @Component({
    selector: 'pick-date-modal',
    templateUrl: 'pickDate.html',
  })
  export class PickDateModal {
    formg = this.fb.group({
      start: [null, Validators.required],
      finish: [null, Validators.required]
    })
    constructor(private fb: FormBuilder) {
    }
  }
