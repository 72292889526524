<div *ngIf="!this.ticketsData" style="display: flex;width: 100%;height: 100%;justify-content: center;align-items: center;"><mat-spinner></mat-spinner></div>
<div *ngIf="!!this.ticketsData" style="margin: 10px">

  <div class="ticket-panel">
    <mat-radio-group [(ngModel)]="itemsPerPage" (change)="onPageSizeChange($event)" class="ml-2" style="display: flex;gap: 20px;margin: 20px 0;justify-content: center;">
      <mat-radio-button color="primary" [value]="50">По 50 заявок</mat-radio-button>
      <mat-radio-button color="primary" [value]="100">По 100 заявок</mat-radio-button>
      <mat-radio-button color="primary" [value]="500">По 500 заявок</mat-radio-button>
      <mat-radio-button color="primary" [value]="1000">По 1000 заявок</mat-radio-button>
    </mat-radio-group>
    <mat-form-field appearance="fill">
      <mat-label>Сортировка по дате добавления</mat-label>
      <mat-select [(ngModel)]="desc" (selectionChange)="onDescUpdate($event)">
        <mat-option [value]="1">Сортировка по убыванию</mat-option>
        <mat-option [value]="0">Сортировка по возрастанию</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Поиск Ф.И.О</mat-label>
      <input [(ngModel)]="search" matInput>
    </mat-form-field>
    <button mat-raised-button (click)="onSearch()">Применить поиск</button>
  </div>
  <ng2-smart-table [source]="source" [settings]="settingsTable"></ng2-smart-table>
  <div style="display: none;" *ngFor="let item of [] | paginate: {currentPage: currentPage, itemsPerPage: itemsPerPage, totalItems: ticketsData.totalRecords}">
<!--    {{item.id}}-->
  </div>
  <pagination-controls previousLabel="Назад" nextLabel="Вперед" (pageChange)="onPageChange($event)"></pagination-controls>
</div>
