import {Component, Inject, OnInit} from '@angular/core';
import {map, startWith} from "rxjs/operators";
import {Observable} from "rxjs";
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {UsersServices} from "../../../../shared/services/users.service";
import {MapItem} from "../../../../shared/interfaces/mapMarkers";
import {NotifierMsgService} from "../../../../shared/services/notifier-msg.service";
import {ExcelFileExporterService} from "../../../../shared/services/excel-file-exporter.service";
import {DatePipe} from "@angular/common";
import {MatDialog} from "@angular/material/dialog";
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-status-history',
  templateUrl: './status-history.component.html',
  styleUrls: ['./status-history.component.scss']
})
export class StatusHistoryComponent implements OnInit {

  constructor(private agent: UsersServices,
              private fb: FormBuilder,
              private msg: NotifierMsgService,
              private excel: ExcelFileExporterService,
              private dateFormat: DatePipe,
              public dialog: MatDialog
              ) { }
  dateValues = this.fb.group({
    dateStart: [null, Validators.required],
    dateFinish: [null, Validators.required]
  })
  substatuses = {
    on_document: 'На оформлении документов',
    on_issue: 'На выдаче',
    on_canceled_ok: 'Заказ отменен',
    on_finished_ok: 'Заказ завершен',
    on_escort: 'На сопровождении',
    on_submitted: 'Принял заказ',
    on_ticket: 'Назначен заказ',
    on_arrived: 'Прибыл на место по заявке'
  }
  myControl = new FormControl();
  options: MapItem[] | string[]
  filteredOptions: Observable<MapItem[]>;
  loaded = false;
  selectedId: number = null;
  selectName: string = null;
  dateStart: string;
  dateFinish: string;
  dispFn(e: MapItem) {
    return e === null ? '' : e.user.firstName;
  }
  onSelected(e) {
    this.selectedId = e.id;
    this.selectName = e.user.firstName;
  }
  onExport() {
    var startDate = new Date(this.dateValues.controls['dateStart'].value.setHours(this.dateValues.controls['dateStart'].value.getHours() + 3));
    var endDate = new Date(this.dateValues.controls['dateFinish'].value.setHours(this.dateValues.controls['dateFinish'].value.getHours() + 3))
    this.agent.getAgentStates({objId: this.selectedId,
      start: startDate,
      finish: endDate}).subscribe(e => {
        console.log(e);
      if(!e.length) {
        this.msg.notify('error', 'Данные пусты!')
      } else {
        this.excel.exportAsExcelFile(e.map(i => {
          return {
            Дата: this.dateFormat.transform(i.dateAdd, 'dd.MM.yyyy'),
            Время: this.dateFormat.transform(i.dateAdd, 'H:mm:ss'),
            Статус: i.status ? 'На смене' : 'Не на смене',
            Подстатус: !i.status ? '' : i.substatus.length ? this.substatuses[i.substatus] : '',
            ФИО: this.selectName,
            ФИО_Умершего: !i.status || (i.substatus === "" || i.substatus === null) ? '' : i.dead === null || !i.dead.length ? '-' : i.dead
          }
        }), 'Статусы')
      }
    })
  }
  onGroupExport() {
    const dialogRef = this.dialog.open(GroupSelectionDialog, {
      data: this.options
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
  ngOnInit() {
    this.agent.getAgentsAPI().pipe(
        map((e) => {
          return e.map(i => {
            i.user.firstName = i.user.firstName = i.user.secondName + " " + i.user.firstName.slice(0, 1) + ". " + i.user.patronymic.slice(0, 1) + "."
            return {...i}
          })
        })
    ).subscribe(i => {
      this.options = i;
      this.filteredOptions = this.myControl.valueChanges
          .pipe(
              startWith(''),
              map((value) => this._filter(value))
          );
      this.loaded = true;
    })
  }

  private _filter(value: string): MapItem[] {
    if(typeof value !== "string") {
      return;
    }
    const filterValue = value.toLowerCase();

    // @ts-ignore
    return this.options.filter(option => option.user.firstName.toLowerCase().includes(filterValue));
  }
}
@Component({
  selector: 'group-selection-dialog',
  templateUrl: 'group-selection-alert.html',
})
export class GroupSelectionDialog implements OnInit {
  constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
      private userApi: UsersServices,
      private fb: FormBuilder,
      private excel: ExcelFileExporterService,
      private dateFormat: DatePipe,
  ) {
  }
  dataForm = this.fb.group({
    start: [null, Validators.required],
    finish: [null, Validators.required]
  })
  substatuses = {
    on_document: 'На оформлении документов',
    on_issue: 'На выдаче',
    on_canceled_ok: 'Заказ отменен',
    on_finished_ok: 'Заказ завершен',
    on_escort: 'На сопровождении',
    on_submitted: 'Принял заказ',
    on_ticket: 'Назначен заказ',
    on_arrived: 'Прибыл на место по заявке'
  }
  sourceData: MapItem[] = [];
  dispData: MapItem[] = [];
  searchText = "";
  selectedDateFormat = null;
  filterItems(ev) {
    console.log(ev);
  }
  displayFilter(): MapItem[] {
    if(!this.searchText.length) {
      return this.dispData;
    }
    return this.dispData.filter((e) => e.user.firstName.toLowerCase().includes(this.searchText.toLowerCase()));
  }
  dropDate() {
    this.dataForm.setValue({
      start: null,
      finish: null
    })
  }
  submitExport() {
    var items = this.dispData.filter((e) => e.checked);
    var formDate = this.dataForm.value;
    formDate.start = new Date(new Date(formDate.start).setDate(new Date(formDate.start).getDate() + 1));
    formDate.finish = new Date(new Date(formDate.finish).setDate(new Date(formDate.finish).getDate() + 1));

    var date = this.selectedDateFormat === null ? this.dataForm.value : {
      start: new Date(new Date().setDate(new Date().getDate() - this.selectedDateFormat)),
      finish: new Date()
    }
    this.userApi.getAllAgentStates(date).subscribe((e) => {
      var arr = [];
      items.forEach((i) => {
        arr.push(...e.filter((id) => i.id === id.agentId).map((id) => ({...id, fio: i.user.firstName})))
      })
      arr = arr.map((e) => {
        return {
          ФИО: e.fio,
          Дата: this.dateFormat.transform(e.dateAdd, 'dd.MM.yyyy'),
          Время: this.dateFormat.transform(e.dateAdd, 'H:mm:ss'),
          Статус: e.status ? 'На смене' : 'Не на смене',
          Подстатус: !e.status ? '' : e.substatus.length ? this.substatuses[e.substatus] : '',
          ФИО_Умершего: !e.status || (e.substatus === null || e.substatus === "") ? '' : e.dead === null || !e.dead.length ? '' : e.dead
        }
      })
      this.excel.exportAsExcelFile(arr, 'Группа агентов')
    })
  }
  ngOnInit() {
    this.dataForm.valueChanges.subscribe((e) => {
      this.selectedDateFormat = null;
    })
    this.sourceData = this.data;
    this.dispData = this.sourceData.map((e) => {
      return {
        ...e,
        checked: false
      }
    })
    console.log(this.data);
  }
}
