import {Component, Input, OnInit} from '@angular/core';
import {MapItem} from "../../../../../../shared/interfaces/mapMarkers";
import {AgentService} from "../../../../../../shared/services/agent.service";
import {NotifierMsgService} from "../../../../../../shared/services/notifier-msg.service";

@Component({
  selector: 'app-user-status',
  templateUrl: './user-status.component.html',
  styleUrls: ['./user-status.component.css']
})
export class UserStatusComponent implements OnInit {
  @Input() agentData: MapItem;
  constructor(private agent: AgentService, private notify: NotifierMsgService) {}
  status = null;
  substatus = null;
  onStatusSelect(e) {
    this.agent.editAgentConfig({
      id: this.agentData.id,
      userId: this.agentData.userId,
      uniqueId: this.agentData.uniqueId,
      user1cUniqueId: this.agentData.user1cUniqueId,
      inits: this.agentData.inits,
      isDelete: this.agentData.isDelete,
      status: e.value,
      substatus: this.agentData.substatus,
      message: '',
      isDeleted: false
    }).subscribe((e) => {
      this.notify.notify('success', 'Статус изменен!')
    }, (e) => {
      this.notify.notify('error', 'Ошибка в изменении статуса!')
    })
  }
  onSubstatusSelect(e) {
    this.agent.editAgentConfig({
      id: this.agentData.id,
      userId: this.agentData.userId,
      uniqueId: this.agentData.uniqueId,
      user1cUniqueId: this.agentData.user1cUniqueId,
      inits: this.agentData.inits,
      isDelete: this.agentData.isDelete,
      status: this.agentData.status,
      substatus: e.value,
      message: '',
      isDeleted: false
    }).subscribe((e) => {
      this.notify.notify('success', 'Подстатус изменен!')
    }, (e) => {
      this.notify.notify('error', 'Ошибка в изменении подстатуса!')
    })
  }
  clearAll() {
    let data = {
      id: this.agentData.id,
      userId: this.agentData.userId,
      uniqueId: this.agentData.uniqueId,
      user1cUniqueId: this.agentData.user1cUniqueId,
      inits: this.agentData.inits,
      isDelete: this.agentData.isDelete,
      status: false,
      substatus: "",
      message: '',
      isDeleted: false
    }
    this.agent.editAgentConfig(data).subscribe(e => {
      this.status = false;
      this.substatus = "";
      this.notify.notify('success', 'Данные сброшены!')
    }, (error => this.notify.notify('error', 'Ошибка сброса!')))
  }
  ngOnInit(): void {
    this.status = this.agentData.status;
    let tempSub = this.agentData.substatus === null ? "" : this.agentData.substatus;
    this.substatus = tempSub
  }

}
