import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {UserList} from "../interfaces/userList";
import {Conf} from "../conf";
import {Observable} from "rxjs";
import {CallObj, CallObjV2} from "../interfaces/mango";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class MangoService {

  constructor(private http: HttpClient) { }

  getCalls(date):Observable<CallObjV2[]> {
    return this.http.post<CallObjV2[]>(`${Conf.APIBASEURL}api/mango/calls/filter`, date).pipe(
        map((e) => {
          return e.map(i => {
            let operatorArr = [];

            i.text.split('\n').forEach(item => {
              if(item.split(';')[0] === "operator") {
                operatorArr.push({type: 'operator', text: item.split(';')[1]})
              }
              if(item.split(';')[0] === "client") {
                operatorArr.push({type: 'client', text: item.split(';')[1]})
              }
            })
            return {...i, dialog: operatorArr}
          })
        })
    );
  }
}
