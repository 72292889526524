<ng-container [ngSwitch]="isLogin">
  <form (ngSubmit)="login()"
        [formGroup]="loginForm"
        *ngSwitchCase="true"
        style="margin-bottom: 0px;"
        class="login-form">
    <div class="login-wrapper">
      <mat-card>
        <div class="example-button-container">
          <button disabled mat-fab color="primary" aria-label="Example icon button with a delete icon">
            <mat-icon>account_circle</mat-icon>
          </button>
        </div>
        <mat-card-header>
          <mat-card-title style="padding-top: 10px; padding-bottom: 10px">Авторизация</mat-card-title>
        </mat-card-header>
        <p>
          <mat-form-field appearance="standard">
            <mat-label>e-mail </mat-label>
            <input formControlName="email" matInput placeholder="Ваша почта">
            <mat-icon matSuffix>email</mat-icon>
          </mat-form-field>
        </p>
        <p>
          <mat-form-field appearance="standard">
            <mat-label>Пароль</mat-label>
            <input [type]="hide ? 'password' : 'text'" formControlName="password" matInput placeholder="Ваш пароль">
            <mat-icon style="cursor:pointer;" (click)="hide = !hide" matSuffix>lock</mat-icon>
          </mat-form-field>
        </p>
        <mat-card-actions>
          <button [disabled]="!loginForm.valid" mat-button type="submit" color="primary">ВХОД</button>
          <button (click)="isLogin = false" mat-button color="warn">ВОССТАНОВИТЬ ПАРОЛЬ</button>
        </mat-card-actions>
      </mat-card>
    </div>
  </form>
  <form (ngSubmit)="recover()"
        [formGroup]="recoverForm"
        *ngSwitchCase="false"
        class="login-form">
    <div class="login-wrapper">
      <mat-card>
        <div class="example-button-container">
          <button disabled mat-fab color="primary" aria-label="Example icon button with a delete icon">
            <mat-icon>account_circle</mat-icon>
          </button>
        </div>
        <mat-card-header>
          <mat-card-title style="padding-top: 10px; padding-bottom: 10px">Восстановление</mat-card-title>
        </mat-card-header>
        <p>
          <mat-form-field appearance="standard">
            <mat-label>Ваш номер телефона</mat-label>
            <input formControlName="phone" matInput placeholder="Номер">
            <mat-icon matSuffix>phone_iphone</mat-icon>
          </mat-form-field>
        </p>
        <mat-card-actions>
          <button [disabled]="!recoverForm.valid" mat-button type="submit" color="primary">ПРИНЯТЬ</button>
          <button (click)="isLogin = true" mat-button color="warn">ОТМЕНА</button>
        </mat-card-actions>
      </mat-card>
    </div>
  </form>
</ng-container>

