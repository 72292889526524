import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Conf} from '../conf';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DirectoryService {
  morgues: any = false;
  morguesLoaded: Subject<boolean> = new Subject<boolean>();
  morguesToggle = false;


  getMorgue() {
    this.http.get(`${Conf.APIBASEURL}api/morgues`).subscribe(data => {
      this.morgues = data;
      this.morguesLoaded.subscribe(e => this.morguesToggle = e)
      this.morguesLoaded.next(true);
    });
  }

  constructor(private http: HttpClient) { }
}
