import { Injectable } from '@angular/core';
import * as io from 'socket.io-client';
import {HttpClient} from '@angular/common/http';
import {Observable} from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class WebSocketService {

  socket: any;
  readonly uri: string = "https://node-cds.3dcafe.ru";

  constructor(private http: HttpClient) {
    this.socket = io(this.uri);
  }
  listen(eventName: string) {
    return new Observable((sub) => {
      this.socket.on(eventName, (data) => [
          sub.next(data)
      ])
    })
  }
}
