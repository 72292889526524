<div class="p-3">
    <h2 class="light-text m-0">Техническая поддержка</h2>
    <h1 class="title-text">С какой проблемой вы столкнулись?</h1>
</div>
<div class="container-fluid">
    <div class="row cards-row">
        <div class="col-md-3">
            <div class="p-2 h-100 d-flex flex-column justify-content-between align-items-start">
                <div class="card-icon mt-3">
                    <mat-icon>map</mat-icon>
                </div>
                <div class="card-title">
                    <h3 class="pl-2 m-0" style="font-weight: 500;">Карта</h3>
                </div>
                <div class="card-desc">
                    <p class="pl-2" style="font-size: 13px;font-weight: 300;">Проблемы связанные с отображением агентов (маркеров), проблемы фильтрации, проблема поиска, проблема со статусами и т.д.</p>
                </div>
                <button (click)="openDialog(1)" mat-raised-button color="primary">Сообщить</button>
            </div>
        </div>
        <div class="col-md-3">
            <div class="p-2 h-100 d-flex flex-column justify-content-between align-items-start">
                <div class="card-icon mt-3">
                    <mat-icon>assignment</mat-icon>
                </div>
                <div class="card-title">
                    <h3 class="pl-2 m-0" style="font-weight: 500;">Заявка</h3>
                </div>
                <div class="card-desc">
                    <p class="pl-2" style="font-size: 13px;font-weight: 300;">Проблемы с назначением агента, отображением полей в заявке, проблемы в системе заявок в целом.</p>
                </div>
                <button (click)="openDialog(2)" mat-raised-button color="primary">Сообщить</button>
            </div>
        </div>
        <div class="col-md-3">
            <div class="p-2 h-100 d-flex flex-column justify-content-between align-items-start">
                <div class="card-icon mt-3">
                    <mat-icon>language</mat-icon>
                </div>
                <div class="card-title">
                    <h3 class="pl-2 m-0" style="font-weight: 500;">Сайт</h3>
                </div>
                <div class="card-desc">
                    <p class="pl-2" style="font-size: 13px;font-weight: 300;">Проблемы в открытии сайта, не отображаются поля/контент/кнопки/текст.</p>
                </div>
                <button (click)="openDialog(3)" mat-raised-button color="primary">Сообщить</button>
            </div>
        </div>
        <div class="col-md-3">
            <div class="p-2 h-100 d-flex flex-column justify-content-between align-items-start">
                <div class="card-icon mt-3">
                    <mat-icon>ad_units</mat-icon>
                </div>
                <div class="card-title">
                    <h3 class="pl-2 m-0" style="font-weight: 500;">Мобильное приложение</h3>
                </div>
                <div class="card-desc">
                    <p class="pl-2" style="font-size: 13px;font-weight: 300;">Проблемы связанные с мобильным приложением, ошибки профиля или взятия заказа, неработающий профиль и т.д.</p>
                </div>
                <button (click)="openDialog(4)" mat-raised-button color="primary">Сообщить</button>
            </div>
        </div>
    </div>
    <div class="row p-3">
        <h1 class="title-text">Отправленные запросы</h1>
        <div class="col-md-12 p-0">
            <mat-accordion class="example-headers-align">
                <mat-expansion-panel *ngFor="let item of data | paginate: {itemsPerPage: 10, currentPage: page, totalItems: countItems}">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <div style="gap: 10px" class="d-flex flex-row justify-content-center align-items-center">
                                <div class="d-flex flex-row justify-content-center align-items-center" style="width: 35px; height: 35px; border-radius: 99px; background-color: #3f51b5;">
                                    <mat-icon style="color: white">{{item.subject === "Заявка" ? 'assignment' : item.subject === "Карты" ? 'map' : item.subject === "Сайт" ? "language" : "ad_units"}}</mat-icon>
                                </div>
                                <span>{{item.name}}</span>
                            </div>
                        </mat-panel-title>
                        <mat-panel-description>
                            {{item.dateAdd | date: 'dd.MM.yyyy'}} | Статус:&nbsp;<span
                                [class.ticket-open]="item.state === 0"
                                [class.ticket-work]="item.state === 3"
                                [class.ticket-finished]="item.state === 2"
                        >{{['открыта', '', 'выполнена', 'в работе'][item.state]}}</span>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div class="container-col">
                        <span>{{item.text}}</span>
                        <mat-card>
                            <span>Создано пользователем</span>
                            <span>ФИО: {{item.user?.secondName}} {{item.user?.firstName}} {{item.user?.patronymic}}</span>
                            <span>E-Mail: {{item.user?.email || 'Не указан'}}</span>
                        </mat-card>
                        <mat-card *ngIf="item.userWhoClose !== null">
                            <span>Закрыто пользователем</span>
                            <span>ФИО: {{item.userWhoClose?.secondName}} {{item.userWhoClose?.firstName}} {{item.userWhoClose?.patronymic}}</span>
                            <span>E-Mail: {{item.userWhoClose?.email || 'Не указан'}}</span>
                        </mat-card>
                        <mat-card *ngIf="item.executor !== null">
                            <span>Взято в работу пользователем</span>
                            <span>ФИО: {{item.executor?.secondName}} {{item.executor?.firstName}} {{item.executor?.patronymic}}</span>
                            <span>E-Mail: {{item.executor?.email || 'Не указан'}}</span>
                        </mat-card>
                        <span class="updated">Обновлено: {{item.dateUpdate | date: 'dd.MM.yyyy в HH:mm'}}</span>
                    </div>
                    <mat-action-row *ngIf="user.currentUser[0]?.role?.name === 'admin' || false">
                        <button mat-button color="accent" *ngIf="item.state === 0" (click)="toggleTicket(item, 3)">Взять в работу</button>
                        <ng-container *ngIf="item.state === 3">
                            <button mat-button color="primary" (click)="toggleTicket(item, 2)">Закрыть заявку</button>
                        </ng-container>
                    </mat-action-row>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
<!--        <div class="ticket-card col-md-12 py-3 px-5" *ngFor="let item of data">-->
<!--            <div>-->
<!--                {{item.name}}-->
<!--            </div>-->
<!--        </div>-->
    </div>
    <div class="row">
        <div class="col-12">
            <pagination-controls (pageChange)="onPageChange($event)" previousLabel="Назад" nextLabel="Вперед"></pagination-controls>
        </div>
    </div>
</div>
