import { Component, OnInit } from '@angular/core';
import {FormControl} from "@angular/forms";
import {Observable} from "rxjs";
import {MapItem} from "../../shared/interfaces/mapMarkers";
import {UsersServices} from "../../shared/services/users.service";
import {map, startWith} from "rxjs/operators";
import {MapService} from "../../shared/services/map.service";

@Component({
  selector: 'app-dash-search',
  templateUrl: './dash-search.component.html',
  styleUrls: ['./dash-search.component.css']
})
export class DashSearchComponent implements OnInit {
  myControl = new FormControl();
  filteredOptions: Observable<MapItem[]>;
  allData: MapItem[] = [];
  constructor(private user: UsersServices, private map: MapService) { }
  onUserSelect(e: MapItem) {
    this.map.mapTriggerSearch.next(e)
  }
  ngOnInit(): void {
    this.user.getAgentsAPI().subscribe((e) => this.allData = e.map(item => {
      return {
        ...item,
        user: {
          ...item.user,
          firstName: item.user.secondName + ' ' + item.user.firstName.slice(0, 1) + '. ' + item.user.patronymic.slice(0, 1) + '.'
        }
      }
    }));
    this.filteredOptions = this.myControl.valueChanges
        .pipe(startWith(''),
            map(value => typeof value === 'string' ? value : value.name),
            map(name => name ? this._filter(name) : this.allData.slice()))
  }
  displayFn(user: MapItem): string {
    return user && user.user.firstName ? user.user.firstName : '';
  }
  private _filter(name: string): MapItem[] {
    const filterValue = name.toLowerCase();

    return this.allData.filter(option => option.user.firstName.toLowerCase().indexOf(filterValue) === 0);
  }
}
