import {Component, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-user-button-table',
  templateUrl: './user-button-table.component.html',
  styleUrls: ['./user-button-table.component.css']
})
export class UserButtonTableComponent implements OnInit {

  @Input() value: string | number;
  @Input() rowData: any;
  constructor(private router: Router) { }
  onSubmitChange(): void {
    this.router.navigate(['/user/' + this.rowData.nummer])
  };
  onSubmitDelete(): void {

  };
  ngOnInit(): void {
  }

}
