<mat-progress-bar *ngIf="!loaded" mode="buffer"></mat-progress-bar>

<form *ngIf="loaded" class="example-form">
    <mat-form-field class="example-full-width">
        <input type="text"
               placeholder="Инициалы агента"
               matInput
               [formControl]="myControl"
               [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelected($event.option.value)" [displayWith]="dispFn">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                {{option.user.firstName}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</form>
    <form [formGroup]="dateValues">
        <div class="row">
            <div class="col-md-6">
                <mat-form-field *ngIf="loaded" appearance="fill">
                    <mat-label>Дата начала</mat-label>
                    <input formControlName="dateStart" matInput [matDatepicker]="picker">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field *ngIf="loaded" appearance="fill">
                    <mat-label>Дата конца</mat-label>
                    <input formControlName="dateFinish" matInput [matDatepicker]="picker">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
    </form>
<div class="d-flex flex-row" style="gap: 10px">
    <button *ngIf="loaded" mat-raised-button (click)="onExport()" [disabled]="selectedId === null || !dateValues.valid" color="primary">Экспорт</button>
    <button *ngIf="loaded" mat-raised-button (click)="onGroupExport()" color="primary">Экспорт группы агентов</button>
</div>


