import {Component, OnInit, Output, ViewChild} from '@angular/core';
import {DirectoryService} from "../../shared/services/directory.service";
import {UsersServices} from "../../shared/services/users.service";
import {MapItem} from "../../shared/interfaces/mapMarkers";
import {Route, ActivatedRoute, Router} from "@angular/router";
import {AdminLogsComponent} from "./admin-logs/admin-logs.component";
import {MatDialogConfig} from "@angular/material/dialog";
import {MatTabGroup} from "@angular/material/tabs";

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.css']
})
export class AdminLayoutComponent implements OnInit {
  asyncDirectoryLoaded = false;
  allUsersLoaded = false;
  allUsersData: any;
  allAgentsData: any[];
  allDevicesData: any;
  agentsLoaded = false;
  role: string;
  agentsLoadedAPI: MapItem[] = [];
  currentTab = 0
  @ViewChild(AdminLogsComponent) child:AdminLogsComponent;

  constructor(private route: ActivatedRoute,
              public dirServ: DirectoryService,
              private user: UsersServices,
              private router: Router) { }
  @ViewChild('tabs') tabGroup: MatTabGroup;
  directory(event) {
    this.router.navigate(['/admin'], {
      queryParams: { p: event.index }
    })
    if (event.tab.textLabel === 'Справочники' && this.dirServ.morgues === false) {
      this.dirServ.getMorgue();
    }
    if(event.tab.textLabel === 'Безопасность') {
      this.child.openDialog();
    }
    if (event.tab.textLabel === 'Агенты') {
      if (!this.agentsLoadedAPI.length) {
        this.user.getAgentsAPI().subscribe(data => {
          const resp = data as MapItem[];
          this.agentsLoadedAPI = resp;
          this.agentsLoaded = true;
        })
      }
    }
    if (event.tab.textLabel === 'Манго телефония') {
      this.router.navigate(['/mango']);
    }
    if (event.tab.textLabel === 'Создание агента') {
      this.user.getAllUsers().subscribe(data => {
        this.allUsersData = data;
        this.user.getAgents().subscribe(adata => {
          this.allAgentsData = adata as [];
          this.user.getDevicesAll().subscribe(ddata => {
            this.allDevicesData = ddata;
            this.allUsersLoaded = true;
          });
        });
      });
    }

  }
  ngOnInit() {
    this.route.queryParams.subscribe((e) => {
      if(e.hasOwnProperty('p')) {
        this.tabGroup.selectedIndex = parseInt(e.p);
      } else {
        if(!!this.tabGroup) {
          this.tabGroup.selectedIndex = 0;
        }
      }
    })
    this.role = this.user.currentUser[0].role.name
    const link = this.route.snapshot.paramMap.get('ref');
    this.route.queryParams.subscribe(params => {
      if(params.hasOwnProperty('ref') && params.ref === "agents") {
        if (!this.agentsLoadedAPI.length) {
          this.user.getAgentsAPI().subscribe(data => {
            const resp = data as MapItem[];
            this.agentsLoadedAPI = resp;
            this.agentsLoaded = true;
          })
        }
      }
    })
  }
}
