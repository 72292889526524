<h2 mat-dialog-title>Удаление пользователя</h2>
<mat-dialog-content class="mat-typography">
    <div class="row">
        <div class="col-md-12">
            <p>Вы уверены что хотите удалить аккаунт?</p>
            <div *ngIf="data !== null && data.length" style="border: 1px solid #dfdfdf;border-radius: .25rem;background-color: #f4f4f4; padding: 10px;">
                <p style="font-size: 11px;">Данный аккаунт связан с агентом:</p>
                <p style="font-size: 11px;">{{data[0].user.email}}</p>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="false">Отмена</button>
    <button mat-button [mat-dialog-close]="true" color="primary">Удалить</button>
</mat-dialog-actions>
