<div style="max-width: 35%; width: 35%; right: 0px; z-index: 999; position: fixed; margin: 10px"><mat-card style="background-color: #1a2637">
    <span style="color: white">Текущие заказы</span>
    <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
    <div *ngIf="!loading" style="max-height: 140px; padding-right: 10px; overflow: auto;">
        <mat-card *ngFor="let item of ticketsData" style="margin-top: 10px;">
            <div style="display: flex; align-items: center; justify-content: space-between">
                <span>{{item?.agent?.user?.secondName}} {{item?.agent?.user?.firstName?.slice(0, 1)}}. {{item?.agent?.user?.patronymic?.slice(0, 1)}}.</span>
                <mat-icon (click)="editTicket(item.id)" style="color: #1a2637; cursor: pointer; font-size: 20px; margin-left: 20px;">arrow_forward_ios</mat-icon>
            </div>
            <div style="z-index: 999;background-color: #d4d4d4;height: 1px;" class="w-100"></div>
            <div>
                <div>
                    <span style="font-size: 12px">Создан: {{item.dateAdd | date: 'dd.mm.yyyy HH:mm'}}</span>
                </div>
                <div style="gap: 5px" class="d-flex flex-row align-items-center">
                    <div
                            [style.backgroundColor]="item.status === 'ticket' ? 'gray' : item.status === 'submitted' ? 'orange' : 'forestgreen'"
                            style="width: 10px; height: 10px; border-radius: 90px; background-color: red"></div>
                    <span style="font-size: 10px">{{item.status === 'ticket' ? 'Открыт' : item.status === 'submitted' ? 'Принят' : 'Прибыл'}}</span>
                </div>

            </div>
            <!--            <span>sdf</span>-->
        </mat-card>
    </div>
</mat-card></div>
